import { ClockTimestamp } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { PatientOverview, PatientOverviewPath } from '@sqior/viewmodels/patient';

export const WorkflowCommandSubPath = 'workflow-command';
export const WorkflowCommandPath = joinPath(PatientOverviewPath, WorkflowCommandSubPath);
export const PatientAndWorkflowCommandSubPath = 'patient-workflow-command';
export const PatientAndWorkflowCommandPath = joinPath(
  PatientOverviewPath,
  PatientAndWorkflowCommandSubPath
);

export enum WorkflowPathStepState {
  Done,
  Problem,
  Todo,
  Comment,
}
export enum WorkflowPathStepLabelType {
  Text,
  Button,
  Custom,
}
export type WorkflowPathStepLabel = {
  type: WorkflowPathStepLabelType;
  text: string;
  operation?: OperationSpec<WorkflowPathResponse> | Entity;
  addText?: string;
  pending?: boolean;
  custom?: Entity;
};
export type WorkflowPathStepModel = {
  id: string;
  state: WorkflowPathStepState;
  label: WorkflowPathStepLabel;
};
export type WorkflowPathModel = {
  current: string;
  steps: WorkflowPathStepModel[];
  timestamp: ClockTimestamp;
};
export type WorkflowOverview = Entity & { workflow?: WorkflowPathModel };

export type WorkflowPathResponse = {
  id: string;
  text?: string;
  entity?: Entity;
  timestamp?: ClockTimestamp;
};
export function SelectWorkflowCommand(
  data: WorkflowPathResponse
): OperationSpec<WorkflowPathResponse> {
  return { type: OperationType.Add, path: WorkflowCommandPath, data: data };
}

/* Combined selection and command execution */
export type PatientWorkflowPathResponse = {
  select: Entity;
  command: WorkflowPathResponse;
};
export function PatientAndWorkflowCommand(
  select: Entity,
  command: WorkflowPathResponse
): OperationSpec<PatientWorkflowPathResponse> {
  return {
    type: OperationType.Add,
    path: PatientAndWorkflowCommandPath,
    data: { select: select, command: command },
  };
}

/* Method returning the workflow path from a patient overview, if applicable */

export function getWorkflowPathFromOverview(overview?: PatientOverview) {
  return overview && overview.content ? (overview.content as WorkflowOverview).workflow : undefined;
}
