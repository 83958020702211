import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { initFirebaseFrontend } from '@sqior/web/push-firebase';
import { PerformOperation, SetWebPushToken } from '@sqior/react/uiapp';
import { firebaseConfig, vapidKey } from './firebase-config';
import { isSqiorMobileClient } from '@sqior/react/uiapp';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from './theme';

// SQIOR SF: Original comment:
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// SQIOR SF:
// We register the service worker in development and production mode in order to get the PUSH notification for web apps
// The caching part of the service worker has been disabled in service-worker.ts (needs to be re-enabled and customized once we need it)

/* Do not register service worker on mobile clients as well as for non-personal users */
const params = new URLSearchParams(window.location.search);
const personalUser = !params.get('kiosk') && !params.get('testUser');
const registerServiceWorker = !isSqiorMobileClient() && personalUser;

function initPushMessages(registration: ServiceWorkerRegistration) {
  initFirebaseFrontend(registration, firebaseConfig, vapidKey)
    .then((pushToken) => {
      console.log(
        'Service Worker registration - initFirebaseFrontend happened - token returned',
        pushToken
      );
      SetWebPushToken(pushToken);
    })
    .catch((e) => {
      console.log('Firebase initialization failed - Push messages will not be available', e);
    });
  navigator.serviceWorker.addEventListener('message', (event) => {
    console.log('message', event.data);
    if ('startOperation' in event.data) {
      PerformOperation(event.data.startOperation);
    }
  });
}

if (registerServiceWorker) {
  serviceWorkerRegistration.register({
    onAlreadyActive(registration) {
      console.log('Service Worker registration - already active', registration);
      initPushMessages(registration);
    },
    onSuccess(registration) {
      console.log('Service Worker registration - succeeded', registration);
      initPushMessages(registration);
    },
    onUpdate(registration) {
      console.log('Service Worker registration - updated', registration);
    },
  });
} else if (personalUser) {
  serviceWorkerRegistration.unregister();
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
