import { EnrichedChatVM } from '@sqior/viewmodels/communication';
import styles from './chat-item.module.css';
import { getPrettyTimeOrDayString } from '@sqior/react/utils';
import ChatPicture from '../chat-picture/chat-picture';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';

export interface ChatItemProps {
  item: EnrichedChatVM;
}

export function ChatItem(props: ChatItemProps) {
  const dispatcher = useContext(OperationContext);
  function onItemClicked() {
    if (props.item.select) dispatcher.start(props.item.select);
  }

  return (
    <div className={styles['container']} onClick={onItemClicked}>
      <div className={styles['icon']}>
        <ChatPicture type={props.item.type} url={props.item.typeUrl} />
        {props.item.unread > 0 && <div className={styles['badge']}>{props.item.unread}</div>}
      </div>
      <div className={styles['title']}>{props.item.name}</div>
      {typeof props.item.subtitle === 'string' && (
        <div className={styles['sub-title']}>{props.item.subtitle}</div>
      )}
      {props.item.subtitle instanceof Array && (
        <div className={styles['sub-title-container']}>
          <div>{props.item.subtitle[0]}</div>
          <div>{props.item.subtitle[1]}</div>
        </div>
      )}
      {props.item.timestamp && (
        <div className={styles['time']}>{getPrettyTimeOrDayString(props.item.timestamp)}</div>
      )}
    </div>
  );
}

export default ChatItem;
