import {
  ChecklistItemViewModel,
  ChecklistOptionSentiment,
  ChecklistOptionViewModel,
} from '@sqior/viewmodels/checklist';
import { useRef } from 'react';
import ChecklistOptionControl from '../checklist-option-control/checklist-option-control';
import styles from './checklist-item-control.module.css';
import { classes } from '@sqior/react/utils';

export interface ChecklistItemControlProps {
  data: ChecklistItemViewModel;
  select: (option: number, check: boolean) => void;
}

export function ChecklistItemControl(props: ChecklistItemControlProps) {
  const scrollElement = useRef<HTMLDivElement>(null);
  /* Check if one of the options is checked */
  const indexOptions: [ChecklistOptionViewModel, number][] = props.data.options.map(
    (option, index) => {
      return [option, index];
    }
  );
  let options = indexOptions.filter((option) => {
    return option[0].checked;
  });
  let warn = false;
  if (!options.length) options = indexOptions;
  else
    warn = options[0][0].checked && options[0][0].sentiment === ChecklistOptionSentiment.Negative;
  return (
    <div className={styles['outer-container']}>
      <div className={styles['container']}>
        <div className={classes(styles['header'], warn ? styles['warn'] : undefined)}>
          {props.data.text}
        </div>
        {options.map((option) => (
          <ChecklistOptionControl
            data={option[0]}
            key={option[1]}
            select={(check) => {
              props.select(option[1], check);
              scrollElement.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }}
          />
        ))}
      </div>
      <div ref={scrollElement} className={styles['scroll-element']}>
        &nbsp;
      </div>
    </div>
  );
}

export default ChecklistItemControl;
