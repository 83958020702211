import {
  AddOperation,
  Operation,
  OperationSpec,
  OperationState,
  OperationType,
} from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';
import { joinPath } from '@sqior/js/url';
import { EntityHeader } from '@sqior/js/entity';
import { ValueObject, isEqual } from '@sqior/js/data';
//import { Logger } from "@sqior/js/log";

export const MessengerTabPath = 'messenger-tab';

export enum MessengerPages {
  CommandHistory = 'page.command-history',
  Settings = 'page.settings',
  ChoseRoom = 'page.chose-room',
  QRScan = 'page.qr-scan',
}

/** Main Messenger Tabs */
export enum MessengerTab {
  None = 'none',
  Todo = 'todo',
  List = 'list',
  Conversation = 'conversation',
  Menu = 'menu',
}
/** Model of pages which can be opened on top of each other */
export type MessengerTabPageSpec = EntityHeader & {
  data?: ValueObject;
};

/** Items which are allowed to be stackable */
export type MessengerTabStackItem = MessengerTab | MessengerTabPageSpec;
/** The stack itself */
export type MessengerTabStack = MessengerTabStackItem[];

/** Selects a message tab
 * (potentially stacked tabs are removed)
 */
export function SelectMessengerTab(tab: MessengerTab): OperationSpec<SelectMessengerTabData> {
  return { type: OperationType.Add, path: SelectMessengerTabPath, data: { tab: tab } };
}
export const SelectMessengerTabPath = joinPath(MessengerTabPath, 'select');
export type SelectMessengerTabData = { tab: MessengerTab };

/** Opens a page on top of the current tab stack  */
export function OpenPage(page: MessengerTabStackItem): OperationSpec<OpenPageData> {
  return { type: OperationType.Add, path: OpenPagePath, data: { page: page } };
}
export const OpenPagePath = joinPath(MessengerTabPath, 'open');
export type OpenPageData = { page: MessengerTabStackItem };

/** Closes the most top page from the current tab stack */
export function ClosePage(): OperationSpec<string> {
  console.log('MessengerTabOverlay - ClosePage');
  return { type: OperationType.Add, path: CloseViewPath, data: '' };
}
export const CloseViewPath = joinPath(MessengerTabPath, 'close');

export function MessengerTabOverlay(op: Operation, path: string, curVal: ValueObject | undefined) {
  if (!(op instanceof AddOperation)) return undefined;

  const oldValue = (curVal as unknown as MessengerTabStack) || [];
  const overlay = StateOverlay.create<MessengerTabStack>(
    (value, timestamp) => {
      timestamp;

      let expectedValue: MessengerTabStack = oldValue;
      if (path === 'select') {
        const data = op.data as SelectMessengerTabData;
        expectedValue = [data.tab];
      } else if (path === 'open') {
        const data = op.data as OpenPageData;
        expectedValue = [...expectedValue, data.page];
      } else if (path === 'close') {
        expectedValue = value.slice(0, expectedValue.length - 1);
      }

      //Logger.debug(['MessengerTabOverlay.create', JSON.stringify(oldValue), JSON.stringify(value), JSON.stringify(timestamp), JSON.stringify(op), path, expectedValue])

      if (isEqual(value, expectedValue))
        setTimeout(() => {
          overlay.clear();
        });

      return expectedValue;
    },
    [MessengerTab.Todo]
  );

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) {
      //Logger.debug(['MessengerTabOverlay.op.stateChange-failed', JSON.stringify(oldValue), state, JSON.stringify(op), path])
      overlay.clear();
    } else if (state === OperationState.Completed) {
      //Logger.debug(['MessengerTabOverlay.op.stateChange-completed', JSON.stringify(oldValue), state, JSON.stringify(op), path])
      setTimeout(() => {
        overlay.clear();
      }, 100);
    }
  });

  return overlay;
}
