import { FactoryProps } from '@sqior/react/factory';
import { WardSPIStatsInfoEntity } from '@sqior/viewmodels/occupancy';
import styles from './ward-spi-stats.module.css';

export type WardSpiStatsProps = FactoryProps<WardSPIStatsInfoEntity>;

export function WardSpiStats(props: WardSpiStatsProps) {
  const warn = props.data.spiMaxCountLimit < props.data.spiCountLimit;
  return (
    <div className={styles['container']}>
      <span>#Patienten SPI &lt;{props.data.spiValueLimit}: </span>
      <span className={styles[warn ? 'warning' : 'normal']}>{props.data.spiCountLimit}</span>
      <span> / {props.data.spiMaxCountLimit}</span>
    </div>
  );
}

export default WardSpiStats;
