import { Entity } from '@sqior/js/entity';
import { PhoneNumberPair } from '@sqior/viewmodels/user';
import { EntityHTML } from './entity-html';
import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { OperationSpec } from '@sqior/js/operation';

export type ChatListVM = ChatVM[];

export const PageChatType = 'page.chat';

export enum ChatVMType {
  Person,
  Group,
  Info,
  PatientRelated,
}
export type ChatVM = {
  id: Entity;
  name: string;
  subtitle?: string | [string, string];
  type: ChatVMType;
  typeUrl?: string;
  timestamp?: ClockTimestamp;
};
export type EnrichedChatVM = ChatVM & {
  select: OperationSpec<ValueObject>;
  key: string;
  unread: number;
};

// Conatains details about the chat, used at the chat page to dsiplay all details
export type ChatDetailsVM = {
  chatId: Entity;
  selectEntities?: Entity; // Entity being feed into SelectPatient when clicked on header
  chatVM: ChatVM; // Detail information on chat
  participants?: AddressInfoVM[]; // Information on chat participants (to display participant list)
};

export enum OnlineStatus {
  NeverSeen,
  Offline,
  Online,
  Away,
}
export type AddressInfoVM = {
  id?: Entity;
  name: EntityHTML;
  subname?: EntityHTML;
  lastConnect?: number;
  onlineStatus: OnlineStatus;
  phoneNumbers: PhoneNumberPair[];
  email?: string;
  chatAddress?: Entity; // An entity referencing an Address to be used to communicate via a chat, typically used to create a chat
};
