import ClosablePage from '../closable-page/closable-page';
import styles from './title-page.module.css';

export interface TitlePageProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  className?: string;
}

export function TitlePage(props: TitlePageProps) {
  return (
    <ClosablePage onClose={props.onClose} className={props.className}>
      <div className={styles['container']}>
        <div className={styles['title']}>{props.title}</div>
        {props.children}
      </div>
    </ClosablePage>
  );
}

export default TitlePage;
