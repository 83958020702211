import { ButtonClose } from '@sqior/react/uibase';
import { AppStyle, AppStyleContext } from '@sqior/react/uimessenger';
import { useContext } from 'react';
import DemoPhone from '../demo-phone/demo-phone';
import styles from './parallel-phone-layout.module.css';

/* eslint-disable-next-line */
export interface ParallelPhoneLayoutProps {}

export function ParallelPhoneLayout(props: ParallelPhoneLayoutProps) {
  const appStyleContext = useContext(AppStyleContext);
  const users: string[] = [];
  for (let i = 1; i <= 16; i++) users.push('test' + i);
  return (
    <div className={styles['container']}>
      <div className={styles['phone-container']}>
        {users.map((user) => (
          <DemoPhone user={user} />
        ))}
      </div>
      <ButtonClose
        additionalClassName={styles['cancel']}
        onClick={() => {
          appStyleContext[1](AppStyle.Combined);
        }}
      />
    </div>
  );
}

export default ParallelPhoneLayout;
