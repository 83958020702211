import LogInButton from '../log-in-button/log-in-button';
import styles from './login-screen.module.css';

/* eslint-disable-next-line */
export interface LoginScreenProps {}

export function LoginScreen(props: LoginScreenProps) {
  return (
    <div className={styles['container']}>
      <h1>sqior Assistent</h1>

      <h2>Anmelden</h2>

      <LogInButton />
    </div>
  );
}

export default LoginScreen;
