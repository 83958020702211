import { Value } from '@sqior/js/data';
import { AddOperation } from './add-operation';
import { DataResult } from './data-operation';
import { DeleteOperation, DeleteResult } from './delete-operation';
import { Operation, OperationState } from './operation';
import { ReadOperation, ReadResult } from './read-operation';
import { StreamOperation } from './stream-operation';

export interface OperationHandler {
  handle: (op: Operation, path: string) => void;
}

export class BasicOperationHandler implements OperationHandler {
  /* Handles an operation by dispatching to the known operation types */
  handle(op: Operation, path = '') {
    /* Indicate that this is now running */
    op.setState(OperationState.Running);

    /* Dispatch to handler functions */
    if (op instanceof AddOperation) {
      this.addOp(op, path)
        .then(([id, timestamp]) => {
          op.completeAdd(id, timestamp);
        })
        .catch((err) => {
          op.fail(err);
        });
    } else if (op instanceof StreamOperation) {
      this.streamOp(op, path)
        .then(([id, timestamp]) => {
          op.completeAdd(id, timestamp);
        })
        .catch((err) => {
          op.fail(err);
        });
    } else if (op instanceof ReadOperation) {
      this.readOp(op, path)
        .then(([data, mimeType, timestamp]) => {
          op.completeRead(data, mimeType, timestamp);
        })
        .catch((err) => {
          op.fail(err);
        });
    } else if (op instanceof DeleteOperation) {
      this.deleteOp(op, path)
        .then((timestamp) => {
          op.complete(timestamp);
        })
        .catch((err) => {
          op.fail(err);
        });
    } else op.fail(); /* Unknown operation type - set to failed */
  }

  /* Default handlers throwing exceptions */
  async addOp(op: AddOperation, path: string): Promise<DataResult> {
    throw Error('Add operation handler is not implemented for path: ' + path + ' and op: ' + op);
  }
  async streamOp(op: StreamOperation, path: string): Promise<DataResult> {
    throw Error('Stream operation handler is not implemented for path: ' + path + ' and op: ' + op);
  }
  async readOp(op: ReadOperation, path: string): Promise<ReadResult> {
    throw Error('Read operation handler is not implemented for path: ' + path + ' and op: ' + op);
  }
  async deleteOp(op: DeleteOperation, path: string): Promise<DeleteResult> {
    throw Error('Delete operation handler is not implemented for path: ' + path + ' and op: ' + op);
  }
}

export class StdOperationHandler<AddType extends Value = Value> extends BasicOperationHandler {
  /* Default handlers throwing exceptions */
  override async addOp(op: AddOperation, path: string): Promise<DataResult> {
    return this.add(op.get<AddType>(), path);
  }
  override async readOp(op: ReadOperation, path: string): Promise<ReadResult> {
    return this.read(op.id, path, op.requestData);
  }
  override async deleteOp(op: DeleteOperation, path: string): Promise<DeleteResult> {
    return this.delete(op.id, path);
  }

  /* Default handlers throwing exceptions */
  async add(data: AddType, path: string): Promise<DataResult> {
    throw Error(
      'Add operation handler is not implemented for path: ' + path + ' and data: ' + data
    );
  }
  async read(id: string, path: string, value?: Value): Promise<ReadResult> {
    value;
    throw Error('Read operation handler is not implemented for path: ' + path + ' and Id: ' + id);
  }
  async delete(id: string, path: string): Promise<DeleteResult> {
    throw Error('Delete operation handler is not implemented for path: ' + path + ' and Id: ' + id);
  }
}
