import { joinUrlPath } from '@sqior/js/url';
import { AuthConfirmTriggerType, AuthContext } from '@sqior/react/uiauth';
import { ConfigContext } from '@sqior/react/utils';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styles from './keycloak-wrapper.module.css';
import { KeycloakProviderBase } from '../keycloak-provider-base';
import { Emitter } from '@sqior/js/event';
import { AuthConfig } from '@sqior/js/authbase';

/* eslint-disable-next-line */
export interface KeycloakWrapperProps {
  authProvider: KeycloakProviderBase;
  children: React.ReactNode;
}

export function KeycloakWrapper(props: KeycloakWrapperProps) {
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState('');
  const configContext = useContext(ConfigContext);

  useEffect(() => {
    const configUrl = configContext.getEndpoint('config');
    axios
      .get(configUrl.href)
      .then((response) => {
        const config = response.data as AuthConfig;
        if (
          config.sqiorAuthBaseUrl &&
          config.sqiorAuthRealm &&
          config.sqiorAuthClientId &&
          config.sqiorAuthUrl &&
          config.baseUrlPrefix
        ) {
          const appUrl = joinUrlPath(window.location.origin, config.baseUrlPrefix);
          props.authProvider.init(config, appUrl);
          setInitialized(true);
        } else setError(`Missing mandatory parameters in: ${configUrl}`);
      })
      .catch((err) => {
        setError(`Error on loading: ${configUrl}`);
        console.error(err);
      });
  }, [props.authProvider, configContext]);

  return (
    <AuthContext.Provider
      value={{
        provider: props.authProvider,
        confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
      }}
    >
      {initialized && props.children}
      {error && error}
    </AuthContext.Provider>
  );
}

export default KeycloakWrapper;
