import { clone, shallowClone, SortedArray } from '@sqior/js/data';
import { AddOperation, Operation, OperationState } from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';
import { InformProjectionVM, MarkReadData, ResponseData } from './inform-projection';

export function PersonsCommunicationStateOverlay(op: Operation) {
  if (!(op instanceof AddOperation)) return undefined;
  const data = op.data as ResponseData | MarkReadData;

  /* Create overlay inserting temporary string if not found */
  const overlay = StateOverlay.create<InformProjectionVM[]>((value) => {
    /* Find the entry */
    let index = -1;
    if (Array.isArray(data.id)) {
      for (const id of data.id) {
        index = SortedArray.findExact(value, { id: id }, (first, second) => {
          return first > second;
        });
        if (index >= 0) break;
      }
    } else
      index = SortedArray.findExact(value, { id: data.id }, (first, second) => {
        return first > second;
      });

    if (index < 0) return value;

    if ('responseIndex' in data) {
      /* Set pre-empted response if not already set, otherwise disable overlay */
      const resOptions = value[index].responseOptions?.[data.responseIndex];
      if (resOptions && !resOptions.response && resOptions.options) {
        const res = shallowClone(value);
        res[index] = clone(value[index]);
        const changeResOptions = res[index].responseOptions?.[data.responseIndex];
        if (changeResOptions) {
          changeResOptions.response = resOptions.options[data.responseId].text;
          changeResOptions.responseTimestamp = data.timestamp;
        }
        return res;
      } else
        setTimeout(() => {
          overlay.clear();
        });
    } else if ('confirmationType' in data) {
      if (!value[index].myReadConfirmationStatus) {
        const res = shallowClone(value);
        res[index] = clone(value[index]);
        res[index].myReadConfirmationStatus = data.confirmationType;
        return res;
      } else
        setTimeout(() => {
          overlay.clear();
        });
    }
    return value;
  }, []);

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
  });

  return overlay;
}
