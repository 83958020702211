import { FactoryProps } from '@sqior/react/factory';
import {
  HighlightButton,
  SelectControl,
  SelectModel,
  SwitchControl,
  useTextResources,
} from '@sqior/react/uibase';
import { SelectionAreaProps } from '@sqior/react/uiselection';
import { classes } from '@sqior/react/utils';
import {
  BedAllocationRequestParameters,
  BedAllocationRequestParametersEntity,
  BedAllocationRequestParametersInput,
} from '@sqior/viewmodels/occupancy';
import { useState } from 'react';
import NursingComplexityControl from '../nursing-complexity-control/nursing-complexity-control';
import styles from './bed-allocation-parameter-input-area.module.css';

export type BedAllocationParameterInputAreaProps =
  FactoryProps<BedAllocationRequestParametersInput> & SelectionAreaProps;

export function BedAllocationParameterInputArea(props: BedAllocationParameterInputAreaProps) {
  const textDict = useTextResources();

  const [clinic, setClinic] = useState(0);
  const [isolation, setIsolation] = useState(false);
  const [monitoring, setMonitoring] = useState(false);
  const [nursing, setNursing] = useState(0);
  const clinicSelect: SelectModel = {
    items: props.data.clinics.map((clinic) => {
      return clinic[0];
    }),
    selected: clinic,
  };

  const handleIsolationChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsolation(event.target.checked);

  const handleMonitoringChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMonitoring(event.target.checked);

  return (
    <div className={styles['container']}>
      {props.data.clinics.length > 0 && (
        <div className={styles['nursing-container']}>
          <div className={styles['title']}>{textDict.get('clinic_selection')}</div>
          <SelectControl
            className={styles['clinic']}
            data={clinicSelect}
            onSelect={(value) => {
              setClinic(value);
            }}
          />
        </div>
      )}
      <div className={styles['toggle-container']}>
        <div className={styles['title']}>{textDict.get('isolation_requirement')}</div>
        <SwitchControl onChange={handleIsolationChange} />
      </div>
      <div className={styles['toggle-container']}>
        <div className={styles['title']}>{textDict.get('monitoring_requirement')}</div>
        <SwitchControl onChange={handleMonitoringChange} />
      </div>
      <div className={styles['nursing-container']}>
        <div className={styles['nursing-container-control']}>
          <div className={styles['title']}>{textDict.get('nursing_complexity')}</div>
          <NursingComplexityControl
            score={nursing}
            onChanged={(score) => {
              setNursing(score);
            }}
          />
        </div>
        <div className={styles['nursing-container-descriptions']}>
          {
            // Items are stacked on each other to avoid height flipping while selecting another complexity and to calculate the height  to the max needed height
            props.data.scoreDesc.map((d, i) => {
              const visible = i + 1 === nursing;
              return (
                <div
                  lang={textDict.language()}
                  className={classes(
                    styles['nursing-container-descriptions-inner'],
                    visible && styles['nursing-container-descriptions-inner-visible']
                  )}
                >
                  {d}
                </div>
              );
            })
          }
        </div>
      </div>
      <div className={styles['button-container']}>
        <HighlightButton
          disabled={nursing === 0}
          onClick={() => {
            const params: BedAllocationRequestParameters = {
              entityType: BedAllocationRequestParametersEntity,
              isolation: isolation,
              monitoring: monitoring,
              nursingComplexity: nursing,
            };
            if (props.data.clinics.length > 0) params.clinic = props.data.clinics[clinic][1];
            props.onSelection({ entity: params });
          }}
        >
          {textDict.get('request_bed')}
        </HighlightButton>
      </div>
    </div>
  );
}

export default BedAllocationParameterInputArea;
