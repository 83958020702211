import { ExternalPromise } from '@sqior/js/async';
import {
  AuthConfig,
  AuthorizationHeader,
  TokenGenerationResult,
  UserInfoType,
} from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';
import Keycloak from 'keycloak-js';
import { KeycloakProviderBase } from './keycloak-provider-base';
import { ClockTimestamp, addSeconds, now } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';

export class KeycloakProvider implements KeycloakProviderBase {
  constructor() {
    this.keycloak = undefined;
    this.tokenRefreshed = new Emitter<[string]>();
    this.initialized = false;
    this.appUrl = undefined;
    this.extAuthenticated = new ExternalPromise<boolean>();
    this.isAuthenticated = this.extAuthenticated.promise;
    this.refreshTime = 30;
    this.refreshCheckInterval = addSeconds(20); // Check every 20 seconds whether the token is valid for another 30 seconds
    this.refreshTimer = undefined;
  }

  /** Returns the storage object to use */
  static get storage() {
    /* Use local storage if available, this can be undefined on Android devices without appropriate permissions */
    return window.localStorage ? window.localStorage : window.sessionStorage;
  }

  init(config: AuthConfig, appUrl: string) {
    if (this.initialized) return;

    this.appUrl = appUrl;
    this.offlineAccess = config.authOfflineAccess ?? false;

    this.keycloak = new Keycloak({
      url: config.sqiorAuthBaseUrl,
      realm: config.sqiorAuthRealm,
      clientId: config.sqiorAuthClientId,
    });

    /* Inject stored tokens to avoid having to log in right again after refreshing the browser */
    const user = KeycloakProvider.storage.getItem('user');
    const token = user ? KeycloakProvider.storage.getItem(user + '-token') : undefined;
    const refreshToken = user
      ? KeycloakProvider.storage.getItem(user + '-refreshToken')
      : undefined;

    if (user)
      Logger.debug(
        [
          'Init keycloak for user:',
          user,
          'token:',
          token ? true : false,
          'refreshToken:',
          refreshToken ? true : false,
          'offline access:',
          this.offlineAccess,
        ],
        [
          'Init keycloak for user, ',
          'token:',
          token ? true : false,
          'refreshToken:',
          refreshToken ? true : false,
          'offlineAccess:',
          this.offlineAccess,
        ]
      );
    else Logger.debug(['Init keycloak without user, offlineAccess:', this.offlineAccess]);

    /* Initialize keycloak */
    this.keycloak
      .init({
        checkLoginIframe: false,
        token: token || undefined,
        refreshToken: refreshToken || undefined,
        onLoad: 'check-sso', // Enables to continue session for new tabs or browser windows
        redirectUri: this.appUrl,
        enableLogging: true,
        scope: this.offlineAccess ? 'offline_access' : undefined,
      })
      .then((succ) => {
        this.extAuthenticated.resolve(succ || false);
        if (succ) {
          Logger.debug('Keycloak init succeeded - storing tokens');
          this.storeTokens();
        } else if (user) {
          Logger.debug(['Keycloak init failed - forcing login of user:', user]);
          this.resetUserTokens(user); // for safety, so that next init call does not present invalid token again
          this.keycloak?.login({
            redirectUri: this.appUrl,
            loginHint: user,
            scope: this.offlineAccess ? 'offline_access' : undefined,
          });
        } else Logger.debug('Keycloak init failed - letting outside handle it');
      })
      .catch((e) => {
        this.extAuthenticated.resolve(false);
        Logger.info(['Keycloak init reported exception:', Logger.exception(e)]);
      });
    this.initialized = true;
  }

  tryLogIn(user?: string) {
    Logger.debug(['Keycloak login of user:', user]);
    if (user) {
      /* Check if the user is already logged in */
      if (
        user ===
        (this.keycloak?.idTokenParsed ? this.keycloak.idTokenParsed['preferred_username'] : '')
      )
        return;
      /* Set user for check-in */
      KeycloakProvider.storage.setItem('user', user);
      /* Check if the browser provides a special clearAndReload method (which is the case for sqior iPhone App) */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const win: any = window;
      if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.clearAndReload) {
        win.webkit.messageHandlers.clearAndReload.postMessage({});
        return;
      } else if (win.Android) {
        win.Android.clearCookiesAndReload();
        return;
      }
    }
    /* Switch to the log-in page */
    const logInUser = user || KeycloakProvider.storage.getItem('user') || undefined;
    if (logInUser) {
      this.resetUserTokens(logInUser); // for safety, so that next init call presents invalid token again
    }
    this.keycloak?.login({
      redirectUri: this.appUrl,
      prompt: 'login',
      loginHint: logInUser,
      scope: this.offlineAccess ? 'offline_access' : undefined,
    });
  }
  logOut(): void {
    Logger.debug('Keycloak logout - purging tokens');
    const user = KeycloakProvider.storage.getItem('user');
    if (user) {
      KeycloakProvider.storage.removeItem('user');
      this.resetUserTokens(user);
    }
    this.keycloak?.logout({ redirectUri: this.appUrl });
  }

  async generateToken(scope: string) {
    const refreshed = await this.keycloak?.updateToken(this.refreshTime);
    const token = this.keycloak?.token || '';
    if (refreshed) {
      this.tokenRefreshed.emit(token);
    }

    // Trigger timeout to re-generate a token pro-actively just befor potential expiration
    if (this.refreshTimer === undefined)
      this.refreshTimer = setTimeout(() => {
        this.refreshTimer = undefined;
        this.generateToken(scope);
      }, this.refreshCheckInterval);

    /* Store tokens only if previous tokens are set, otherwise there is a risk to revive a logged out session */
    if (KeycloakProvider.storage.getItem('user')) this.storeTokens();

    /* Create result */
    const res: TokenGenerationResult = { token };
    const user = this.keycloak?.idTokenParsed
      ? this.keycloak.idTokenParsed['preferred_username']
      : undefined;
    if (user) {
      const sessStart = KeycloakProvider.storage.getItem(user + '-sessionStart');
      if (sessStart) {
        const ts = parseInt(sessStart);
        if (Number.isInteger(ts)) res.sessionStart = ts;
      }
    }
    return res;
  }

  async getIdentityToken(): Promise<string | undefined> {
    return this.keycloak?.idToken;
  }

  get userInfo(): UserInfoType {
    return this.keycloak?.idTokenParsed ? { name: this.keycloak.idTokenParsed['name'] } : {};
  }

  async getAuthorizationHeader(scope: string): Promise<AuthorizationHeader> {
    const token = await this.generateToken(scope);
    const authHeader = { Authorization: 'Bearer ' + token };
    return authHeader;
  }

  private storeTokens() {
    /* Store the tokens in the session storage to be able to inject them in the the init call */
    const user = this.keycloak?.idTokenParsed
      ? this.keycloak.idTokenParsed['preferred_username']
      : undefined;
    if (this.keycloak?.token && this.keycloak.refreshToken && user) {
      KeycloakProvider.storage.setItem('user', user);
      KeycloakProvider.storage.setItem(user + '-token', this.keycloak.token);
      KeycloakProvider.storage.setItem(user + '-refreshToken', this.keycloak.refreshToken);
      /* Set the session start timestamp if not set before or if the session ID changed */
      if (
        !KeycloakProvider.storage.getItem(user + '-sessionStart') ||
        KeycloakProvider.storage.getItem(user + '-sessionId') !== this.keycloak.sessionId
      )
        KeycloakProvider.storage.setItem(user + '-sessionStart', now().toString());
      /* Store the session ID for later comparison */
      if (this.keycloak.sessionId)
        KeycloakProvider.storage.setItem(user + '-sessionId', this.keycloak.sessionId);
    }
  }
  private resetUserTokens(user: string) {
    KeycloakProvider.storage.removeItem(user + '-token');
    KeycloakProvider.storage.removeItem(user + '-refreshToken');
    KeycloakProvider.storage.removeItem(user + '-sessionId');
    KeycloakProvider.storage.removeItem(user + '-sessionStart');
  }

  /* Immediately reload on auth failure to check log-in */
  authFailedReloadPeriod(): number {
    return 0;
  }

  public initialized: boolean;
  private appUrl: string | undefined;
  tokenRefreshed: Emitter<[string]>;
  private extAuthenticated: ExternalPromise<boolean>;
  readonly isAuthenticated: Promise<boolean>;
  private refreshTime: number; // in seconds
  private refreshCheckInterval: ClockTimestamp;
  private refreshTimer: ReturnType<typeof setTimeout> | undefined;
  private offlineAccess = false;

  private keycloak: Keycloak | undefined;
}
