import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import { ChecklistSectionViewModel } from '@sqior/viewmodels/checklist';
import ChecklistParagraphControl from '../checklist-paragraph-control/checklist-paragraph-control';
import styles from './checklist-section-control.module.css';
import { useContext } from 'react';
import { AuthContext } from '@sqior/react/uiauth';

export type ChecklistSectionControlProps = {
  data: ChecklistSectionViewModel;
  select: (paragraph: number, item: number, option: number, check: boolean) => void;
  confirm: () => void;
};

export function ChecklistSectionControl(props: ChecklistSectionControlProps) {
  const textDict = useTextResources();
  const authContext = useContext(AuthContext);
  let allChecked = true;
  for (const paragraph of props.data.paragraphs)
    for (const item of paragraph.items) {
      let checked = false;
      for (const option of item.options) if (option.checked) checked = true;
      if (!checked) allChecked = false;
    }
  return (
    <div className={styles['container']}>
      {props.data.title && <div className={styles['title']}>{props.data.title}</div>}
      {props.data.paragraphs.map((paragraph, index) => (
        <ChecklistParagraphControl
          data={paragraph}
          key={index}
          select={(item, option, check) => {
            props.select(index, item, option, check);
          }}
          readOnly={!props.data.confirm}
        />
      ))}
      {props.data.confirm && (
        <div className={styles['confirm-container']}>
          {props.data.confirmText && (
            <div className={styles[allChecked ? 'confirmActive' : 'confirmDisabled']}>
              {props.data.confirmText}
            </div>
          )}
          <div className={styles['confirm-button']}>
            <HighlightButton
              disabled={!allChecked || props.data.completed}
              onClick={() => {
                authContext.confirmIdentity.emit(
                  textDict.get('confirm_orchecklist_password'),
                  () => {
                    props.confirm();
                  }
                );
              }}
            >
              {textDict.get('confirm')}
            </HighlightButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChecklistSectionControl;
