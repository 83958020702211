import { AddressInfoVM } from '@sqior/viewmodels/communication';
import { Entity } from '@sqior/js/entity';
import { ItemState, ListViewItem } from '@sqior/viewmodels/visual';
import { WorkflowOverview } from '@sqior/viewmodels/workflow';
import { ValueObject } from '@sqior/js/data';
import { PatientInfo } from '@sqior/viewmodels/patient';
import { SelectionControlBaseEntity } from '@sqior/viewmodels/input';

/* Parameterless entity representing the parameters of a bed allocation request to be put in */

export const BedAllocationRequestParametersInputType = 'BedAllocationRequestParametersInput';
export type BedAllocationRequestParametersInput = Entity & {
  clinics: [string, Entity][];
  scoreDesc: string[];
};

/* Entity representing the parameters for a bed allocation request */

export const BedAllocationRequestParametersEntity = 'BedAllocationRequestParameters';
export const BedAllocationRequestParametersInfoType = 'BedAllocationRequestParametersInfo';

export type BedAllocationRequestParametersBase = Entity & {
  isolation: boolean;
  monitoring: boolean;
  nursingComplexity: number;
};
export type BedAllocationRequestParameters = BedAllocationRequestParametersBase & {
  clinic?: Entity;
};
export type BedAllocationRequestParametersInfoEntity = BedAllocationRequestParametersBase & {
  clinic?: string;
  private?: boolean;
};

/* Entity representing the input allowing for selecting a patient ward */

export const BedAllocationTargetWardInputType = 'BedAllocationTargetWardInput';

export type BedAllocationTargetWard = {
  name: string;
  id: Entity;
  text: string;
  challenge: boolean;
};
export type BedAllocationTargetWardInput = SelectionControlBaseEntity & {
  info: PatientInfo;
  diagnosis?: string;
  requirements: BedAllocationRequestParametersInfoEntity;
  wards: BedAllocationTargetWard[];
  defaultWard: string;
};

/* Bed allocation workflow overview */

export const BedAllocationOverviewType = 'BedAllocationOverview';

export type BedAllocationOverviewEntity = WorkflowOverview & {
  diagnosis?: string;
  requirements?: BedAllocationRequestParametersInfoEntity;
  team?: AddressInfoVM[];
  relatedId?: ValueObject;
};

/* Bed allocation list item */

export const BedAllocationListItemType = 'BedAllocationListItem';

export type BedAllocationListItemEntity = ListViewItem & {
  patient: string;
  targetWard?: string;
  diagnosis?: string;
  requirements?: string;
  requestSource?: string;
  order: string;
  state: ItemState;
  select: Entity;
};
