import { classes } from '@sqior/react/utils';
import { ReadConfirmationStatus } from '@sqior/viewmodels/communication';
import styles from './thread-item-status.module.css';

/* eslint-disable-next-line */
export interface ThreadItemStatusProps {
  className?: string;
  readConfirmation?: ReadConfirmationStatus;
}

export function ThreadItemStatus(props: ThreadItemStatusProps) {
  function getRCStyle(confirmation?: ReadConfirmationStatus) {
    switch (confirmation) {
      case ReadConfirmationStatus.ImplicitPartially:
        return 'readconfirmation-implicit-partial';
      case ReadConfirmationStatus.Implicit:
        return 'readconfirmation-implicit';
      case ReadConfirmationStatus.ExplicitPartially:
        return 'readconfirmation-explicit-partial';
      case ReadConfirmationStatus.Explicit:
        return 'readconfirmation-explicit';
      default:
        return 'readconfirmation-none';
    }
  }

  return (
    <div className={props.className}>
      <div className={styles['container']}>
        <span
          className={classes(
            styles['readconfirmation-status'],
            styles[getRCStyle(props.readConfirmation)]
          )}
        />
      </div>
    </div>
  );
}

export default ThreadItemStatus;
