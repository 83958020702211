import { FactoryProps } from '@sqior/react/factory';
import { Checkbox, useTextResources } from '@sqior/react/uibase';
import { BedAllocationRequestParametersInfoEntity } from '@sqior/viewmodels/occupancy';
import styles from './bed-allocation-parameter-info.module.css';

/* eslint-disable-next-line */
export type BedAllocationParameterInfoProps =
  FactoryProps<BedAllocationRequestParametersInfoEntity>;

export function BedAllocationParameterInfo(props: BedAllocationParameterInfoProps) {
  const textDict = useTextResources();
  return (
    <div className={styles['container']}>
      {props.data.clinic && (
        <div className={styles['item-container']}>
          <div className={styles['title']}>{textDict.get('clinic_selection')}</div>
          <div className={styles['clinic']}>{props.data.clinic}</div>
        </div>
      )}
      <div className={styles['item-container']}>
        <div className={styles['title']}>{textDict.get('isolation_requirement')}</div>
        <div className={styles[props.data.isolation ? 'yes' : 'no']}>
          {textDict.get(props.data.isolation ? 'yes' : 'no')}
        </div>
      </div>
      <div className={styles['item-container']}>
        <div className={styles['title']}>{textDict.get('monitoring_requirement')}</div>
        <div className={styles[props.data.monitoring ? 'yes' : 'no']}>
          {textDict.get(props.data.monitoring ? 'yes' : 'no')}
        </div>
      </div>
      <div className={styles['item-container']}>
        <div className={styles['title']}>{textDict.get('nursing_complexity')}</div>
        <Checkbox disabled={true} label={props.data.nursingComplexity.toString()} />
      </div>
      <div className={styles['item-container']}>
        <div className={styles['title']}>{textDict.get('insurance_status')}</div>
        <div className={styles[props.data.private ? 'yes' : 'no']}>
          {textDict.get(
            props.data.private ? 'insurance_status_private' : 'insurance_status_public'
          )}
        </div>
      </div>
    </div>
  );
}

export default BedAllocationParameterInfo;
