import { useDynamicStateRaw } from '@sqior/react/state';
import { classes } from '@sqior/react/utils';
import { ViewportSize } from '@sqior/web/utils';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from './more.svg';
import { ReactComponent as LessIcon } from './icon-less.svg';
import { ReactComponent as CheckIcon } from './icon-check.svg';
import styles from './select-control.module.css';

export type SelectModel = {
  items: string[];
  selected: number;
  defaultSelectionText?: string;
};

export interface SelectControlProps {
  data: SelectModel;
  disabled?: boolean;
  onSelect: (item: number) => void;
  className?: string;
}

export function SelectControl(props: SelectControlProps) {
  const [open, setOpen] = useState(false);
  const [left, setLeft] = useState(100);
  const [top, setTop] = useState(100);
  const [width, setWidth] = useState(300);
  /* Method setting the correct position of the selection pop-up */
  const selCont = useRef<HTMLDivElement>(null);
  const updatePosition = () => {
    if (!selCont.current) return;
    const rect = selCont.current.getBoundingClientRect();
    setLeft(rect.left - 3);
    setTop(rect.bottom + 5);
    setWidth(rect.width + 6);
  };
  /* Observe the viewport size and update the position on resize */
  const viewport = useDynamicStateRaw<ViewportSize>('app/viewport');
  useEffect(() => {
    if (viewport)
      setTimeout(() => {
        updatePosition();
      }, 0);
  }, [viewport]);
  return (
    <div
      className={classes(
        styles[props.disabled ? 'disabled-container' : 'container'],
        props.className
      )}
      onClick={() => {
        if (!open) {
          if (props.disabled || (props.data.items.length <= 1 && props.data.selected >= 0)) return;
          updatePosition();
        }
        setOpen(!open);
      }}
    >
      <div ref={selCont} className={styles['select-container']}>
        <div className={styles['select-text']}>
          {props.data.selected >= 0
            ? props.data.items[props.data.selected]
            : props.data.defaultSelectionText || ''}
        </div>
        {(props.data.items.length > 1 || props.data.selected < 0) && !open && (
          <MoreIcon className={styles[props.disabled ? 'more-disabled' : 'more']} />
        )}
        {(props.data.items.length > 1 || props.data.selected < 0) && open && (
          <LessIcon className={styles['less']} />
        )}
      </div>
      {open && (
        <div
          className={styles['cover']}
          onClick={() => {
            setOpen(false);
          }}
        />
      )}
      {open && (
        <div
          className={styles['item-container']}
          style={{ left: left + 'px', top: top + 'px', width: width + 'px' }}
        >
          <div className={styles['spacer']} />
          <div className={styles['item-sub-container']}>
            <div className={styles['item-scroll-container']}>
              {props.data.items.map((item, index) => (
                <div
                  key={item}
                  className={classes(styles['item'], index > 0 ? styles['next'] : undefined)}
                  onClick={() => {
                    props.onSelect(index);
                  }}
                >
                  <div className={styles['icon-container']}>
                    {props.data.selected === index && <CheckIcon />}
                  </div>
                  <div className={styles['item-content']}>{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectControl;
