import { Bytes } from './binary';
import { RecIF, ValueOrNothing } from './value';

export enum Primitive {
  Boolean = 'boolean',
  Number = 'number',
  String = 'string',
  Binary = 'binary',
  Undefined = 'undefined',
}
export type ValueSpec = Primitive | RecIF<ValueSpec> | ValueSpec[];
export type ValidationError = [string, ValueOrNothing];

/** Method validating whether a value matches a specification */

export function validateValue(
  spec: ValueSpec,
  value: ValueOrNothing,
  collectErrors?: ValidationError[]
): boolean {
  /* Check the specification */
  if (typeof spec === 'string') {
    if (spec !== Primitive.Binary) {
      /* This is is supposed to be a primitive type, simply check the native type */
      return typeof value === spec;
    } else {
      return value instanceof Bytes;
    }
  }
  /* Check for arrays in the spec, arrays with a single element specify an actual array,
     arrays with more elements represent an optional group */
  if (spec instanceof Array) {
    if (spec.length === 1) {
      if (!(value instanceof Array)) {
        return false;
      }
      for (const sub of value) {
        if (!validateValue(spec[0], sub, collectErrors)) {
          return false;
        }
      }
      return true;
    } else {
      for (const option of spec) {
        if (validateValue(option, value, collectErrors)) {
          return true;
        }
      }
      return false;
    }
  }
  /* Specification is an object, check all properties */
  if (typeof value !== 'object' || value instanceof Array || value instanceof Bytes) {
    return false;
  }
  for (const key in spec) {
    if (!validateValue(spec[key], value?.[key], collectErrors)) {
      if (collectErrors) {
        collectErrors.push([key, value?.[key]]);
      }
      return false;
    }
  }
  return true;
}

export function filterInvalidValues<Type extends ValueOrNothing = ValueOrNothing>(
  spec: ValueSpec,
  values: Type[]
): [Type[], [Type, ValidationError[]][]] {
  const valid: Type[] = [];
  const invalid: [Type, ValidationError[]][] = [];

  for (const value of values) {
    const valError: ValidationError[] = [];
    if (validateValue(spec, value, valError)) {
      valid.push(value);
    } else {
      invalid.push([value, valError]);
    }
  }
  return [valid, invalid];
}
