import { Entity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';

export enum ChecklistOptionSentiment {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export type ChecklistOptionViewModel = {
  text: string;
  sentiment: ChecklistOptionSentiment;
  checked: boolean;
};

export type ChecklistItemViewModel = {
  text: string;
  options: ChecklistOptionViewModel[];
};

export type ChecklistParagraphViewModel = {
  header: string;
  items: ChecklistItemViewModel[];
};

export type ChecklistSectionViewModel = {
  title?: string;
  paragraphs: ChecklistParagraphViewModel[];
  completed: boolean;
  confirm: boolean;
  confirmText?: string;
};

export const ChecklistViewModelType = 'ChecklistViewModel';

export type ChecklistViewModel = Entity & {
  template: string;
  id: string;
  title?: string;
  sections: ChecklistSectionViewModel[];
};

/* Command setting a check of the check-list */

export const ChecklistPath = 'checklist';
export const SetChecklistSubPath = 'set';
export const SetChecklistPath = joinPath(ChecklistPath, SetChecklistSubPath);

export type ChecklistSetInfo = {
  template: string; // Name of the checklist template
  id: string; // ID identifying the checklist
  section: number; // Index of the section containing the item to set
  paragraph: number; // Index of the paragraph inside the section containing the item to set
  item: number; // Index of the item in the paragraph to set
  option: number; // Index of the item option to set
  check: boolean; // Flag whether to set or reset the item
};

export function SetChecklistItem(
  template: string,
  id: string,
  section: number,
  paragraph: number,
  item: number,
  option: number,
  check: boolean
): OperationSpec<ChecklistSetInfo> {
  return {
    path: SetChecklistPath,
    type: OperationType.Add,
    data: {
      template: template,
      id: id,
      section: section,
      paragraph: paragraph,
      item: item,
      option: option,
      check: check,
    },
  };
}

/* Command confirming a section of the checklist */

export const ConfirmChecklistSubPath = 'confirm';
export const ConfirmChecklistPath = joinPath(ChecklistPath, ConfirmChecklistSubPath);

export type ChecklistConfirmInfo = {
  template: string; // Name of the checklist template
  id: string; // ID identifying the checklist
  section: number; // Index of the section containing the item to set
};

export function ConfirmChecklistSection(
  template: string,
  id: string,
  section: number
): OperationSpec<ChecklistConfirmInfo> {
  return {
    path: ConfirmChecklistPath,
    type: OperationType.Add,
    data: { template: template, id: id, section: section },
  };
}
