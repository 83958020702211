import {
  AddOperation,
  Operation,
  OperationSpec,
  OperationState,
  OperationType,
} from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';

export const PhoneNumberSettingPath = 'phone-number-setting';

export enum PhoneType {
  Device = 'device',
  User = 'user,',
}

export type PhoneNumberPair = {
  type: PhoneType;
  phoneNumber: string;
};

export type PhoneNumberVM = {
  device: string;
  user: string;
  deviceEnabled: boolean;
};
export type SetPhoneNumberData = {
  type: PhoneType;
  phoneNumber: string;
};
export function SetPhoneNumber(
  type: PhoneType,
  phoneNumber: string
): OperationSpec<SetPhoneNumberData> {
  return {
    type: OperationType.Add,
    path: PhoneNumberSettingPath,
    data: { type: type, phoneNumber: phoneNumber },
  };
}

export function PhoneNumberSettingOverlay(op: Operation) {
  if (!(op instanceof AddOperation)) return undefined;

  const phoneData = op.data as SetPhoneNumberData;

  /* Create overlay setting location */
  const overlay = StateOverlay.create<PhoneNumberVM>(
    (value) => {
      let mixIn = {};
      if (phoneData.type === PhoneType.Device) {
        if (value.device === phoneData.phoneNumber)
          setTimeout(() => {
            overlay.clear();
          });
        mixIn = { device: phoneData.phoneNumber };
      } else if (phoneData.type === PhoneType.User) {
        if (value.user === phoneData.phoneNumber)
          setTimeout(() => {
            overlay.clear();
          });
        mixIn = { user: phoneData.phoneNumber };
      }

      return { ...value, ...mixIn };
    },
    { device: '', user: '', deviceEnabled: false }
  );

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
    else if (state === OperationState.Completed)
      setTimeout(() => {
        overlay.clear();
      }, 1000);
  });

  return overlay;
}
