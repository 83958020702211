import { isEqual } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';
import { useDynamicState } from '@sqior/react/state';
import { NativeAppBackPossible } from '@sqior/react/uiapp';
import {
  MessengerTab,
  MessengerTabPageSpec,
  MessengerTabPath,
  MessengerTabStack,
  MessengerTabStackItem,
} from '@sqior/viewmodels/app';
import { useEffect } from 'react';

export function useMessengerTabState() {
  const messengerTabStack = useDynamicState<MessengerTabStack>(MessengerTabPath, []);

  // Propagate to the native app whether back is possible
  useEffect(() => {
    //Logger.debug(['useMessengerTabState', messengerTabStack])
    NativeAppBackPossible(messengerTabStack.length > 1);
  }, [messengerTabStack]);

  return messengerTabStack;
}

export function firstPage(stack: MessengerTabStack): MessengerTabStackItem {
  return stack[0];
}
export function lastPage(
  stack: MessengerTabStack,
  minLevel = 1
): MessengerTabStackItem | undefined {
  if (stack.length < minLevel) return undefined;

  return stack[stack.length - 1];
}

export function isMessengerTabPageSpec(
  item: MessengerTabStackItem | undefined,
  specificItem?: MessengerTabStackItem
): MessengerTabPageSpec | undefined {
  if (
    item !== undefined &&
    typeof item === 'object' &&
    'entityType' in item &&
    (specificItem === undefined || isEqual(item, specificItem))
  )
    return item;
  return undefined;
}
export function isMessengerMessengerTab(
  item: MessengerTabStackItem | undefined,
  specificItem?: MessengerTab
) {
  return (
    item !== undefined &&
    typeof item === 'string' &&
    (specificItem === undefined || item === specificItem)
  );
}

export function isMessengerMenuOpen(stack: MessengerTabStack): boolean {
  for (let i = stack.length - 1; i >= 0; i--) {
    if (isMessengerMessengerTab(stack[i], MessengerTab.Menu)) return true;
  }
  return false;
}
