import { StateContext } from '../state-context';
import { useContext, useEffect } from 'react';
import { useStateOnce } from '@sqior/react/hooks';
import { Value } from '@sqior/js/data';

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicStateRaw<Type extends Value>(path: string) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);

  /* Get Initial value from state */
  const lastState = stateContext.getSubRaw<Type>(path);
  /* Initialize the state variable which is actually manipulated via the provided setter function in the useEffect() below */
  const [value, setValue] = useStateOnce<Type | undefined>(() => lastState);

  /* Use an effect hook with clean-up to listen and stop listening to state changes */
  useEffect(() => {
    let effectLastState = lastState; // local effectLastState variable to verify whether the state changed compared to initialization

    /* Register a listener and update value on change */
    const stopListening = stateContext.onSubTyped<Type>(path, (value) => {
      setValue(value);
    });

    // Since useEffect() is called asynchronously, re-get the value and set to state if different
    const newValue = stateContext.getSubRaw<Type>(path);
    if (effectLastState !== newValue) {
      effectLastState = newValue;
      setValue(newValue);
    }

    /* Return a clean-up method that removes the listener */
    return () => {
      stopListening();
    };
  }, [path, setValue, lastState, stateContext]);

  return value;
}

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicState<Type extends Value>(path: string, defValue: Type) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);

  /* Get Initial value from state */
  const lastState = stateContext.getSub<Type>(path, defValue);
  /* Initialize the state variable which is actually manipulated via the provided setter function in the useEffect() below */
  const [value, setValue] = useStateOnce<Type>(() => lastState);

  /* Use an effect hook with *clean-up* to listen and stop listening to state changes */
  useEffect(() => {
    let effectLastState = lastState; // local effectLastState variable to verify whether the state changed compared to initialization

    /* Register a listener and update value on change */
    const stopListening = stateContext.onSubTyped<Type>(path, () => {
      setValue(stateContext.getSub<Type>(path, defValue));
    });

    // Since useEffect() is called asynchronously, re-get the value and set to state if different
    const newValue = stateContext.getSub<Type>(path, defValue);
    if (effectLastState !== newValue) {
      effectLastState = newValue;
      setValue(newValue);
    }

    /* Return a clean-up method that removes the listener */
    return () => {
      stopListening();
    };
  }, [defValue, path, stateContext, lastState, setValue]);

  return value;
}

export default useDynamicState;
