import { ensureArray } from './array';
import { Bytes } from './binary';
import { ComparisonOperator, readComparisonMagic } from './comparison';

/* Some helper interface required because of limitations of TS with respect to circular type aliases */
export interface RecIF<T> {
  [k: string]: T;
}

/* This is the main value type specifying what can go into a value:
 * a string
 * a number
 * a boolean
 * a binary object (could be a Blob in browsers or a Buffer in node.js)
 * objects of this
 * arrays of this */
export type PrimitiveValue = string | number | boolean | Bytes;
export type Value = PrimitiveValue | RecIF<Value> | Value[];
export type ValueOrNothing = Value | undefined;
export type ValueObject = Record<string, Value>;
export type PureObject = RecIF<PureObject>;

/* Clones a value (deep) */
export function clone<Type extends Value = Value>(value: Type): Type {
  /* Check if this is a primitive type - in this case this can be returned as is */
  if (typeof value !== 'object' || value instanceof Bytes) return value;

  /* Check if this is an array, then clone all elements */
  if (value instanceof Array) {
    const cloned = [];
    for (const element of value) cloned.push(clone(element));
    return cloned as Type;
  }
  /* This is a true object, clone all properties */
  const cloned: ValueObject = {};
  for (const key in value) cloned[key] = clone((value as ValueObject)[key]);
  return cloned as Type;
}

/* Clones a value (shallow) */
export function shallowClone<Type extends Value = Value>(value: Type): Type {
  /* Check if this is a primitive type - in this case this can be returned as is */
  if (typeof value !== 'object' || value instanceof Bytes) return value;

  /* Check if this is an array, then create a new array with the same elements */
  if (value instanceof Array) return [...value] as Type;

  /* This is a true object, create a new object with the same elements */
  return Object.assign({}, value);
}

/* Clones a value (shallow) with the exception of a specified key */
export function shallowCloneExcept<Type extends ValueObject = ValueObject>(
  value: Type,
  keys: string | string[]
): Type {
  /* Use Object.assign as this is likely more efficient than an own copy loop */
  const clone = Object.assign({}, value);
  for (const k of ensureArray(keys)) delete clone[k];
  return clone;
}

/* Clone by method */
export enum CloneMethod {
  Deep,
  Shallow,
}
export function cloneUsing<Type extends Value = Value>(value: Type, method: CloneMethod): Type {
  if (method === CloneMethod.Shallow) return shallowClone(value);
  return clone(value);
}

/* Removes specfied attributes from object */
export function removeAttributes<Type extends ValueObject = ValueObject>(
  val: ValueObject,
  attributes: string | string[]
): Type {
  for (const a of ensureArray(attributes)) delete val[a];
  return val as Type;
}

/* Checks recursively if all properties are equal */
export function isEqual<Type extends ValueOrNothing>(first: Type, second: Type) {
  /* Check if the types are identical */
  if (typeof first !== typeof second) return false;

  /* Check if there is literal identity */
  if (first === second) return true;

  /* The only two cases in which there is no literal idendity are records and arrays */
  /* Check if this is an object (= a non array as arrays are objects, too) */
  if (
    first instanceof Object &&
    second instanceof Object &&
    !(first instanceof Bytes) &&
    !(second instanceof Bytes) &&
    !(first instanceof Array) &&
    !(second instanceof Array)
  ) {
    for (const key in first)
      if (
        !isEqual(first[key] as unknown as ValueOrNothing, second[key] as unknown as ValueOrNothing)
      )
        return false;
    for (const key in second) if (first[key] === undefined) return false;
    return true;
  } else if (first instanceof Array && second instanceof Array) {
  /* Check if all array elements are equal */
    if (first.length != second.length) return false;
    for (let i = 0; i < first.length; i++) if (!isEqual(first[i], second[i])) return false;
    return true;
  }

  return false;
}

/* Checks recursively if all properties in the first object are equal to the second */
export function isSubsetEqual<SubType extends ValueOrNothing, Type extends SubType = SubType>(
  first: SubType,
  second: Type
) {
  /* Check if the types are identical */
  if (typeof first !== typeof second) return false;

  /* Check if there is literal identity */
  if (first === second) return true;

  /* The only two cases in which there is no literal identity are records and arrays */
  /* Check if this is an object (and a non-array as arrays are objects, too) */
  if (
    first instanceof Object &&
    second instanceof Object &&
    !(first instanceof Bytes) &&
    !(second instanceof Bytes) &&
    !(first instanceof Array) &&
    !(second instanceof Array)
  ) {
    for (const key in first)
      if (
        !isSubsetEqual(
          first[key] as unknown as ValueOrNothing,
          second[key] as unknown as ValueOrNothing
        )
      )
        return false;
    return true;
  } else if (first instanceof Array && second instanceof Array) {
  /* Check if all array elements are equal */
    if (first.length != second.length) return false;
    for (let i = 0; i < first.length; i++) if (!isEqual(first[i], second[i])) return false;
    return true;
  }

  return false;
}

/* Checks recursively if all properties in the first object match the second, supporting magic comparison operators */
export function subsetMatch(
  first: ValueOrNothing,
  second: ValueOrNothing,
  checkForArrayElement = true
) {
  /* Check if the types are identical */
  if (typeof first !== typeof second) {
    /* Check for magic comparison operators */
    if (typeof first === 'string' && typeof second === 'number') {
      const comp = readComparisonMagic(first);
      if (comp)
        switch (comp[0]) {
          case ComparisonOperator.GreaterOrEqual:
            return second >= comp[1];
          case ComparisonOperator.Less:
            return second < comp[1];
        }
    } else if (checkForArrayElement && second instanceof Array)
    /* Check for an array element being contained in an array */
      for (let i = 0; i < second.length; i++) if (subsetMatch(first, second[i], false)) return true;
    return false;
  }

  /* Check if there is literal identity */
  if (first === second) return true;

  /* The only two cases in which there is no literal identity are records and arrays */
  /* Check if this is an object (and a non-array as arrays are objects, too) */
  if (
    first instanceof Object &&
    second instanceof Object &&
    !(first instanceof Bytes) &&
    !(second instanceof Bytes) &&
    !(first instanceof Array) &&
    !(second instanceof Array)
  ) {
    for (const key in first)
      if (
        !subsetMatch(
          first[key] as unknown as ValueOrNothing,
          second[key] as unknown as ValueOrNothing
        )
      )
        return false;
    return true;
  } else if (first instanceof Array && second instanceof Array) {
  /* Check if all array elements are equal */
    if (first.length != second.length) return false;
    for (let i = 0; i < first.length; i++) if (!subsetMatch(first[i], second[i])) return false;
    return true;
  } else if (checkForArrayElement && second instanceof Array)
  /* Check for an array element being contained in an array */
    for (let i = 0; i < second.length; i++) if (subsetMatch(first, second[i], false)) return true;

  return false;
}

/* Visits all primitive leaf node values of the provided value */
export function visit(value: Value, visitor: (value: PrimitiveValue) => void) {
  /* Check if this is a primitive value - call the visitor in this case */
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value instanceof Bytes
  )
    visitor(value);
  /* Check if this is a type that needs to be iterated */ else if (value instanceof Array)
    for (let i = 0; i < value.length; i++) visit(value[i], visitor);
  else if (typeof value === 'object') for (const key in value) visit(value[key], visitor);
}

/* Checks if a value has binary properties */
export function hasBinaries(value: Value) {
  let binaries = 0;
  visit(value, (value) => {
    if (value instanceof Bytes) binaries++;
  });
  return binaries > 0;
}

export function transform(value: Value, trans: (value: PrimitiveValue) => Value) {
  /* Check if this is a primitive type */
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value instanceof Bytes
  )
    return trans(value);

  /* Check if this is an array that must be traversed */
  if (value instanceof Array)
    for (let i = 0; i < value.length; i++) value[i] = transform(value[i], trans);
  else if (!(value instanceof Bytes))
    for (const key in value) value[key] = transform(value[key], trans);

  return value;
}

/* Updates an object by a partial update document */

export function updateObject(obj: ValueObject, update: ValueObject, partial?: PureObject) {
  /* Loop for all properties of the update document */
  for (const key in update) {
    /* Check original, if either the original or the update element does not correspond to an object, then it is simply replaced */
    const orig = obj[key];
    const upd = update[key];
    if (
      typeof orig !== 'object' ||
      orig instanceof Array ||
      orig instanceof Bytes ||
      typeof upd !== 'object' ||
      upd instanceof Array ||
      upd instanceof Bytes ||
      !partial ||
      !partial[key]
    )
      obj[key] = upd;
    /* Otherwise, simply recurse */ else updateObject(orig, upd, partial[key]);
  }
}

/* Flattens a nested object - this does not support arrays */

export function flatten(
  obj: ValueObject,
  filter?: (key: string) => boolean,
  arrayHandler?: (arr: Value[]) => Value
) {
  const res: ValueObject = {};
  flattenObject(obj, res, '', filter, arrayHandler);
  return res;
}

function flattenObject(
  obj: ValueObject,
  res: ValueObject,
  prefix: string,
  filter?: (key: string) => boolean,
  arrayHandler?: (arr: Value[]) => Value
) {
  for (const key in obj) {
    const full = prefix + key;
    if (filter && !filter(full)) continue;
    const value = obj[key];
    if (typeof value !== 'object' || value instanceof Bytes) res[full] = value;
    else if (value instanceof Array) {
      /* Arrays are not fully supported, they are either omitted or treated as a leaf */
      if (arrayHandler) res[full] = arrayHandler(value);
    } else flattenObject(value, res, full + '.', undefined, arrayHandler);
  }
}

/* Unflattens a value from dot notation */

export function unflatten(obj: ValueObject, flatKey: string, value: Value) {
  const keys = flatKey.split('.').filter((key) => {
    return key.length > 0;
  });
  if (keys.length === 0)
    throw new Error(
      'Cannot unflatten key as it does not contain any key components - provided: ' + flatKey
    );
  for (let i = 0; i + 1 < keys.length; i++) {
    let subObj = obj[keys[i]];
    if (!subObj || typeof subObj !== 'object' || subObj instanceof Array || subObj instanceof Bytes)
      obj[keys[i]] = subObj = {};
    obj = subObj;
  }
  obj[keys[keys.length - 1]] = value;
}

export function unflattenWithPrefix(obj: ValueObject, flatKeyPrefix: string, value: ValueObject) {
  for (const key in value) if (key.startsWith(flatKeyPrefix)) unflatten(obj, key, value[key]);
}

/** Walks to a sub-path inside a value */

export function tryWalk(valueParam: Value, path: string): Value | undefined {
  if (path === '') return valueParam;
  let value = valueParam;
  const comps = path.split('.');
  /* Check if the current value is an array */
  for (const comp of comps)
    if (value instanceof Array) {
      const index = parseInt(comp);
      if (index >= value.length) return undefined;
      value = value[index];
    } else if (typeof value === 'object' && !(value instanceof Bytes)) {
    /* Check if the current value is an object */
      if (value[comp] === undefined) return undefined;
      value = value[comp];
    } else return undefined;
  return value;
}

export function walk(value: Value, path: string): Value {
  const res = tryWalk(value, path);
  if (!res) throw new Error('Value does not contain sub-value with path: ' + path);
  return res;
}

/** Estimates the amount of memory consumed by a value */

export function memoryConsumption(value: ValueOrNothing) {
  /* Check the type of value */
  if (typeof value === 'string') return (Math.ceil((value.length + 7) / 8) + 1) * 8;
  else if (typeof value !== 'object' || value instanceof Bytes)
    // Size of bytes not known
    return 8;
  else if (value instanceof Array) {
    let bytes = 24;
    for (const subValue of value) bytes += memoryConsumption(subValue);
    return bytes;
  }
  let bytes = 24;
  for (const key in value) bytes += memoryConsumption(value[key]) + 8;
  return bytes;
}
