import { WorkflowPath } from '@sqior/react/uiworkflow';
import { FactoryProps } from '@sqior/react/factory';
import { BedAllocationOverviewEntity } from '@sqior/viewmodels/occupancy';
import { WorkflowBaseArea } from '@sqior/react/uiorworkflow';
import styles from './bed-allocation-overview.module.css';
import { DiagnosisInfo } from '@sqior/react/uipatient';

export type BedAllocationOverviewProps = FactoryProps<BedAllocationOverviewEntity>;

export function BedAllocationOverview(props: BedAllocationOverviewProps) {
  return (
    <WorkflowBaseArea
      relatedId={props.data.relatedId}
      team={props.data.team}
      headers={[props.data.diagnosis && <DiagnosisInfo diagnosis={props.data.diagnosis} />]}
    >
      {props.data.workflow && (
        <WorkflowPath path={props.data.workflow} className={styles['workflow-path']} />
      )}
    </WorkflowBaseArea>
  );
}

export default BedAllocationOverview;
