import styles from './op-config-view.module.css';
import SpecialtyView from '../specialty-view/specialty-view';
import { motion } from 'framer-motion';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useContext } from 'react';
import { ORWorkflowOverviewDashboardORData } from '@sqior/viewmodels/orworkflow';
import {
  DailyStartConfig,
  DailyStartConfigItem,
  DailyStartConfigItemTypes,
  makeUpdateLocationData,
  UpdateLocation,
} from '@sqior/viewmodels/location';
import { RoomId } from '@sqior/plugins/location';
import { OperationContext } from '@sqior/react/operation';
import { useTextResources } from '@sqior/react/uibase';

/* eslint-disable-next-line */
export interface OpConfigViewProps {
  openConfig?: boolean;
  or: ORWorkflowOverviewDashboardORData;
  firstItem: boolean;
  lastItem: boolean;
}

const getPriorityGroups = (dailyStartConfig?: DailyStartConfig): DailyStartConfigItem | null => {
  if (!dailyStartConfig) return null;
  const priorityIndex = dailyStartConfig.configs.findIndex(
    (config) => config.configType === DailyStartConfigItemTypes.TransferPriorityGroup
  );
  if (priorityIndex === -1) return null;
  return dailyStartConfig.configs[priorityIndex];
};

const getTransferLocations = (dailyStartConfig?: DailyStartConfig): DailyStartConfigItem | null => {
  if (!dailyStartConfig) return null;
  const transferLocationIndex = dailyStartConfig.configs.findIndex(
    (config) => config.configType === DailyStartConfigItemTypes.TransferLocation
  );
  if (transferLocationIndex === -1) return null;
  return dailyStartConfig.configs[transferLocationIndex];
};

const getInductionLocations = (
  dailyStartConfig?: DailyStartConfig
): DailyStartConfigItem | null => {
  if (!dailyStartConfig) return null;
  const inductionLocationIndex = dailyStartConfig.configs.findIndex(
    (config) => config.configType === DailyStartConfigItemTypes.InductionLocation
  );
  if (inductionLocationIndex === -1) return null;
  return dailyStartConfig.configs[inductionLocationIndex];
};

const getSelectedValue = (dailyStartConfigItem: DailyStartConfigItem): string => {
  if (dailyStartConfigItem.selected === undefined) return '';
  return dailyStartConfigItem.options[dailyStartConfigItem.selected].text;
};

export const OpConfigView = ({ openConfig, or, firstItem, lastItem }: OpConfigViewProps) => {
  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  const { dailyStartConfig } = or;
  const location = or.location as RoomId;

  const priorityGroup = getPriorityGroups(dailyStartConfig);
  const transferLocation = getTransferLocations(dailyStartConfig);
  const inductionLocation = getInductionLocations(dailyStartConfig);

  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // G1 G2 G3
    if (!priorityGroup) return;
    const priorityIndex = priorityGroup.options.findIndex(
      (group) => group.text === event.target.value
    );
    dispatcher.start(
      UpdateLocation(makeUpdateLocationData(location.id, priorityGroup.configType, priorityIndex))
    );
  };
  const handleTransferLocationChange = (event: SelectChangeEvent<string>) => {
    if (!transferLocation) return;
    const transferLocationIndex = transferLocation.options.findIndex(
      (group) => group.text === event.target.value
    );
    dispatcher.start(
      UpdateLocation(
        makeUpdateLocationData(location.id, transferLocation.configType, transferLocationIndex)
      )
    );
  };

  const handleInductionLocationChange = (event: SelectChangeEvent<string>) => {
    if (!inductionLocation) return;
    const inductionLocationIndex = inductionLocation.options.findIndex(
      (group) => group.text === event.target.value
    );
    dispatcher.start(
      UpdateLocation(
        makeUpdateLocationData(location.id, inductionLocation.configType, inductionLocationIndex)
      )
    );
  };

  return (
    <motion.div
      layout
      initial={{ width: '100%', backgroundColor: undefined }}
      animate={{
        backgroundColor: openConfig ? 'rgba(34,40,63,0.68)' : undefined,
        borderBottomRightRadius: lastItem ? 10 : 0,
        borderBottomLeftRadius: firstItem ? 10 : 0,
      }}
      style={{
        overflow: 'hidden',
        boxShadow: openConfig ? '0px 0px 5px 0px #000000' : 'none',
        marginBottom: 10,
      }}
    >
      <div className={styles['container']}>
        <p className={styles['or-name']}>{or.name}</p>
        {or.specialty && (
          <SpecialtyView specialtyName={or.specialty} specialtyColor={or.specialtyColor} />
        )}
      </div>
      {inductionLocation && priorityGroup && transferLocation && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            height: openConfig ? 'auto' : 0,
            opacity: openConfig ? 1 : 0,
          }}
        >
          <div style={{ padding: 10 }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Prioritätengruppe</FormLabel>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handlePriorityChange}
                value={getSelectedValue(priorityGroup)}
              >
                {priorityGroup.options.map((group) => (
                  <div key={group.text} className={styles['priority-group-container']}>
                    <FormControlLabel
                      value={group.text}
                      control={<Radio />}
                      label={
                        <div className={styles['priority-group-label-container']}>
                          <p>{group.text}</p>
                          <div className={styles['priority-group-description-container']}>
                            <p className={styles['priority-group-description']}>
                              {group.description}
                            </p>
                          </div>
                        </div>
                      }
                    />
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ padding: 10 }}>
            <FormControl fullWidth>
              <InputLabel>{textDict.get('location_transfer')}</InputLabel>
              <Select
                label={textDict.get('location_transfer')}
                variant="outlined"
                value={getSelectedValue(transferLocation)}
                onChange={handleTransferLocationChange}
              >
                {transferLocation.options.map((location) => (
                  <MenuItem key={location.text} value={location.text}>
                    {location.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ padding: 10 }}>
            <FormControl fullWidth>
              <InputLabel>{textDict.get('location_induction')}</InputLabel>
              <Select
                label={textDict.get('location_induction')}
                variant="outlined"
                value={getSelectedValue(inductionLocation)}
                onChange={handleInductionLocationChange}
              >
                {inductionLocation.options.map((location) => (
                  <MenuItem key={location.text} value={location.text}>
                    {location.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default OpConfigView;
