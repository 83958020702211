import { ChecklistOptionSentiment, ChecklistOptionViewModel } from '@sqior/viewmodels/checklist';
import { ReactComponent as CheckBoxIcon } from './check-box.svg';
import { ReactComponent as ToDoBoxIcon } from './todo-box.svg';
import styles from './checklist-option-control.module.css';
import { classes } from '@sqior/react/utils';

export interface ChecklistOptionControlProps {
  data: ChecklistOptionViewModel;
  select: (check: boolean) => void;
}

function sentimentStyle(sentiment: ChecklistOptionSentiment) {
  if (sentiment === ChecklistOptionSentiment.Positive) return 'positive';
  else if (sentiment === ChecklistOptionSentiment.Negative) return 'negative';
  else return 'neutral';
}

export function ChecklistOptionControl(props: ChecklistOptionControlProps) {
  return (
    <div
      className={styles['container']}
      onClick={() => {
        props.select(!props.data.checked);
      }}
    >
      {props.data.checked && (
        <CheckBoxIcon className={styles[sentimentStyle(props.data.sentiment)]} />
      )}
      {!props.data.checked && (
        <ToDoBoxIcon className={styles[sentimentStyle(props.data.sentiment)]} />
      )}
      <div
        className={classes(
          styles['text'],
          props.data.checked && props.data.sentiment === ChecklistOptionSentiment.Negative
            ? styles['warn']
            : undefined
        )}
      >
        {props.data.text}
      </div>
    </div>
  );
}

export default ChecklistOptionControl;
