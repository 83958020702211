import styles from './user-info.module.css';
import { LogOutButton } from '@sqior/react/uiauth';
import { useContext } from 'react';
import { AuthContext } from '../auth-context';

import { ReactComponent as UserInfoIcon } from './user-picture-generic.svg';
import { useDynamicState } from '@sqior/react/state';

/* eslint-disable-next-line */
export interface UserInfoProps {
  onClick?: () => void;
  showLogout?: boolean;
  showUserIcon?: boolean;
  className?: string;
}

export function UserInfo(props: UserInfoProps) {
  const authContext = useContext(AuthContext);
  const user = authContext.provider.userInfo;

  const displayUserImage = user['picture'] !== undefined;

  /* Show server supplied user name if available */
  const userNameServer = useDynamicState<string>('user-info/user', '');
  const userName = userNameServer ? userNameServer : user.name;

  return (
    <div className={`${styles['user-info']} ${props.className}`}>
      {props.showUserIcon && !displayUserImage && (
        <UserInfoIcon
          fill="#ffffff"
          viewBox="0 0 40 40"
          className={styles['user-image']}
          onClick={props.onClick}
        />
      )}
      {props.showUserIcon && displayUserImage && (
        <img
          className={styles['user-image']}
          src={user['picture']}
          alt={userName}
          onClick={props.onClick}
        />
      )}
      {userName && (
        <div className={styles['user-name']} onClick={props.onClick}>
          {userName}
        </div>
      )}
      {props.showLogout && <LogOutButton />}
    </div>
  );
}

export default UserInfo;
