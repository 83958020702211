import styles from './react-uiauth.module.css';

/* eslint-disable-next-line */
export interface ReactUiauthProps {}

export function ReactUiauth(props: ReactUiauthProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactUiauth!</h1>
    </div>
  );
}

export default ReactUiauth;
