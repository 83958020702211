import {
  AddressInfoVM,
  CreateAndDisplayChat,
  OnlineStatus as OnlineStat,
} from '@sqior/viewmodels/communication';
import { Button, SvgIconCall2, SvgIconChatEmpty, SvgIconEmail } from '@sqior/react/uibase';
import { EntityVisual } from '@sqior/react/uiconversation';
import styles from './address-item.module.css';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { ValueObject } from '@sqior/js/data';
import { classes, getPrettyTimeString, VersionInfoContext } from '@sqior/react/utils';
import { PhoneType } from '@sqior/viewmodels/user';

/* eslint-disable-next-line */
export interface AddressItemProps {
  item: AddressInfoVM;
  relatedId?: ValueObject; // If specified, this reference be used as related entity to e.g. start a chat
}

export function AddressItem(props: AddressItemProps) {
  const debug = false;
  const dispatcher = useContext(OperationContext);
  const versionInfo = useContext(VersionInfoContext);

  function onEmail() {
    document.location.href = `mailto:${props.item.email}`;
  }
  function onChat() {
    if (props.item.chatAddress !== undefined && props.relatedId !== undefined)
      dispatcher.start(CreateAndDisplayChat(props.relatedId, [props.item.chatAddress], true));
  }

  return (
    <div className={styles['container']}>
      <div className={styles['status']}>
        <OnlineStatus status={props.item.onlineStatus} />
      </div>
      <div className={styles['names']}>
        <div className={styles['name']}>
          <EntityVisual content={props.item.name} />
        </div>
        <div className={styles['role']}>
          <EntityVisual content={props.item.subname} />{' '}
          {debug && props.item.lastConnect && `(${getPrettyTimeString(props.item.lastConnect)})`}
        </div>
      </div>
      <div className={styles['buttons']}>
        {props.item.phoneNumbers && versionInfo?.mobileClient && (
          <>
            {props.item.phoneNumbers.map((p, idx, arr) => (
              <PhoneButton phoneNumber={p.phoneNumber} type={arr.length > 1 ? p.type : undefined} />
            ))}
          </>
        )}
        {props.item.chatAddress && (
          <Button className={styles['button']} onClick={onChat}>
            <SvgIconChatEmpty />
          </Button>
        )}
        {false && props.item.email && (
          <Button className={styles['button']} onClick={onEmail}>
            <SvgIconEmail />
          </Button>
        )}
      </div>
    </div>
  );
}

interface OnlineStatusProps {
  status: OnlineStat;
}

function OnlineStatus(props: OnlineStatusProps) {
  function statusClass(status: OnlineStat) {
    switch (status) {
      case OnlineStat.NeverSeen:
        return styles['status-offline'];
      case OnlineStat.Offline:
        return styles['status-offline'];
      case OnlineStat.Online:
        return styles['status-online'];
      case OnlineStat.Away:
        return styles['status-away'];
    }
  }

  return <span className={classes(styles['online-status'], statusClass(props.status))} />;
}

interface PhoneButtonProps {
  phoneNumber: string;
  type: PhoneType | undefined;
}

function PhoneButton(props: PhoneButtonProps) {
  function onCall() {
    document.location.href = `tel:${props.phoneNumber}`;
  }
  let type = '';
  switch (props.type) {
    case PhoneType.Device:
      type = 'M';
      break;
    case PhoneType.User:
      type = 'U';
      break;
  }

  return (
    <Button key={props.phoneNumber} className={styles['button']} onClick={onCall}>
      <SvgIconCall2 />
      <div>{type}</div>
    </Button>
  );
}

export default AddressItem;
