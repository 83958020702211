/* Main type for a timestamp that represents a real unit of time - this uses the number of milliseconds after Unix epoch UTC w/o leap seconds */

import { SortedArray } from './sorted-array';

export type ClockTimestamp = number;

/* Get the current timestamp */

export function now(): ClockTimestamp {
  return Date.now();
}

/* Manipulate a timestamp with a defined unit of time */

export function addMilliseconds(milliSeconds: number, timestamp: ClockTimestamp = 0) {
  return timestamp + milliSeconds;
}
export function addSeconds(seconds: number, timestamp: ClockTimestamp = 0) {
  return addMilliseconds(seconds * 1000, timestamp);
}
export function addMinutes(minutes: number, timestamp: ClockTimestamp = 0) {
  return addSeconds(minutes * 60, timestamp);
}
export function addHours(hours: number, timestamp: ClockTimestamp = 0) {
  return addMinutes(hours * 60, timestamp);
}
export function addDays(days: number, timestamp: ClockTimestamp = 0) {
  return addHours(days * 24, timestamp);
}
export function inSeconds(timestamp: ClockTimestamp) {
  return timestamp / 1000;
}
export function inMinutes(timestamp: ClockTimestamp) {
  return inSeconds(timestamp) / 60;
}
export function inHours(timestamp: ClockTimestamp) {
  return inMinutes(timestamp) / 60;
}
export function inDays(timestamp: ClockTimestamp) {
  return inHours(timestamp) / 24;
}

/* Interface for time providers */

export type TimerCallback = () => void;
export type StopTimer = () => void;

export interface TimerInterface {
  get now(): ClockTimestamp;
  schedule(callback: TimerCallback, ms: ClockTimestamp): StopTimer;
  periodic(callback: TimerCallback, ms: ClockTimestamp): StopTimer;
}

/* Time provider with the default clock */

const MaxTimerPeriod = addHours(500);

export class StdTimer implements TimerInterface {
  get now() {
    return Date.now();
  }

  schedule(callback: TimerCallback, timeout = 0): StopTimer {
    let stopTimer: ReturnType<typeof setTimeout> | undefined;
    /* Check if the maximum supported timeout period is not exceeded */
    if (timeout < MaxTimerPeriod) stopTimer = setTimeout(callback, timeout);
    else {
      /* Define the next iteration */
      const iterate = () => {
        if (timeout <= MaxTimerPeriod) callback();
        else {
          timeout -= MaxTimerPeriod;
          stopTimer = setTimeout(iterate, Math.min(timeout, MaxTimerPeriod));
        }
      };
      stopTimer = setTimeout(iterate, MaxTimerPeriod);
    }
    return () => {
      if (stopTimer) clearTimeout(stopTimer);
    };
  }

  periodic(callback: TimerCallback, period: ClockTimestamp): StopTimer {
    const stopInteval = setInterval(callback, period);
    return () => {
      clearInterval(stopInteval);
    };
  }
}

/* Test helper class that simulates a timer with a controlled clock */

export class TestTimer implements TimerInterface {
  constructor(current = now()) {
    this.time = current;
  }

  get now() {
    return this.time;
  }

  schedule(callback: TimerCallback, timeout = 0): StopTimer {
    const time = this.time + timeout;
    SortedArray.insert<[ClockTimestamp, TimerCallback]>(this.timers, [time, callback], (a, b) => {
      return a[0] < b[0];
    });
    return () => {
      const idx = this.timers.findIndex((v) => v[0] === time && v[1] === callback);
      if (idx >= 0) this.timers.splice(idx, 1);
    };
  }

  /** Starts a periodic test timer */
  periodic(callback: TimerCallback, ms: number): StopTimer {
    /* Define the function that starts the next period */
    let stopTimer: StopTimer | undefined;
    const iterate = () => {
      stopTimer = this.schedule(iterate, ms);
      callback();
    };
    /* Schedule a timer for the next period */
    stopTimer = this.schedule(iterate, ms);
    return () => {
      if (stopTimer) stopTimer();
    };
  }

  advance(ms: ClockTimestamp) {
    this.time += ms;
    this.handleTimers();
  }

  set(ms: ClockTimestamp) {
    this.time = ms;
    this.handleTimers();
  }

  private handleTimers() {
    while (this.timers.length) {
      const timer = this.timers[0];
      if (timer[0] > this.time) return;
      this.timers.shift();
      timer[1]();
    }
  }

  tick() {
    this.advance(0);
  }

  private time;
  private timers: [ClockTimestamp, TimerCallback][] = [];
}

/** Holder of a stop timer callback */

export class TimerHolder {
  /** Sets the timer result */
  set(stopTimer: StopTimer) {
    if (this.stopTimer !== undefined) this.stopTimer();
    this.stopTimer = stopTimer;
  }

  /** Checks if a timer is pending */
  get isSet() {
    return this.stopTimer !== undefined;
  }

  /** Resets the timer */
  reset() {
    if (this.stopTimer === undefined) return;
    this.stopTimer();
    this.stopTimer = undefined;
  }

  private stopTimer?: StopTimer;
}
