import { FactoryProps } from '@sqior/react/factory';
import { classes } from '@sqior/react/utils';
import {
  WardSpecialtiesStatsInfoEntity,
  WardSpecialtiesStatsInfoItem,
} from '@sqior/viewmodels/occupancy';
import styles from './ward-specialties-stats.module.css';

export type WardSpecialtiesStatsProps = FactoryProps<WardSpecialtiesStatsInfoEntity>;
export function WardSpecialtiesStats(props: WardSpecialtiesStatsProps) {
  const total: WardSpecialtiesStatsInfoItem = {
    specialty: 'Summe',
    patients: 0,
    quota: 0,
    arriving: 0,
    leaving: 0,
  };
  props.data.specialties.forEach((item) => {
    total.patients += item.patients;
    total.quota += item.quota;
    total.arriving += item.arriving;
    total.leaving += item.leaving;
  });

  return (
    <div className={styles['container']}>
      <table className={styles['stats-table']}>
        {props.data.specialties.map((item) => {
          return <WardSpecialtiesRow item={item} />;
        })}
        {props.data.specialties.length > 1 && <WardSpecialtiesRow item={total} total={true} />}
      </table>
    </div>
  );
}

type WardSpecialtiesRowProps = { item: WardSpecialtiesStatsInfoItem; total?: boolean };
function WardSpecialtiesRow(props: WardSpecialtiesRowProps) {
  const warning = props.item.patients > props.item.quota;
  const patientAfter = props.item.patients + props.item.arriving - props.item.leaving;
  const warningAfter = patientAfter > props.item.quota;
  return (
    <tr className={props.total ? styles['stats-row-total'] : undefined}>
      <td className={styles['stats-cell-name']}>{props.item.specialty}</td>
      <td className={styles['stats-cell-values-current']}>
        <span className={classes(styles[warning ? 'warning' : 'normal'])}>
          {props.item.patients}
        </span>
      </td>
      <td className={styles['stats-cell-values-arriving']}>
        {props.item.arriving > 0 ? `+${props.item.arriving}` : <>&nbsp;-&nbsp;</>}
      </td>
      <td className={styles['stats-cell-values-middle']}>/</td>
      <td className={styles['stats-cell-values-leaving']}>
        {props.item.leaving > 0 ? `-${props.item.leaving}` : <>&nbsp;-&nbsp;</>}
      </td>
      <td className={styles['stats-cell-values-future']}>
        <span className={classes(styles[warningAfter ? 'warning' : 'normal'])}>{patientAfter}</span>
      </td>
      <td className={styles['stats-cell-values-quota']}>({props.item.quota})</td>
    </tr>
  );
}

export default WardSpecialtiesStats;
