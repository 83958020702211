import { ExternalPromise } from '@sqior/js/async';
import { BearerTokenAuthContext } from '@sqior/js/authbase';
import Keycloak from 'keycloak-js';
import { KeycloakProviderBase } from './keycloak-provider-base';
import { addMinutes, now } from '@sqior/js/data';
import { AuthConfirmMessageType } from '@sqior/react/uiauth';

/** Special keycloak auth provider used for confirming the identity of a user e.g. when confirming a critical action */
export class KeycloakConfirmProvider
  extends BearerTokenAuthContext
  implements KeycloakProviderBase
{
  constructor() {
    super();
    this.keycloak = undefined;
  }

  init(
    authBaseUrl: string,
    authRealm: string,
    authClientId: string,
    authUrl: string,
    appUrl: string
  ) {
    /* Check if this has been initialized before */
    if (this.keycloak) return;
    /* Create the keycloak client - a second client ID (automatically derived from the main one) is used to not mess with the already logged in client */
    this.keycloak = new Keycloak({
      url: authBaseUrl,
      realm: authRealm,
      clientId: authClientId + '-confirm',
    });
    /* Initialize keycloak */
    this.keycloak
      .init({
        checkLoginIframe: false,
        onLoad: 'login-required', // Do not allow for SSO to confirm actions
        redirectUri: this.appUrl,
        enableLogging: true,
      })
      .then((succ) => {
        /* Check if this is a recent ID token, otherwise force log-in */
        if (
          succ &&
          this.keycloak?.idTokenParsed &&
          this.keycloak?.idTokenParsed.auth_time &&
          now() < addMinutes(1, this.keycloak?.idTokenParsed.auth_time * 1000)
        )
          /* Send message to main window informing about the successful confirmation */
          window.top?.postMessage({ entityType: AuthConfirmMessageType }, window.location.origin);
        else this.tryLogIn();
      })
      .catch(() => {
        this.tryLogIn();
      });
  }

  tryLogIn(user?: string) {
    this.keycloak?.login({
      redirectUri: this.appUrl,
      prompt: 'login',
      loginHint: user,
    });
  }
  logOut(): void {
    this.keycloak?.logout({ redirectUri: this.appUrl });
  }

  async generateToken(scope: string) {
    return '';
  }

  async getIdentityToken(): Promise<undefined> {
    return undefined;
  }

  get userInfo() {
    return {};
  }

  get isAuthenticated(): Promise<boolean> {
    return this.authenticated.promise;
  }

  private appUrl?: string;
  private keycloak?: Keycloak;
  private authenticated = new ExternalPromise<boolean>();
}
