import './App.css';
import {
  AppFrame,
  AuthenticationSystem,
  isSqiorMobileClient,
  UIAppPlugin,
} from '@sqior/react/uiapp';
import { StateOverlayContext, StateOverlayMap } from '@sqior/js/state-operation';
import { UIWorkflowPlugin } from '@sqior/react/uiorworkflow';
import { useEffect, useState } from 'react';
import {
  AppStyle,
  AppStyleContext,
  decideAppStyle,
  Messenger,
  UIMessengerPlugin,
} from '@sqior/react/uimessenger';
import { UIUserPlugin } from '@sqior/react/uiuser';
import { AskPushPermission } from '@sqior/react/notifications';

import { VersionInfo, VersionInfoContext } from '@sqior/react/utils';
import { Factory, FactoryContext } from '@sqior/react/factory';
import { PluginContext, Plugins } from '@sqior/js/plugin';
import { UIOccupancyPlugin } from 'libs/react/uioccupancy/src';
import { State } from '@sqior/js/state';
import { ViewportState } from '@sqior/web/utils';
import { ParallelPhoneLayout } from '@sqior/react/uidemo';
import { UIChecklistPlugin } from '@sqior/react/uichecklist';
import { UIPatientPlugin } from '@sqior/react/uipatient';
import { Dashboard } from '@sqior/react/uivisual';
import { ConsoleLogger, LogLevel, ProxyLogger, SetLogger, StdLogger } from '@sqior/js/log';
import { LogPath, LogState, NativeDeviceLogger, StorageLogRetainer } from '@sqior/web/log';
import { UITreatmentPathPlugin } from '@sqior/react/ui-treatment-path';
import { UIConversationPlugin } from '@sqior/react/uiconversation';
import { UIChatPlugin } from '@sqior/react/uichat';
import { UIAdvancedPlugin } from '@sqior/react/uiadvanced';
import { InterweaveExtContext, InterweaveExtFactory, UIBasePlugin } from '@sqior/react/uibase';

/* Define state overlays */
const stateOverlays = new StateOverlayMap();
const context: PluginContext & FactoryContext & StateOverlayContext & InterweaveExtContext = {
  plugins: new Plugins(),
  factory: new Factory(),
  interweaveFactory: new InterweaveExtFactory(),
  stateOverlays: stateOverlays,
};

// stateOverlays.add('image-to-text', ImageTextStateOverlay, 'image-text');

/* Currently not used, function to identify screen rotation of device
function isPortrait(){
  if (window.screen.orientation !== undefined) // Rest of the world
    return window.screen.orientation.type.includes('portrait');

  else if (window.orientation !== undefined) // Safari, iPhone (need to use deprecated API ... OMG)
    return window.orientation === 0 || window.orientation === 180;

  return false;
}
*/

/* Returns the class to use for the specified style */
function getClass(style: AppStyle) {
  if (style === AppStyle.Messenger) return 'app-frame';
  if (style === AppStyle.Dashboard) return 'app-frame-dashboard';
  return 'app-frame-w-dashboard';
}

/* Define app state */
const appState = new State();
appState.map('viewport', new ViewportState());

/* Keep logs in memory and in WebStorage until they can be send out */
const logRetainer = new StorageLogRetainer();
/* Log to a state */
const logState = new LogState();
appState.map(LogPath, logState.state);
/* Construct proxy logger, logging to console, to the native device and to a state */
SetLogger(
  new StdLogger(
    { level: LogLevel.Debug },
    new ProxyLogger([new ConsoleLogger(), logRetainer, new NativeDeviceLogger(), logState])
  )
);

/** Main rendering function of the app */
function App() {
  /* Check if the window width is significantly greater than the heigth */
  const params = new URLSearchParams(window.location.search);
  const kiosk = params.get('kiosk');
  const [appStyle, setAppStyle] = useState(decideAppStyle(kiosk ? true : false));
  useEffect(() => {
    function onChange() {
      let sd = decideAppStyle(kiosk ? true : false, appStyle);
      if (sd !== appStyle) setAppStyle(sd);
    }

    window.addEventListener('resize', onChange);
    if (window.screen.orientation !== undefined)
      window.screen.orientation.addEventListener('orientationchange', onChange);
    else if (window.orientation !== undefined)
      window.addEventListener('orientationchange', onChange);

    return () => {
      if (window.screen.orientation !== undefined)
        window.screen.orientation.removeEventListener('orientationchange', onChange);
      else if (window.orientation !== undefined)
        window.removeEventListener('orientationchange', onChange);
      window.removeEventListener('resize', onChange);
    };
  }, [appStyle, kiosk]);

  const versionInfo: VersionInfo = {
    version: process.env.REACT_APP_VERSION || 'Environment variable REACT_APP_VERSION missing',
    appnameInternal: process.env.REACT_APP_NAME || 'Environment variable REACT_APP_NAME missing',
    appname: 'sqior Assistant',
    mobileClient: isSqiorMobileClient(),
  };

  /* Check if this is the main app or just an iFrame to confirm the log-in, check if this is not an iFrame of the demo phone layout */
  const testUser = params.get('testUser');
  const confirmApp = window.parent !== window && !testUser;

  /* Initialize plug-ins */
  if (!confirmApp) {
    context.plugins.require(UIBasePlugin, context);
    context.plugins.require(UIAdvancedPlugin, context);
    context.plugins.require(UIAppPlugin, context);
    context.plugins.require(UIChatPlugin, context);
    context.plugins.require(UIChecklistPlugin, context);
    context.plugins.require(UIConversationPlugin, context);
    context.plugins.require(UIMessengerPlugin, context);
    context.plugins.require(UIOccupancyPlugin, context);
    context.plugins.require(UIPatientPlugin, context);
    context.plugins.require(UITreatmentPathPlugin, context);
    context.plugins.require(UIUserPlugin, context);
    context.plugins.require(UIWorkflowPlugin, context);
  }

  return (
    <VersionInfoContext.Provider value={versionInfo}>
      <AppStyleContext.Provider value={[appStyle, setAppStyle]}>
        <AppFrame
          config={{
            core: { ...context, appState: appState, logRetainer: logRetainer },
            auth: confirmApp ? AuthenticationSystem.KeycloakConfirm : AuthenticationSystem.Keycloak,
          }}
        >
          {appStyle !== AppStyle.ParallelPhoneDemo && !confirmApp && (
            <div className={getClass(appStyle)}>
              {appStyle !== AppStyle.Dashboard && <AskPushPermission className="top-status" />}
              {(appStyle === AppStyle.Combined || appStyle === AppStyle.Dashboard) && (
                <Dashboard className="app-dashboard" />
              )}
              {appStyle !== AppStyle.Dashboard && (
                <Messenger
                  className="app-messenger"
                  fullDashboard={appStyle !== AppStyle.Combined}
                />
              )}
            </div>
          )}
          {appStyle === AppStyle.ParallelPhoneDemo && <ParallelPhoneLayout />}
        </AppFrame>
      </AppStyleContext.Provider>
    </VersionInfoContext.Provider>
  );
}

export default App;
