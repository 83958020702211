import { KeyPairMap } from './multi-key-map';
import { flatten, shallowCloneExcept, unflatten, Value, ValueObject } from './value';

export class MassFilter<Result> {
  constructor() {
    this.results = [];
    this.subs = new KeyPairMap<string, Value, MassFilter<Result>>();
    this.keys = new Set<string>();
  }

  add(filter: ValueObject, res: Result, valueFilter?: (value: Value) => boolean) {
    /* Flatten the filter template to simplify the analysis. If this would not be done, one would need to do recursion
       during matching in two dimensions, the properties and the nesting ... */
    const flat = flatten(filter);
    let match: MassFilter<Result> | undefined = (() => {
      return this;
    })();
    for (const key in flat) {
      /* Eliminate values, if applicable */
      if (valueFilter && !valueFilter(flat[key])) continue;
      const master = match;
      match = match.subs.get(key, flat[key]);
      if (!match) master.subs.set(key, flat[key], (match = new MassFilter<Result>()));
      /* Add the key and all keys on the path to the key to the dictionary, this will be used to limit the inspection of the values */
      for (let usedKey = key; !this.keys.has(usedKey); ) {
        this.keys.add(usedKey);
        const dotPos = usedKey.lastIndexOf('.');
        if (dotPos < 0) break;
        usedKey = usedKey.substring(0, dotPos);
      }
    }
    match.results.push(res);
    return () => {
      if (match)
        match.results = match.results.filter((item) => {
          return item !== res;
        });
    };
  }

  match(value: ValueObject) {
    /* Flatten the value, filter out all properties that will not be matched anyways */
    const flat = flatten(
      value,
      (key) => {
        return this.keys.has(key);
      },
      (arr) => {
        return arr;
      }
    );
    const res = new Set<Result>();
    this.matchResults(flat, res);
    return res;
  }
  private matchResults(value: ValueObject, res: Set<Result>) {
    /* Add results of this level */
    for (const r of this.results) res.add(r);
    /* Loop all keys and test on lower levels */
    for (const key in value) {
      const prop = value[key];
      /* Check if this is a primitive value */
      if (typeof prop !== 'object') {
        const sub = this.subs.get(key, prop);
        if (!sub) continue;
        /* Test on sub-level */
        sub.matchResults(shallowCloneExcept(value, key), res);
      } else if (prop instanceof Array) {
      /* Check if this is an array */
        let rem: ValueObject | undefined;
        for (const el of prop) {
          /* Ensure this is a primitive value */
          if (typeof el !== 'object') {
            const sub = this.subs.get(key, el);
            if (!sub) continue;
            /* Test on sub-level */
            if (!rem) rem = shallowCloneExcept(value, key);
            sub.matchResults(rem, res);
          } else if (this.subs.map.has(key))
            console.log(
              'WARN: Match filter called with array of complex objects -> no match is performed',
              el
            );
        }
      }
    }
  }

  /* Determines the unique filter objects that will cause at least one match */
  topFilters(): ValueObject[] {
    /* Return empty object if there are results on this level */
    if (this.results.length > 0) return [{}];
    /* Loop for all keys and combine with their results */
    const res: ValueObject[] = [];
    for (const keyEntry of this.subs.map.entries())
      for (const valueEntry of keyEntry[1].entries())
        for (const subRes of valueEntry[1].topFilters()) {
          unflatten(subRes, keyEntry[0], valueEntry[0]);
          res.push(subRes);
        }
    return res;
  }

  private results: Result[];
  private subs: KeyPairMap<string, Value, MassFilter<Result>>;
  private keys: Set<string>;
}
