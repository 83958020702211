import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { SvgIconPatient } from '@sqior/react/uibase';
import { PatientSex } from '@sqior/react/uipatient';
import { classes } from '@sqior/react/utils';
import { BedBlockStrength } from '@sqior/viewmodels/location';
import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedInfo,
  BedAllocationWardListItemBedState,
  BedAllocationWardListItemEntity,
  DischargeInfo,
} from '@sqior/viewmodels/occupancy';
import { PatientInfo } from '@sqior/viewmodels/patient';
import { PersonSex } from '@sqior/viewmodels/person';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext, useState } from 'react';
import styles from './bed-allocation-ward-list-item.module.css';

export type BedAllocationWardListItemProps = FactoryProps<BedAllocationWardListItemEntity>;

function subStyle(data: BedAllocationWardListItemBedInfo) {
  if (data.state === BedAllocationWardListItemBedState.Blocked)
    if (data.blockStrength === BedBlockStrength.Soft) return 'blocked-soft';
    else if (data.blockStrength === BedBlockStrength.Medium) return 'blocked-medium';
    else return 'blocked';
  if (data.state === BedAllocationWardListItemBedState.Free)
    if (data.sex === PersonSex.Male) return 'free-male';
    else if (data.sex === PersonSex.Female) return 'free-female';
    else if (data.sex === PersonSex.Diverse) return 'free-diverse';
    else return 'free';
  if (data.sex === PersonSex.Male) return 'occupied-male';
  else if (data.sex === PersonSex.Female) return 'occupied-female';
  else if (data.sex === PersonSex.Diverse) return 'occupied-diverse';
  else return 'occupied';
}

function isSplit(bed: BedAllocationWardListItemBed) {
  return (
    bed.current.state !== bed.later.state ||
    bed.current.sex !== bed.later.sex ||
    bed.current.blockStrength !== bed.later.blockStrength
  );
}

export function BedAllocationWardListItem(props: BedAllocationWardListItemProps) {
  const [displayBedInfo, setDisplayBedInfo] = useState<BedAllocationWardListItemBed | undefined>(
    undefined
  );
  const dispatcher = useContext(OperationContext);

  function showBedInfo(bed: BedAllocationWardListItemBed) {
    setDisplayBedInfo(bed);
  }
  function hideBedInfo() {
    setDisplayBedInfo(undefined);
  }
  function select(bed: BedAllocationWardListItemBed) {
    if (bed.select) {
      dispatcher.start(bed.select);
    }
  }

  return (
    <div className={styles['container']}>
      <div className={styles['label']}>{props.data.label}</div>
      <div className={styles['beds']}>
        {props.data.beds.map((bed) => (
          <div
            key={bed.id}
            className={classes(
              styles['bed'],
              styles[isSplit(bed) ? 'split' : 'solid'],
              isSplit(bed) ? undefined : styles[subStyle(bed.current)]
            )}
            onMouseOver={() => showBedInfo(bed)}
            onMouseLeave={() => hideBedInfo()}
            onClick={() => select(bed)}
          >
            {!isSplit(bed) && bed.current.inset && (
              <div className={styles['inset']}>{bed.current.inset}</div>
            )}
            {isSplit(bed) && (
              <div className={classes(styles['bed-current'], styles[subStyle(bed.current)])} />
            )}
            {isSplit(bed) && (
              <div className={classes(styles['bed-next'], styles[subStyle(bed.later)])} />
            )}
          </div>
        ))}
      </div>
      {displayBedInfo?.current.patient && (
        <BedAllocationDetailInfo
          patient={displayBedInfo?.current.patient}
          dischargeInfo={displayBedInfo.current.dischargeInfo}
        />
      )}
    </div>
  );
}

type BedAllocationDetailInfoProps = {
  patient?: PatientInfo;
  dischargeInfo?: DischargeInfo;
};
function BedAllocationDetailInfo(props: BedAllocationDetailInfoProps) {
  return (
    <div className={styles['detail-info']}>
      <SvgIconPatient className={styles['icon']} />
      <div className={styles['detail-info-meta']}>
        <InterweaveExt content={props.patient?.name} />
        <div className={styles['detail-info-meta-line']}>
          {props.patient?.sex && <PatientSex sex={props.patient?.sex} />}
          {props.patient?.dob && (
            <div className={styles['dob-info']}>
              <div>*{props.patient?.dob}</div>
            </div>
          )}
        </div>
        <div className={styles['detail-info-additional']}>
          {props.dischargeInfo && <div>{props.dischargeInfo.plannedDate}</div>}
          {props.patient?.spi && <div>{`SPI: ${Math.round(props.patient?.spi)}`}</div>}
        </div>
      </div>
    </div>
  );
}

export default BedAllocationWardListItem;
