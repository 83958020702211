import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { BedAllocationListItemEntity } from '@sqior/viewmodels/occupancy';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { ItemState } from '@sqior/viewmodels/visual';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext } from 'react';
import styles from './bed-allocation-list-item.module.css';

export type BedAllocationListItemProps = FactoryProps<BedAllocationListItemEntity>;

export function BedAllocationListItem(props: BedAllocationListItemProps) {
  const dispatcher = useContext(OperationContext);
  return (
    <div
      className={styles['main-container']}
      onClick={() => {
        dispatcher.start(SelectPatient(props.data.select));
      }}
    >
      <div className={styles['container']}>
        <div>
          <InterweaveExt
            className={
              styles[
                props.data.state === ItemState.Challenged
                  ? 'challenge'
                  : props.data.state === ItemState.Highlighted
                  ? 'highlight'
                  : 'normal'
              ]
            }
            content={props.data.patient}
          />
          {props.data.requestSource && (
            <span className={styles['request-source']}>{props.data.requestSource}</span>
          )}
        </div>
        {props.data.targetWard && (
          <div className={styles['highlight']}>{props.data.targetWard}</div>
        )}
        {props.data.diagnosis && <div>{props.data.diagnosis}</div>}
        {props.data.requirements && <div>{props.data.requirements}</div>}
      </div>
    </div>
  );
}

export default BedAllocationListItem;
