import { ClockTimestamp, now } from '@sqior/js/data';
import { DoNotCatchException } from '@sqior/js/error';
import { LogContent, LogData, LogLevel } from './log-data';
import { TextLogBackend } from './text-log-backend';

export type LogMessage = {
  level: LogLevel;
  content: LogContent;
  unclassified?: LogContent;
  timestamp?: ClockTimestamp;
};
export type LogOptions = { level: LogLevel; classified?: boolean };
export enum ErrorReportingMode {
  None,
  Log,
  Throw,
  LogAndThrow,
}

export abstract class LogInterface {
  constructor(options: LogOptions) {
    this.options = options;
  }

  log(message: LogMessage) {
    if (message.level <= this.options.level)
      if (this.options.classified && message.unclassified)
        this.doLog({
          level: message.level,
          content: message.unclassified,
          timestamp: message.timestamp,
        });
      else
        this.doLog({
          level: message.level,
          content: message.content,
          timestamp: message.timestamp,
        });
  }

  offer(level: LogLevel, log: (classified: boolean) => LogContent) {
    if (level <= this.options.level)
      this.log({ level: level, content: log(this.options.classified || false) });
  }

  error(content: LogContent, unclassified?: LogContent) {
    this.log({
      level: LogLevel.Critical,
      content: content,
      unclassified: unclassified,
      timestamp: now(),
    });
  }
  warn(content: LogContent, unclassified?: LogContent) {
    this.log({
      level: LogLevel.Warning,
      content: content,
      unclassified: unclassified,
      timestamp: now(),
    });
  }
  info(content: LogContent, unclassified?: LogContent) {
    this.log({
      level: LogLevel.Info,
      content: content,
      unclassified: unclassified,
      timestamp: now(),
    });
  }
  debug(content: LogContent, unclassified?: LogContent) {
    this.log({
      level: LogLevel.Debug,
      content: content,
      unclassified: unclassified,
      timestamp: now(),
    });
  }
  trace(content: LogContent, unclassified?: LogContent) {
    this.log({
      level: LogLevel.Trace,
      content: content,
      unclassified: unclassified,
      timestamp: now(),
    });
  }

  /** Combined method that logs and throws depending on the mode specified */
  reportError(
    content: LogContent,
    mode: ErrorReportingMode = ErrorReportingMode.LogAndThrow,
    unclassified?: LogContent
  ) {
    if (mode === ErrorReportingMode.Log || mode === ErrorReportingMode.LogAndThrow)
      this.error(content, unclassified);
    if (mode === ErrorReportingMode.Throw || mode === ErrorReportingMode.LogAndThrow)
      throw new Error('ERROR: ' + TextLogBackend.convert(content));
  }

  exception(exc: unknown): string {
    /* Check if this is an exception type that is not supposed to be caught */
    if (exc instanceof DoNotCatchException) throw exc;
    if (exc instanceof Error) return exc.message;
    return JSON.stringify(exc);
  }

  protected abstract doLog(data: LogData): void;

  readonly options: LogOptions;
}

export function logLevel(level: string): LogLevel {
  switch (level.toLowerCase()) {
    case 'fatal':
      return LogLevel.Fatal;
    case 'critical':
      return LogLevel.Critical;
    case 'warning':
      return LogLevel.Warning;
    case 'info':
      return LogLevel.Info;
    case 'debug':
      return LogLevel.Debug;
    case 'trace':
      return LogLevel.Trace;
  }
  return LogLevel.Info;
}
