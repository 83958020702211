import styles from './messenger.module.css';
import { useContext, useEffect, useState } from 'react';
import { AppVersionUpdatePage, ServerConnectionPage, useConnectionError } from '@sqior/react/uiapp';
import { classes } from '@sqior/react/utils';
import { MessengerMainPage } from '../messenger-main-page/messenger-main-page';
import { ConferencePage } from '@sqior/react/uiconference';
import { useDynamicStateRaw } from '@sqior/react/state';
import { ConferenceVM } from '@sqior/js/conference';
import MenuPage from '../menu-page/menu-page';
import { SelectionPageContext, SelectionStack, useSelectionStack } from '@sqior/react/uiselection';
import { OperationContext } from '@sqior/react/operation';
import {
  AppVersionUpdateData,
  AppVersionUpdatePath,
  ClosePage,
  MessengerTab,
} from '@sqior/viewmodels/app';
import { isMessengerMessengerTab, lastPage, useMessengerTabState } from '../messenger-tab-stack';

export enum MessengerPage {
  None,
  Camera,
  Conference,
}

/* eslint-disable-next-line */
export interface MessengerProps {
  className?: string;
  fullDashboard: boolean;
}

export function Messenger(props: MessengerProps) {
  const dispatcher = useContext(OperationContext);
  const tabStack = useMessengerTabState();

  const [page, setPage] = useState(MessengerPage.None);
  const connError = useConnectionError();

  function closePopupPage() {
    dispatcher.start(ClosePage());
  }

  /* Get the app version update state */
  const avus = useDynamicStateRaw<AppVersionUpdateData>(AppVersionUpdatePath);
  /* Activate the conferencing page if a conference is started */
  const conf = useDynamicStateRaw<ConferenceVM>('conference');
  useEffect(() => {
    if (conf && conf.partners.length) {
      if (page !== MessengerPage.Conference) setPage(MessengerPage.Conference);
    } else if (page === MessengerPage.Conference) setPage(MessengerPage.None);
  }, [conf, page, setPage]);

  const selectionStack = useSelectionStack();
  return (
    <SelectionPageContext.Provider
      value={(menu, onClose) => {
        selectionStack[0].add({ data: menu, cb: onClose });
      }}
    >
      <div className={classes(styles['messenger'], props.className)}>
        <MessengerMainPage fullDashboard={props.fullDashboard} tabStack={tabStack} page={page} />
        {page === MessengerPage.Conference && conf && <ConferencePage conf={conf} />}
        {isMessengerMessengerTab(lastPage(tabStack), MessengerTab.Menu) && (
          <MenuPage
            onClose={() => {
              closePopupPage();
            }}
          />
        )}

        {selectionStack[1].length > 0 && (
          <div className={styles['menu']}>
            <div className={styles['selection']}>
              <SelectionStack data={selectionStack} />
            </div>
          </div>
        )}

        {connError && <ServerConnectionPage />}
        {avus && <AppVersionUpdatePage data={avus} />}
      </div>
    </SelectionPageContext.Provider>
  );
}

export default Messenger;
