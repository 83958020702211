import { Logger } from '@sqior/js/log';
import { BearerTokenAuthContext, TokenGenerationResult } from './auth-context';
import { AuthProvider } from './auth-provider';
import { ClockTimestamp, addMinutes } from '@sqior/js/data';

export class TestAuthProvider extends BearerTokenAuthContext implements AuthProvider {
  constructor(token = '', sessionStart?: ClockTimestamp) {
    super();
    this.token = token;
    this.sessionStart = sessionStart;
  }

  tryLogIn() {
    Logger.info('Attempt to log in');
  }
  logOut() {
    Logger.info('Logging out');
  }
  generateToken(scope = '') {
    scope;
    return new Promise<TokenGenerationResult>((resolve) => {
      resolve({ token: this.token, sessionStart: this.sessionStart });
    });
  }
  async getIdentityToken(): Promise<string | undefined> {
    return undefined;
  }

  refreshToken(token: string) {
    this.token = token;
    this.tokenRefreshed.emit(this.token);
  }

  /* Reload every minute on failed authentication (prevents the page from permanently cycling if wrong kiosk token is provided) */
  authFailedReloadPeriod() {
    return addMinutes(1);
  }

  private token: string;
  private sessionStart?: ClockTimestamp;
  readonly isAuthenticated = new Promise<boolean>((resolve) => {
    resolve(true);
  });
  readonly userInfo = { name: 'Test' };
}
