import { mixInSelectionOption, SelectionMenu } from '@sqior/viewmodels/input';
import { ValueObject } from '@sqior/js/data';
import { ComponentFactory, FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { ClosablePage } from '@sqior/react/uibase';
import { useContext } from 'react';
import styles from './selection-page.module.css';

export type SelectionPageProps = FactoryProps<SelectionMenu>;

export function SelectionPage(props: SelectionPageProps) {
  const FactoryComponent = useContext(ComponentFactory);

  /* Operation emitted */
  const dispatcher = useContext(OperationContext);
  const confirm = (data: ValueObject) => {
    if (props.data.operation) dispatcher.start(mixInSelectionOption(props.data.operation, data));
    if (props.onClose) props.onClose(true, data);
  };

  return (
    <ClosablePage
      onClose={() => {
        if (props.onClose) props.onClose(false);
      }}
    >
      <div className={styles['container']}>
        <div className={styles['spacer']} />
        <div className={styles['title']}>{props.data.title}</div>
        <div className={styles['spacer']} />
        <FactoryComponent
          data={props.data.selection}
          onSelection={(value: ValueObject | undefined) => {
            if (value !== undefined) confirm(value);
            else if (props.onClose) props.onClose(false);
          }}
        />
        <div className={styles['spacer']} />
        <div className={styles['spacer']} />
        <div className={styles['spacer']} />
      </div>
    </ClosablePage>
  );
}

export default SelectionPage;
