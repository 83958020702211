import { LogicalTimestamp, PatchValue } from '@sqior/js/data';
import { Message } from '@sqior/js/message';
import { SubStateValues } from '@sqior/js/state';

export interface StateReplaceMessage extends Message {
  states: SubStateValues;
}
export interface StateUpdateMessage extends Message {
  path: string;
  patch: PatchValue;
  timestamp: LogicalTimestamp;
}
export interface StateConfirmMessage extends Message {
  path: string;
}
export enum StateMessageType {
  ReplaceState = 'state.replace',
  UpdateState = 'state.update',
  ConfirmState = 'state.confirm',
}
