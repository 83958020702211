import styles from './cave.module.css';
import { CaveSeverity } from '@sqior/viewmodels/patient';

export interface CaveProps {
  severity: CaveSeverity;
  showMultiple?: CaveSeverity[];
}

export function Cave(props: CaveProps) {
  function mapSeverityColor(severity: CaveSeverity): string {
    return {
      [CaveSeverity.Info]: '#1CADE4',
      [CaveSeverity.Warning]: '#FFC000',
      [CaveSeverity.Critical]: '#F55D5D',
    }[severity];
  }
  const color = mapSeverityColor(props.severity);
  const multipleColors =
    props.showMultiple?.map((severity: CaveSeverity) => {
      return mapSeverityColor(severity);
    }) || [];

  let width = 18;
  if (multipleColors.length > 0) {
    width = 22;
  }
  if (multipleColors.length > 1) {
    width = 28;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container-element']}>
        <svg
          width={width}
          height="24"
          viewBox={`0 0 ${width} 24`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {multipleColors.length > 0 ? (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.0619 19.9381L19.5858 13.4142C20.3669 12.6332 20.3669 11.3668 19.5858 10.5858L13.0615 4.06149C13.8176 3.65942 14.7774 3.7769 15.4144 4.41391L21.586 10.5855C22.367 11.3665 22.367 12.6329 21.586 13.4139L15.4144 19.5855C14.7775 20.2224 13.8179 20.3399 13.0619 19.9381Z"
              fill={multipleColors[0]}
            />
          ) : undefined}
          {multipleColors.length > 1 ? (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.0619 19.9381L24.5858 13.4142C25.3669 12.6332 25.3669 11.3668 24.5858 10.5858L18.0615 4.06149C18.8176 3.65942 19.7774 3.7769 20.4144 4.41391L26.586 10.5855C27.367 11.3665 27.367 12.6329 26.586 13.4139L20.4144 19.5855C19.7775 20.2224 18.8179 20.3399 18.0619 19.9381Z"
              fill={multipleColors[1]}
            />
          ) : undefined}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.41391 10.586C0.632864 11.367 0.632862 12.6334 1.41391 13.4144L7.58548 19.586C8.36653 20.367 9.63286 20.367 10.4139 19.586L16.5855 13.4144C17.3665 12.6334 17.3665 11.367 16.5855 10.586L10.4139 4.4144C9.63288 3.63335 8.36655 3.63335 7.5855 4.4144L1.41391 10.586ZM7.9997 7.0002C7.9997 6.44791 8.44741 6.0002 8.9997 6.0002C9.55198 6.0002 9.9997 6.44791 9.9997 7.0002V12.5002C9.9997 13.0525 9.55198 13.5002 8.9997 13.5002C8.44741 13.5002 7.9997 13.0525 7.9997 12.5002V7.0002ZM9.9997 16.0002C9.9997 16.5525 9.55198 17.0002 8.9997 17.0002C8.44741 17.0002 7.9997 16.5525 7.9997 16.0002C7.9997 15.4479 8.44741 15.0002 8.9997 15.0002C9.55198 15.0002 9.9997 15.4479 9.9997 16.0002Z"
            fill={color}
          />
        </svg>
      </div>
    </div>
  );
}

export default Cave;
