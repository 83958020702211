import { AddressInfoVM } from '@sqior/viewmodels/communication';
import { WorkflowOverview } from '@sqior/viewmodels/workflow';
import { ValueObject } from '@sqior/js/data';
import { CaveInfoVM, PatientOverview } from '@sqior/viewmodels/patient';

export const ORWorkflowOverviewType = 'ORWorkflowOverview';

export enum EmergencyCategoryType {
  N0 = 'n0',
  N1 = 'n1',
  N2 = 'n2',
  N3 = 'n3',
  N4 = 'n4',
  N5 = 'n5',
}

export type EmergencyClassificationVM = {
  category: EmergencyCategoryType;
  note?: string;
  color?: string;
  opacity?: number;
  emergencyCountdown?: boolean;
};

export type ORWorkflowOverview = WorkflowOverview & {
  relatedId?: ValueObject;
  shortDescription?: string;
  longDescription?: string;
  location?: string;
  specialty?: string;
  specialtyColor?: string;
  team?: AddressInfoVM[];
  caveInfos?: CaveInfoVM[];
  emergencyClassification?: EmergencyClassificationVM;
  indicationTimestamp?: number;
};

export function getTeamFromOverview(overview?: PatientOverview) {
  return overview && overview.content ? (overview.content as ORWorkflowOverview).team : undefined;
}
