import { ReadConfirmationTypes, SendChat } from '@sqior/viewmodels/communication';
import { OperationContext } from '@sqior/react/operation';
import { ButtonX, ContainerWithShadow, ExpandableTextarea } from '@sqior/react/uibase';
import { InputMainButton, Thread } from '@sqior/react/uiconversation';
import React, { KeyboardEvent, useContext, useState } from 'react';
import styles from './chat.module.css';
import { classes } from '@sqior/react/utils';

/* eslint-disable-next-line */
export interface ChatProps {
  className?: string;
}

export function Chat(props: ChatProps) {
  const [input, setInput] = useState<string>('');

  function onInputChanged(e: React.FormEvent<HTMLTextAreaElement>) {
    setInput(e.currentTarget.value);
  }
  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      send();
    }
  }
  function cancelInput() {
    setInput('');
  }

  const dispatcher = useContext(OperationContext);
  function send() {
    if (input !== '') {
      setInput('');
      dispatcher.start(SendChat(input));
    }
  }

  return (
    <div className={classes(styles['container'], props.className)}>
      <ContainerWithShadow className={styles['thread-area']}>
        <Thread stateBasePath="current-chat/data" autoMarkReadAs={ReadConfirmationTypes.Explicit} />
      </ContainerWithShadow>
      <div className={styles['input-panel']}>
        <div className={styles['input-editor-frame']}>
          <ExpandableTextarea
            className={styles['input-control']}
            classNameEdit={styles['input-control-textarea']}
            value={input}
            onInput={onInputChanged}
            onKeyDown={onKeyDown}
          />
          {input !== '' && (
            <ButtonX onClick={cancelInput} additionalClassName={styles['cancelbutton']} />
          )}
        </div>
        <div className={styles['input-panel-button']}>
          <InputMainButton onClick={send} enabled={input !== ''} />
        </div>
      </div>
    </div>
  );
}

export default Chat;
