import SvgButton from '../svg-button/svg-button';
import { ReactComponent as Logo } from './search-button.svg';

/* eslint-disable-next-line */
export interface SearchButtonProps {
  onClick?: () => void;
}

export function SearchButton(props: SearchButtonProps) {
  return (
    <SvgButton onClick={props.onClick}>
      <Logo fill="#ffffff" viewBox="0 0 40 40" />
    </SvgButton>
  );
}

export default SearchButton;
