import { Closable } from '@sqior/js/async';
import { Operation, OperationHandler } from '@sqior/js/operation';
import { State } from '@sqior/js/state';
import { StateHandler } from './state-handler';
import { StopListening } from '@sqior/js/event';

/** This class represents a state handler that proxies the state from another state handler and relays the operations to it */
export class ProxyStateHandler implements OperationHandler, Closable {
  async close() {
    const source = this.detach();
    if (source) await source.close();
  }

  /** Handles an operation */
  handle(op: Operation, path: string) {
    /* Relay if applicable */
    if (this.source) this.source.handle(op, path);
    else op.fail();
  }

  /** Attaches a new proxy source */
  async attach(source: StateHandler) {
    /* Detach first */
    const oldSource = this.detach();
    if (oldSource) await oldSource.close();
    /* Set new source */
    this.source = source;
    /* Copy the state */
    this.state.set(source.state.getRaw());
    /* Observe the source state for changes */
    this.listener = source.state.on((value) => {
      this.state.set(value);
    });
  }

  /** Detaches this from the proxy source */
  detach() {
    if (this.listener) this.listener();
    this.listener = undefined;
    const source = this.source;
    this.source = undefined;
    return source;
  }

  protected source?: StateHandler;
  private listener?: StopListening;
  readonly state = new State();
}
