import { Bytes, ClockTimestamp } from '@sqior/js/data';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { UID } from '@sqior/js/uid';

/* Specification of the media upload operation */
export type MediaInfo = { timestamp: ClockTimestamp; type: string; data: Bytes };
export function UploadMedia(
  timestamp: number,
  type: string,
  data: Bytes
): OperationSpec<MediaInfo> {
  return {
    type: OperationType.Add,
    path: 'media',
    data: { timestamp: timestamp, type: type, data: data },
  };
}
export function DeleteMedia(id: UID): OperationSpec<UID> {
  return { type: OperationType.Delete, path: 'media', data: id };
}

/* Specification of the media integration operation */
export type MediaIntegrationInfo = { id: UID; title: string; width: number; height: number };
export function IntegrateMedia(
  id: UID,
  title: string,
  width: number,
  height: number
): OperationSpec<MediaIntegrationInfo> {
  return {
    type: OperationType.Add,
    path: 'integrate-media',
    data: { id: id, title: title, width: width, height: height },
  };
}
