import styles from './specialty-view.module.css';
import { classes } from '@sqior/react/utils';
import { CSSProperties } from 'react';

/* eslint-disable-next-line */
export interface SpecialtyViewProps {
  specialtyName?: string;
  specialtyColor?: string;
  style?: CSSProperties;
  className?: string;
}

const getSpecialtyContainerStyle = (specialtyColor: string | undefined) => {
  return classes(styles['specialty-container'], styles[`specialty-${specialtyColor}`]);
};

export function SpecialtyView({
  specialtyName,
  specialtyColor,
  style,
  className,
}: SpecialtyViewProps) {
  if (!specialtyName) return null;

  return (
    <div>
      <div className={classes(getSpecialtyContainerStyle(specialtyColor), className)} style={style}>
        <p className={styles['specialty-content']}>{specialtyName}</p>
      </div>
    </div>
  );
}

export default SpecialtyView;
