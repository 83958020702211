import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import { useContext } from 'react';
import { ServerConnectionContext } from '../server-connection-context/server-connection-context';
import { ReactComponent as ErrorIcon } from './conn-error.svg';
import { ReactComponent as DetailInfoIcon } from './conn-error-detailinfo.svg';
import styles from './server-connection-page.module.css';

/* eslint-disable-next-line */
export interface ServerConnectionPageProps {}

export function ServerConnectionPage(props: ServerConnectionPageProps) {
  const textDict = useTextResources();
  const connContext = useContext(ServerConnectionContext);

  return (
    <div className={styles['container']}>
      <ErrorIcon className={styles['icon']} />
      <div className={styles['description']}>{textDict.get('connection_lost')}</div>
      <div className={styles['assistance']}>{textDict.get('check_connection')}</div>
      <HighlightButton
        additionalClassName={styles['button']}
        onClick={() => {
          connContext.reconnect();
        }}
      >
        {textDict.get('connect')}
      </HighlightButton>
      <div className={styles['detailinfo']}>
        <DetailInfoIcon className={styles['detailinfo-icon']} viewBox="0 0 40 40" />
        <div className={styles['detailinfo-text']}>{connContext.errorString(textDict)}</div>
      </div>
    </div>
  );
}

export default ServerConnectionPage;
