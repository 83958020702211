import { BiChannel, Message } from '@sqior/js/message';
import { StateReplaceMessage, StateUpdateMessage, StateMessageType } from './state-message';
import { State } from '@sqior/js/state';
import { ValuePatch } from '@sqior/js/data';

export class StateReceiver {
  constructor(channel: BiChannel, state = new State(), resetOnClose = false) {
    this.state = state;
    this.paths = new Set<string>();

    /* Connect to the closing signal of the incoming data, reset the state in this case */
    if (resetOnClose)
      channel.in.onClose(() => {
        for (const path of this.paths) this.state.setSub(path, undefined);
        this.paths.clear();
      });

    /* Connect to the state message providing a new state */
    channel.in.on(StateMessageType.ReplaceState, (msg: Message) => {
      const message = msg as StateReplaceMessage;

      /* Set all received values at their respective paths */
      const newPaths = new Set<string>();
      for (const subState of message.states) {
        this.state.setSub(subState.path, subState.value, subState.timestamp);
        newPaths.add(subState.path);
      }

      /* Eliminate all values that were set by this so far and which
         are no longer found in the new state that replaces it */
      for (const path of this.paths) if (!newPaths.has(path)) this.state.setSub(path, undefined);
      this.paths = newPaths;
    });

    /* Connect to the state message providing a state update */
    channel.in.on(StateMessageType.UpdateState, (msg: Message) => {
      const message = msg as StateUpdateMessage;

      /* Send back confirmation */
      channel.out.send({ type: StateMessageType.ConfirmState, path: message.path });

      /* Set new value */
      this.state.changeSubRaw(
        message.path,
        (value) => {
          return ValuePatch.patch(value, message.patch);
        },
        message.timestamp
      );

      /* Remember that this was set */
      this.paths.add(message.path);
    });
  }

  readonly state: State;
  private paths: Set<string>;
}
