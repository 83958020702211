import { AddressInfoVM } from '@sqior/viewmodels/communication';
import { classes } from '@sqior/react/utils';
import AddressItem from '../address-item/address-item';
import styles from './address-list.module.css';
import { ValueObject } from '@sqior/js/data';

/* eslint-disable-next-line */
export interface AddressListProps {
  className?: string;
  items: AddressInfoVM[];
  relatedId?: ValueObject; // If specified, this reference be used as related entity to e.g. start a chat
}

export function AddressList(props: AddressListProps) {
  return (
    <div className={classes(styles['container'], props.className)}>
      {props.items.map((ai, idx) => (
        <AddressItem key={idx} item={ai} relatedId={props.relatedId} />
      ))}
    </div>
  );
}

export default AddressList;
