import { LanguageTextResourceMap } from '@sqior/js/language';

/** App text resources for the client */
export const AppClientTextResources: LanguageTextResourceMap = {
  de: {
    qr_code_scan: 'QR-Code scannen',
    qr_code_scan_description:
      'Bitte richten Sie die Kamera auf den QR-Code. Sie können QR-Codes von Patientenarmbändern, Räumen und Mitarbeiterausweisen scannen.',
    authentication_error: 'Fehler bei der Authentifizierung',
    cancel: 'Abbrechen',
    change_profile_picture: 'Profilbild ändern',
    change_roles: 'Rollen wechseln',
    change_room: 'Raum wechseln',
    change_user: 'Nutzer wechseln',
    cannot_establish_connection: 'Kann die Verbindung zum Server nicht aufbauen',
    chat_participants: 'Mitglieder',
    check_back: 'Rückfrage',
    check_connection: 'Bitte überprüfe deine Netzwerkverbindung und verbinde dich dann erneut.',
    command_history: 'Letzte Aktionen',
    connect: 'Verbinden',
    close: 'Schließen',
    connection_lost:
      'Die Verbindung zum Server wurde unterbrochen. Es können keine Nachrichten gesendet oder empfangen werden.',
    confirm: 'Bestätigen',
    cut_profile_picture: 'Profilbild zuschneiden',
    data_protection: 'Datenschutz',
    demo_layout: 'Demoansicht',
    dynamic_role_assignment: 'Dynamische Rollenzuordnung',
    fixed_push_roles_assignment: 'Push-Nachrichten für',
    fixed_roles_assignment: 'Feste Rollenzuordnung',
    fixed_room_assignment: 'Feste Raumzuordnung',
    grant_permission: 'Freigeben',
    grant_permission_notification: 'Freigabe für Browser-Benachrichtigungen erforderlich:',
    help_and_support: 'Hilfe & Support',
    input_search_term: 'Suchbegriff eingeben',
    later: 'Später',
    log_out: 'Abmelden',
    mark_read: 'Als gelesen markieren',
    new_app_version: 'Eine neue Version dieser App ist nun im App Store verfügbar!',
    no_entries: 'Aktuell keine Einträge',
    no_chats: 'keine Chats vorhanden',
    no_dynamic_role: 'keine Rolle ausgewählt',
    no_fixed_location: 'keine feste Zuordnung',
    no_fixed_push_roles: 'keine Rolle ausgewählt',
    no_fixed_roles: 'keine feste Zuordnung',
    no_selection: 'keine Auswahl',
    occupancy_team: 'Belegteam',
    or_team: 'Kollegen kontaktieren',
    order: 'Beauftragen',
    percentage_uploaded: '% hochgeladen',
    permanent_settings: 'Dauerhafte Einstellungen',
    priority: 'Priorität',
    processing_image: 'Bild wird verarbeitet',
    reply_to: 'Antworten',
    qr_room_title: 'Raum auswählen',
    qr_room_description:
      'Hier kannst du die Räume auswählen, für die du zuständig bist. Die Benachrichtigungen, die du erhältst, werden auf diese Räume begrenzt. Du kannst auch mehrere Räume auswählen.',
    qr_room_current_rooms: 'Aktuelle Räume',
    qr_room_add_room: 'Raum hinzufügen',
    qr_user_title: 'Nutzer wechseln',
    qr_user_description:
      "Scanne den <span class='text-highlight'>QR-Code vom Mitarbeiterausweis</span> um dich schnell mit deiner Identität als Nutzer anzumelden.",
    restart_server: 'Server neustarten',
    restart_server_confirm: 'Neustart bestätigen',
    revert: 'Rückgängig',
    select_chat_partner: 'Gesprächspartner auswählen',
    settings: 'Einstellungen',
    setting_phonenumber_device: 'Geräte-Telefonnummer',
    setting_phonenumber_user: 'Nutzer-Telefonnummer',
    support_infos: 'Informationen für den Support:',
    support_infos_versions: 'Versionen',
    support_infos_deployment: 'Deployment',
    support_infos_licenses: 'Lizenzen',
    support_infos_logs: 'Logeinträge',
    support_infos_server_version: 'Server:',
    support_infos_server_time: 'Server Zeit:',
    support_infos_wep_version: 'Web-Client:',
    support_infos_native_version: 'Native-Client:',
    support_infos_deployment_url: 'Addresse:',
    take_photo: 'Aufnehmen',
    take_profile_picture: 'Profilbild aufnehmen',
    take_over: 'Übernehmen',

    information_messages: 'Aktualisierungen & Ereignisse',

    super_user: 'Super-Benutzer',

    tab_task_label: 'Aufgaben',
    tab_list_label: 'Übersicht',
    tab_chat_label: 'Chat',

    upload_file_label: 'Datei hochladen',
  },
};
