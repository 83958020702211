import { Emitter } from '@sqior/js/event';
import { Channel } from './channel';

export class BiChannel {
  constructor(out: Channel = new Channel(), inChannel: Channel = new Channel()) {
    this.out = out;
    this.in = inChannel;
  }

  reverse() {
    return new BiChannel(this.in, this.out);
  }

  /** Requests to close the channels */
  requestClose() {
    this.closeRequested.emit();
  }

  readonly out: Channel;
  readonly in: Channel;
  readonly closeRequested = new Emitter();
}
