import { FoldableText } from '@sqior/react/uibase';
import { WorkflowPath } from '@sqior/react/uiworkflow';
import { ReactComponent as DescriptionIcon } from './description.svg';
import { ReactComponent as LocationIcon } from './location.svg';
import styles from './orworkflow-area.module.css';
import { ORWorkflowOverview } from '@sqior/viewmodels/orworkflow';
import WorkflowBaseArea from '../workflow-base-area/workflow-base-area';
import SpecialtyView from '../specialty-view/specialty-view';

/* eslint-disable-next-line */
export interface ORWorkflowAreaProps {
  data: ORWorkflowOverview;
}

export function ORWorkflowArea(props: ORWorkflowAreaProps) {
  return (
    <WorkflowBaseArea
      relatedId={props.data.relatedId}
      team={props.data.team}
      headers={[
        (props.data.shortDescription || props.data.longDescription) && (
          <div key="description" className={styles['description-header']}>
            <DescriptionIcon className={styles['description-icon']} />
            <FoldableText
              shortClassName={styles['description']}
              longClassName={styles['long-description']}
              short={props.data.shortDescription}
              long={props.data.longDescription}
            />
          </div>
        ),
        props.data.location && (
          <div key="location" className={styles['description-header']}>
            <LocationIcon className={styles['description-icon']} />
            <div className={styles['location']}>
              <p className={styles['location-text']}>{props.data.location}</p>
              <SpecialtyView
                specialtyName={props.data.specialty}
                specialtyColor={props.data.specialtyColor}
                style={{
                  lineHeight: '18px',
                  marginTop: 3,
                }}
              />
            </div>
          </div>
        ),
      ]}
      caveInfos={props.data.caveInfos}
    >
      {props.data.workflow && (
        <WorkflowPath path={props.data.workflow} className={styles['content-workflow']} />
      )}
    </WorkflowBaseArea>
  );
}

export default ORWorkflowArea;
