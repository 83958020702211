import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import {
  ChecklistViewModel,
  ConfirmChecklistSection,
  SetChecklistItem,
} from '@sqior/viewmodels/checklist';
import { useContext } from 'react';
import ChecklistSectionControl from '../checklist-section-control/checklist-section-control';
import styles from './checklist-control.module.css';

export type ChecklistControlProps = FactoryProps<ChecklistViewModel>;

export function ChecklistControl(props: ChecklistControlProps) {
  const dispatcher = useContext(OperationContext);
  return (
    <div className={styles['container']}>
      {props.data.title && <div className={styles['title']}>{props.data.title}</div>}
      {props.data.sections.map((section, index) => (
        <ChecklistSectionControl
          data={section}
          key={index}
          select={(paragraph, item, option, check) => {
            dispatcher.start(
              SetChecklistItem(
                props.data.template,
                props.data.id,
                index,
                paragraph,
                item,
                option,
                check
              )
            );
          }}
          confirm={() => {
            dispatcher.start(ConfirmChecklistSection(props.data.template, props.data.id, index));
          }}
        />
      ))}
    </div>
  );
}

export default ChecklistControl;
