import styles from './orworkflow-list-item.module.css';
import { addMinutes } from '@sqior/js/data';
import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { CountdownTimer } from '@sqior/react/uibase';
import { useDynamicTime } from '@sqior/react/utils';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext } from 'react';
import { ORWorkflowListItemBase } from '@sqior/viewmodels/orworkflow';
import { ItemState } from '@sqior/viewmodels/visual';

export type ORWorkflowListItemProps = FactoryProps<ORWorkflowListItemBase>;

export function ORWorkflowListItem(props: ORWorkflowListItemProps) {
  const dispatcher = useContext(OperationContext);

  const timeout =
    props.data.countdown === 'Infinity' ? Number.POSITIVE_INFINITY : props.data.countdown;
  const duration = props.data.countdown === 'Infinity' ? Number.POSITIVE_INFINITY : addMinutes(60);
  const dynTime = useDynamicTime(timeout || 0, 60000);

  const emergency = props.data.emergencyClassification?.category;

  return (
    <div className={styles['main-container']}>
      <div
        className={styles['container']}
        onClick={() => {
          dispatcher.start(props.data.select);
        }}
      >
        <div className={styles['patient-container']}>
          {emergency && (
            <span className={styles['emergency']}>
              <b>{emergency.toUpperCase()}</b>
            </span>
          )}
          {props.data.prioGroup && (
            <span
              className={
                styles[props.data.prioGroup.state === ItemState.Challenged ? 'challenge' : 'normal']
              }
            >
              {props.data.prioGroup.label}
            </span>
          )}
          <InterweaveExt content={props.data.patient} />
        </div>
        {props.data.step && <InterweaveExt content={props.data.step} />}
        {props.data.description && <div>{props.data.description}</div>}
        {props.data.location && <InterweaveExt content={props.data.location} />}
      </div>
      {timeout && dynTime[1] > (props.data.countdownStart || 0) && (
        <CountdownTimer
          className={styles['countdown-timer']}
          timeout={timeout}
          duration={duration}
          state={props.data.countdownState}
        />
      )}
    </div>
  );
}

export default ORWorkflowListItem;
