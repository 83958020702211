import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { BedAllocationInFlightPatientInfoEntity } from '@sqior/viewmodels/occupancy';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { PersonSex } from '@sqior/viewmodels/person';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext } from 'react';
import styles from './bed-allocation-in-flight-patient-info.module.css';

export type BedAllocationInFlightPatientInfoProps =
  FactoryProps<BedAllocationInFlightPatientInfoEntity>;

function sexStyle(sex: string) {
  if (sex === PersonSex.Male) return styles['male'];
  else if (sex === PersonSex.Female) return styles['female'];
  else if (sex === PersonSex.Diverse) return styles['diverse'];
  return undefined;
}

export function BedAllocationInFlightPatientInfo(props: BedAllocationInFlightPatientInfoProps) {
  const dispatcher = useContext(OperationContext);
  return (
    <div
      className={styles['patient']}
      onClick={() => {
        dispatcher.start(SelectPatient(props.data.select));
      }}
    >
      <InterweaveExt className={sexStyle(props.data.sex)} content={props.data.label} />
      <div className={styles['request-source']}>{props.data.requestSource}</div>
      <div>{props.data.stepInfo}</div>
    </div>
  );
}

export default BedAllocationInFlightPatientInfo;
