import { addMinutes } from '@sqior/js/data';
import {
  PerformanceMetricProvider,
  PerformanceMonitorInterface,
  PerformanceUnit,
} from './performance-monitor-interface';
import { Closable } from '@sqior/js/async';
import { Logger } from './logger';
import { LogLevel } from './log-data';

/** Basic performance monitor determining and logging performance metrics periodically */
export class PerformanceMonitor implements PerformanceMonitorInterface, Closable {
  /** Constructor with interval parameter specifying the frequency of determining and logging the performance metrics */
  constructor(interval = addMinutes(15), level = LogLevel.Debug) {
    this.interval = setInterval(() => {
      this.collectAndLog();
    }, interval);
    this.logLevel = level;
  }

  /** Stops the performance monitor */
  async close() {
    clearInterval(this.interval);
  }

  /** Registers a metric provider */
  register(name: string, metricProvider: PerformanceMetricProvider) {
    this.providers.push([name, metricProvider]);
  }

  /** Creates the string for a value and unit */
  valueUnit(value: number, unit: PerformanceUnit): string {
    if (unit === PerformanceUnit.Count) return value.toString();
    /* Bytes */
    if (value >= 10 * 1024 * 1024) return Math.floor(value / 1024 / 1024).toString() + ' MB';
    else if (value >= 10 * 1024) return Math.floor(value / 1024).toString() + ' KB';
    else return value.toString() + ' B';
  }

  /** Collects and logs the performance metrics */
  collectAndLog() {
    for (const mp of this.providers) {
      const metrics = mp[1]();
      if (metrics.length)
        Logger.log({
          level: this.logLevel,
          content: [mp[0]].concat(
            metrics.map((metric) => {
              return metric.id + ': ' + this.valueUnit(metric.value, metric.unit) + ' ';
            })
          ),
        });
    }
  }

  private interval: ReturnType<typeof setInterval>;
  private logLevel: LogLevel;
  private providers: [string, PerformanceMetricProvider][] = [];
}
