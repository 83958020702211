import { FactoryContext } from '@sqior/react/factory';
import AboutInfo from './about-info/about-info';

export enum AdvancedPages {
  AboutInfo = 'page.about-info',
}

export function UIAdvancedPlugin(context: FactoryContext) {
  context.factory.add(AdvancedPages.AboutInfo, AboutInfo);
}
