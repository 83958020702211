import DashboardList from '../dashboard-list/dashboard-list';
import styles from './dashboard-stage.module.css';

/* eslint-disable-next-line */
export interface DashboardStageProps {
  title: string;
  path: string;
  className: string;
  separator: boolean;
}

export function DashboardStage(props: DashboardStageProps) {
  return (
    <div className={props.className}>
      <div className={styles['container']}>
        <div className={styles['title']}>{props.title}</div>
        <DashboardList
          className={styles[props.separator ? 'entries' : 'entries-no-sep']}
          path={props.path}
        />
      </div>
    </div>
  );
}

export default DashboardStage;
