import styles from './orworkflow-overview-dashboard.module.css';
import React, { startTransition, useContext, useEffect, useRef, useState } from 'react';
import { addSeconds, now } from '@sqior/js/data';
import { ORWorkflowOverviewDashboardData } from '@sqior/viewmodels/orworkflow';
import { ComponentFactory, factoryGetProp, FactoryProps } from '@sqior/react/factory';
import { ReactComponent as TargetIcon } from './target.svg';
import { motion } from 'framer-motion';
import ZoomLevelView from '../zoom-level-view/zoom-level-view';
import {
  ALLOWED_INTERVAL,
  INIT_SCROLL_MIN,
  INIT_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  SCROLL_STEP,
  VISIBLE_ZOOM_VIEW_TIMEOUT,
  ZOOM_STEP,
} from './constants';
import { TimePositionCalculator } from './TimePositionCalculator';
import { usePrevious } from '@sqior/react/hooks';
import { calculatePositionAndTime, formatTime, havePropsChanged, isToday } from './utils';
import OpConfigView from '../op-config-view/op-config-view';
import { useDynamicStateRaw } from '@sqior/react/state';
import { ViewportSize } from '@sqior/web/utils';

export type ORWorkflowOverviewDashboardProps = FactoryProps<ORWorkflowOverviewDashboardData>;
export type MotionLayout = boolean | 'position' | 'size' | 'preserve-aspect';

export let visibleTimeout: NodeJS.Timeout;

const MIN_WIDTH_CARD = 158; // min width of a card in px
const MENU_WIDTH = 64; // width of the menu in px
const CARD_HOVER_BACKGROUND_COLOR = 'rgba(9,15,35)';

export function ORWorkflowOverviewDashboard(props: ORWorkflowOverviewDashboardProps) {
  const {
    data: { clusters, interval, core, target },
  } = props;
  const viewport = useDynamicStateRaw<ViewportSize>('app/viewport');
  const startY = useRef<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<HTMLDivElement>(null);

  const childRefs = useRef<HTMLDivElement[]>([]);
  const autoScroll = factoryGetProp('autoScroll', props);
  const openConfig = factoryGetProp<boolean | undefined>('openConfig', props);
  const showChallenges = factoryGetProp<boolean | undefined>('showChallenges', props);

  const selectedDate = props.data.date;
  const selectedDateDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day);

  const prevProps = usePrevious(props);
  const FactoryComponent = useContext(ComponentFactory);

  /* Current time indicator */
  const [currentTime, setCurrentTime] = useState(now());
  const [animationSyncDate, setAnimationSyncDate] = useState<Date>();
  const [zoomLevel, setZoomLevel] = useState<number>(INIT_ZOOM_LEVEL);
  const [isZoomVisible, setIsZoomVisible] = useState(false);
  const [motionLayout, setMotionLayout] = useState<MotionLayout | undefined>('position');
  const [scrollMin, setScrollMin] = useState<number>(INIT_SCROLL_MIN);
  const [currentPos, setCurrentPos] = useState<string>('0%');
  const [timeCalc, setTimeCalc] = useState<TimePositionCalculator>();
  const [targetDate, setTargetDate] = useState<Date>(new Date());
  const [tabAnimation, setTabAnimation] = useState<boolean>(true);
  const [thumbWidth, setThumbWidth] = useState<number>(0);

  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [dragging, setDragging] = useState<boolean>(false);
  const [scrollStart, setScrollStart] = useState<number>(0);
  const [startX, setStartX] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(now());
    }, addSeconds(10));
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const { currentPos, timeCalc, targetDate } = calculatePositionAndTime({
      currentTime,
      selectedDate,
      core,
      scrollMin,
      zoomLevel,
      target,
      interval,
    });
    setCurrentPos(currentPos);
    setTimeCalc(timeCalc);
    setTargetDate(targetDate);
  }, [currentTime, selectedDate, core, scrollMin, zoomLevel, target, interval]);

  const adjustChildPosition = (childElement: HTMLDivElement, assign = true) => {
    if (!childElement) return;
    if (assign) childRefs.current.push(childElement);
    const childRect = childElement.getBoundingClientRect();
    if (!childElement.parentElement) return;
    const parentRect = childElement.parentElement.getBoundingClientRect();
    if (childRect.y < parentRect.y) {
      const childHeight = childElement.clientHeight;
      const positionDifference = parentRect.y - childRect.y;
      if (positionDifference > 0 && positionDifference <= childHeight)
        childElement.style.paddingTop = positionDifference - 8 + 'px';
    } else childElement.style.paddingTop = '0px';
  };

  childRefs.current.forEach((child) => {
    adjustChildPosition(child, false);
  });

  useEffect(() => {
    if (havePropsChanged(prevProps, props)) setAnimationSyncDate(new Date());
  }, [props, prevProps]);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      setMotionLayout(undefined);
      event.preventDefault();
      event.stopPropagation();

      if (event.ctrlKey || event.metaKey || event.shiftKey) handleZoom(event);
      else handleScroll(event);

      startTransition(() => {
        setMotionLayout('position');
      });
    };

    const handleZoom = (event: WheelEvent) => {
      if (visibleTimeout) clearTimeout(visibleTimeout);
      setIsZoomVisible(true);
      const direction = (event.deltaY || event.deltaX) > 0 ? ZOOM_STEP : -ZOOM_STEP;
      setZoomLevel((prev) => {
        let newZoomLevel = Math.round((prev + direction) * 10) / 10;
        if (newZoomLevel > MAX_ZOOM_LEVEL) newZoomLevel = MAX_ZOOM_LEVEL; // set max zoom level to 2
        if (newZoomLevel < MIN_ZOOM_LEVEL) newZoomLevel = MIN_ZOOM_LEVEL; // set min zoom level to 0.5
        return newZoomLevel;
      });
      visibleTimeout = setTimeout(() => {
        setIsZoomVisible(false);
      }, VISIBLE_ZOOM_VIEW_TIMEOUT);
    };

    const handleScroll = (event: WheelEvent) => {
      const delta = event.deltaY;
      if (delta > 0) {
        setScrollMin((prev) => {
          const newScrollMin = prev + SCROLL_STEP;
          const { timeCalc } = calculatePositionAndTime({
            currentTime,
            selectedDate,
            core,
            scrollMin: newScrollMin,
            zoomLevel,
            target,
            interval,
          });
          if (
            new Date(timeCalc.end).getTime() ===
            ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).end.getTime()
          )
            return prev;
          return newScrollMin;
        });
      }
      if (delta < 0) {
        setScrollMin((prev) => {
          const newScrollMin = prev - SCROLL_STEP;
          const { timeCalc } = calculatePositionAndTime({
            currentTime,
            selectedDate,
            core,
            scrollMin: newScrollMin,
            zoomLevel,
            target,
            interval,
          });
          if (
            new Date(timeCalc.start).getTime() ===
            ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).start.getTime()
          )
            return prev;
          return newScrollMin;
        });
      }
    };
    const scrollListener = (event: Event) => {
      const node = event.target as HTMLDivElement;
      const scrollLeft = node.scrollLeft;
      setScrollLeft(scrollLeft);
    };

    const handleTouchMove = (event: TouchEvent) => {
      // get the left scroll position of the scrollable element
      const node = event.target as HTMLDivElement;
      const scrollLeft = node.scrollLeft;
      setScrollLeft(scrollLeft);

      if (event.touches.length === 1) {
        // Single touch
        const moveY = event.touches[0].clientY;
        const threshold = 20;
        const diff = moveY - startY.current;

        if (diff > threshold) {
          const delta = startY.current - moveY;
          const newWheelEvent = new WheelEvent('wheel', {
            deltaY: delta,
            deltaX: 0,
          });
          handleWheel(newWheelEvent);
        } else if (diff < -threshold) {
          const delta = startY.current - moveY;
          const newWheelEvent = new WheelEvent('wheel', {
            deltaY: delta,
            deltaX: 0,
          });
          handleWheel(newWheelEvent);
        }
        // Update the start Y position for the next comparison
        startY.current = moveY;
      }
    };

    const handleTouchStart = (event: TouchEvent) => {
      const touch = event.touches[0];
      startY.current = touch.clientY;
    };

    const handleHorizontalScroll = (event: Event) => {
      const node = event.target as HTMLDivElement;
      if (!node) return;
      const containerWidth = node.offsetWidth;
      const contentWidth = node.scrollWidth;
      const visibleRatio = containerWidth / contentWidth;

      setThumbWidth(visibleRatio * containerWidth);
      setScrollLeft(node.scrollLeft * visibleRatio);
    };

    const container = containerRef.current;
    const scrollContainer = scrollContainerRef.current;
    if (!container || !scrollContainer) return;

    container.addEventListener('scroll', scrollListener);
    container.addEventListener('wheel', handleWheel, { passive: false });

    checkHorizontalScroll({ target: scrollContainer } as unknown as Event);

    scrollContainer.addEventListener('touchstart', handleTouchStart, { passive: false });
    scrollContainer.addEventListener('touchmove', handleTouchMove);
    scrollContainer.addEventListener('scroll', handleHorizontalScroll);

    return () => {
      container.removeEventListener('wheel', handleWheel);
      container.removeEventListener('scroll', scrollListener);

      scrollContainer.removeEventListener('touchmove', handleTouchMove);
      scrollContainer.removeEventListener('touchstart', handleTouchStart);
      scrollContainer.removeEventListener('scroll', handleHorizontalScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [core, currentTime, scrollMin, zoomLevel, selectedDate, interval, target]);
  const checkHorizontalScroll = (event: Event) => {
    const node = event.target as HTMLDivElement;
    if (!node) return;
    if (node.scrollWidth > node.clientWidth) {
      const containerWidth = node.offsetWidth;
      const contentWidth = node.scrollWidth;
      const visibleRatio = containerWidth / contentWidth;
      setThumbWidth(visibleRatio * containerWidth);
      setScrollLeft(node.scrollLeft * visibleRatio);
    } else {
      setThumbWidth(0);
    }
  };
  const handleResize = () => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;
    checkHorizontalScroll({ target: scrollContainer } as unknown as Event);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setDragging(true);
    setStartX(e.clientX);
    if (scrollContainerRef.current) {
      setScrollStart(scrollLeft);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (dragging && scrollContainerRef.current && scrollBarRef.current) {
      const deltaX = e.clientX - startX;
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const contentWidth = scrollContainerRef.current.scrollWidth;
      const visibleRatio = containerWidth / contentWidth;
      const maxScrollLeft =
        scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth;
      const scrollLeft = Math.min(Math.max(scrollStart + deltaX, 0), maxScrollLeft);
      scrollContainerRef.current.scrollLeft = scrollLeft;
      setScrollLeft(scrollLeft * visibleRatio);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);

  useEffect(() => {
    handleResize();
  }, [viewport]);

  return (
    <>
      <ZoomLevelView
        isZoomVisible={isZoomVisible}
        zoomLevel={zoomLevel}
        MAX_ZOOM_LEVEL={MAX_ZOOM_LEVEL}
        MIN_ZOOM_LEVEL={MIN_ZOOM_LEVEL}
      />
      <div
        ref={containerRef}
        className={styles['grid-container']}
        style={{
          gridTemplateColumns: `minmax(${MENU_WIDTH}px, ${MENU_WIDTH}px) 1fr`,
        }}
      >
        <div className={styles['empty']}>
          {clusters.map((cl) => (
            <div
              className={styles['or-header']}
              key={cl.title + '-header'}
              style={{ gridAutoColumns: `minmax(${MIN_WIDTH_CARD}px, 1fr)` }}
            >
              {cl.ors.map((or, index) => (
                <OpConfigView
                  key={or.name}
                  or={or}
                  openConfig={openConfig}
                  firstItem={index === 0}
                  lastItem={cl.ors.length - 1 === index}
                />
              ))}
            </div>
          ))}
        </div>

        <div className={styles['menu']}>
          {timeCalc &&
            timeCalc.hours.map((t) => (
              <div
                key={t}
                className={styles['time']}
                style={{
                  top: timeCalc.convertTimestamp(t),
                }}
              >
                {formatTime(new Date(t).getHours(), 0)}
              </div>
            ))}
          {timeCalc &&
            timeCalc.hours.map((t) => (
              <div
                key={t}
                className={styles['time-line']}
                style={{ top: timeCalc.convertTimestamp(t), zIndex: 99 }}
              />
            ))}

          {isToday({
            day: selectedDate.day,
            month: selectedDate.month,
            year: selectedDate.year,
            currentTime,
            interval,
          }) && (
            <div className={styles['current-time']} style={{ top: currentPos, width: MENU_WIDTH }}>
              {formatTime(new Date(currentTime).getHours(), new Date(currentTime).getMinutes())}
            </div>
          )}
          {timeCalc && target && (
            <TargetIcon
              className={styles['target-icon']}
              style={{ top: timeCalc.convertTimestamp(targetDate.getTime()) }}
            />
          )}
        </div>
        <div ref={scrollContainerRef} className={styles['header-content']}>
          <div className={styles['header']}>
            {clusters.map((cl) => (
              <div
                className={styles['or-header']}
                key={cl.title + '-header'}
                style={{ gridAutoColumns: `minmax(${MIN_WIDTH_CARD}px, 1fr)` }}
              >
                {cl.ors.map((or, index) => (
                  <OpConfigView
                    key={or.name}
                    or={or}
                    openConfig={openConfig}
                    firstItem={index === 0}
                    lastItem={cl.ors.length - 1 === index}
                  />
                ))}
              </div>
            ))}
          </div>

          <div className={styles['content']}>
            {timeCalc &&
              timeCalc.hours.map((t) => (
                <div
                  key={t}
                  className={styles['time-line-soft']}
                  style={{ top: timeCalc.convertTimestamp(t) }}
                />
              ))}
            {timeCalc && target && (
              <div
                className={styles['target-time-line']}
                style={{ top: timeCalc.convertTimestamp(targetDate.getTime()) }}
              />
            )}

            {isToday({
              day: selectedDate.day,
              month: selectedDate.month,
              year: selectedDate.year,
              currentTime,
              interval,
            }) && (
              <>
                <div
                  className={styles['current-time-line']}
                  style={{ top: currentPos, zIndex: 15 }}
                />
                <div className={styles['current-time-indicator']} style={{ top: currentPos }} />
              </>
            )}

            {clusters.map((cl) => (
              <div
                key={cl.title}
                className={styles['or-items-outer']}
                style={{
                  gridAutoColumns: `minmax(${MIN_WIDTH_CARD}px, 1fr)`,
                }}
              >
                {cl.ors.map((or) => (
                  <div key={or.name} className={styles['or-items']}>
                    {timeCalc &&
                      or.items.map((item) => (
                        <motion.div
                          key={JSON.stringify(item.component)}
                          ref={adjustChildPosition}
                          whileHover={{
                            backgroundColor: CARD_HOVER_BACKGROUND_COLOR,
                          }}
                          whileTap={{ scale: tabAnimation ? 0.9 : 1 }}
                          className={styles['or-item']}
                          style={{
                            top: timeCalc.convertTimestamp(item.start),
                            bottom: timeCalc.convertTimestampBottom(item.end),
                          }}
                        >
                          <FactoryComponent
                            data={item.component}
                            sync={animationSyncDate}
                            autoScroll={autoScroll}
                            showChallenges={showChallenges}
                            motionLayout={motionLayout}
                            setTabAnimation={setTabAnimation}
                          />
                          <div
                            className={styles['or-item-background']}
                            style={{
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              width: '100%',
                              zIndex: 10,
                            }}
                          />
                          {item.markers.map((marker) => (
                            <motion.div
                              layout={motionLayout}
                              key={marker.label}
                              className={styles['or-item-marker']}
                              style={{
                                top: timeCalc.convertMarkerTimestamp({
                                  markerTimeStamp: marker.timestamp,
                                  itemStart: item.start,
                                  itemEnd: item.end,
                                }),
                              }}
                              transformTemplate={(_, generated) => `translateY(-50%) ${generated}`}
                            >
                              <p className={styles['or-item-marker-text']}>{marker.label}</p>
                            </motion.div>
                          ))}
                        </motion.div>
                      ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <motion.div
            ref={scrollBarRef}
            className={styles['scroll-bar']}
            onMouseDown={handleMouseDown}
            animate={{
              left: scrollLeft + MENU_WIDTH,
              transition: {
                duration: 0,
              },
            }}
            style={{
              width: thumbWidth,
              zIndex: 9999,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ORWorkflowOverviewDashboard;
