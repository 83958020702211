import { wrapFunction } from './wrapped-function';

export type ConcurrencyCheckPred<IdType = string> = (a?: IdType, b?: IdType) => boolean;

enum CheckResult {
  None,
  NotThisOne,
  Run,
}

export class FunctionQueue<IdType = string> {
  constructor(
    concurrencyCheck: ConcurrencyCheckPred<IdType> = FunctionQueue.Sequential,
    concurrencyLimit = 999999
  ) {
    this.concurrencyCheck = concurrencyCheck;
    this.concurrencyLimit = concurrencyLimit;
  }

  async close() {
    /* Empty next functions so that no new one is started */
    this.waiting = [];
    /* Wait for all outstanding promises */
    while (this.running.length) await this.running[0].value;
  }

  add<ReturnType = void>(func: () => Promise<ReturnType>, id?: IdType): Promise<ReturnType> {
    /* Ids can only be used if a concurrency check function is registered */
    if (id && !this.concurrencyCheck)
      throw new Error(
        'Id provided to function queue although no concurrency check function is registered'
      );
    /* Wrap the function to be parameter less which is easier to handle */
    const [wrapped, res] = wrapFunction(func);
    /* Check if the item needs to be queued */
    if (this.checkStart(this.waiting.length, id) === CheckResult.Run) this.start(wrapped, id);
    else this.waiting.push({ func: wrapped, id: id });
    return res;
  }

  private checkStart(waitListCount: number, id?: IdType): CheckResult {
    /* Quick exit if the concurrency limit is exceeded */
    if (this.running.length >= this.concurrencyLimit) return CheckResult.None;
    /* Quick check for constant predicates */
    if (this.concurrencyCheck === FunctionQueue.Concurrent) return CheckResult.Run;
    else if (this.concurrencyCheck === FunctionQueue.Sequential)
      return this.running.length + waitListCount === 0 ? CheckResult.Run : CheckResult.None;
    /* Check against all currently running functions */
    for (const running of this.running)
      if (!this.concurrencyCheck(running.id, id)) return CheckResult.NotThisOne;
    /* Check against all waiting functions up to the specified index */
    for (let i = 0; i < Math.min(waitListCount, this.waiting.length); i++)
      if (!this.concurrencyCheck(this.waiting[i].id, id)) return CheckResult.NotThisOne;
    return CheckResult.Run;
  }

  private start(func: () => Promise<void>, id?: IdType) {
    this.running.push({ func: func, value: this.run(func), id: id });
  }

  private async run(func: () => Promise<void>) {
    /* Perform the actual function */
    await func();
    /* Remove from list of running functions */
    this.running = this.running.filter((item) => {
      return item.func !== func;
    });
    /* Check if a waiting function can now be started */
    for (let w = 0; w < this.waiting.length; ) {
      const checkRes = this.checkStart(w, this.waiting[w].id);
      /* Check if the function can be started */
      if (checkRes === CheckResult.Run) {
        /* Remove from waiting list */
        const removed = this.waiting.splice(w, 1);
        /* Start */
        this.start(removed[0].func, removed[0].id);
      } else if (checkRes === CheckResult.None) break;
      /* Check if it makes sense to test for others */
      else w++;
    }
  }

  static Sequential<IdType = string>(a?: IdType, b?: IdType) {
    a;
    b;
    return false;
  }
  static Concurrent<IdType = string>(a?: IdType, b?: IdType) {
    a;
    b;
    return true;
  }
  static NonIdentity<IdType = string>(a?: IdType, b?: IdType) {
    return a !== b;
  }
  static NonGlobalOrIdentity<IdType = string>(a?: IdType, b?: IdType) {
    return a && b && a !== b ? true : false;
  }

  private running: { func: () => Promise<void>; value: Promise<void>; id?: IdType }[] = [];
  private waiting: { func: () => Promise<void>; id?: IdType }[] = [];
  private concurrencyCheck: ConcurrencyCheckPred<IdType>;
  private concurrencyLimit: number;
}
