import styles from './react-factory.module.css';

/* eslint-disable-next-line */
export interface ReactFactoryProps {}

export function ReactFactory(props: ReactFactoryProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactFactory!</h1>
    </div>
  );
}

export default ReactFactory;
