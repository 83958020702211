import { addHours, startOfDay } from 'date-fns';

export const BASE_START_MINUTES = -15;
export const BASE_END_MINUTES = 510;
export const MIN_ZOOM_LEVEL = 0.5; // 50%
export const MAX_ZOOM_LEVEL = 2; // 200%
export const INIT_ZOOM_LEVEL = 1; // 100%
export const ZOOM_STEP = 0.1; // 10%
export const INIT_SCROLL_MIN = 0; // minutes
export const SCROLL_STEP = 15; // minutes
export const VISIBLE_ZOOM_VIEW_TIMEOUT = 1000; // ms

export interface BaseIntervalAllowed {
  startHour: number;
  endHour: number;
}

interface IAllowedIntervalParams {
  selectedDate: Date;
  interval: BaseIntervalAllowed;
}
export const ALLOWED_INTERVAL = ({
  selectedDate,
  interval,
}: IAllowedIntervalParams): { start: Date; end: Date } => ({
  start: addHours(startOfDay(new Date(selectedDate)), interval.startHour),
  end: addHours(startOfDay(new Date(selectedDate)), interval.endHour),
});
