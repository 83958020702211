import { ComponentFactory, FactoryProps } from '@sqior/react/factory';
import styles from './orworkflow-interactive-dashboard.module.css';
import {
  NextDay,
  ORWorkflowInteractiveDashboardData,
  ORWorkflowOverviewDashboardData,
  ORWorkflowOverviewDashboardORData,
  PrevDay,
} from '@sqior/viewmodels/orworkflow';
import React, { useContext, useEffect, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import { format, isSameDay } from 'date-fns';
import { de } from 'date-fns/locale';
import {
  Button,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ConfigContext } from '@sqior/react/utils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { SvgCalendarMonth, SvgEventUpcoming, SvgIconDashboard } from '@sqior/react/uibase';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

declare module '@mui/material/IconButton' {
  export interface IconButtonPropsColorOverrides {
    grayed: true;
  }
}

export type ORWorkflowInteractiveDashboardProps = FactoryProps<ORWorkflowInteractiveDashboardData>;

enum DashboardType {
  NEW = 'new',
  OLD = 'old',
}

const extractFileName = (url: string) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};

const getFormattedDate = (day?: number, month?: number, year?: number): string => {
  try {
    if (!day || !month || !year) return '';
    const newDate = new Date(year, month - 1, day);
    return format(newDate, 'EEEE', {
      locale: de,
    });
  } catch (e) {
    return '';
  }
};

const isOPConfigDisabled = (dashboard?: ORWorkflowOverviewDashboardData): boolean => {
  if (!dashboard) return true;
  const ors: ORWorkflowOverviewDashboardORData[] = [];
  dashboard.clusters.forEach((cluster) => {
    cluster.ors.forEach((or) => {
      ors.push(or);
    });
  });
  // check if all dailyStartConfigurations are all undefined
  return ors.every((or) => or.dailyStartConfig === undefined);
};

export function ORWorkflowInteractiveDashboard({
  data: {
    date: { day, month, year },
    dashboard,
    nextDate,
    prevDate,
    oldDashboard,
    reportURL,
  },
}: ORWorkflowInteractiveDashboardProps) {
  const configContext = useContext(ConfigContext);
  const FactoryComponent = useContext(ComponentFactory);
  const dispatcher = useContext(OperationContext);

  const [selectedDashboard, setSelectedDashboard] = useState<DashboardType>(DashboardType.NEW);
  const [openConfig, setOpenConfig] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!isSameDay(new Date(year, month - 1, day), new Date())) {
      setSelectedDashboard(DashboardType.NEW);
    }
  }, [day, month, year]);

  const handleChangeDashboardView = (
    event: React.MouseEvent<HTMLElement>,
    dashboardType: DashboardType | null
  ) => {
    if (dashboardType && dashboardType !== selectedDashboard) {
      setSelectedDashboard(dashboardType);
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOpenConfig = () => {
    handleClose();
    setOpenConfig(true);
  };

  const handleCloseConfig = () => {
    setOpenConfig(false);
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper sx={{ width: 320, maxWidth: '100%', background: '#121930' }}>
          <MenuList>
            <a
              href={reportURL && configContext.getEndpoint(reportURL).toString()}
              download={reportURL && extractFileName(reportURL)}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <MenuItem
                onClick={handleClose}
                disabled={!reportURL}
                style={{ paddingLeft: 30, paddingRight: 30 }}
              >
                <ListItemIcon>
                  <FileDownloadOutlinedIcon />
                </ListItemIcon>
                <p style={{ color: '#1cade4' }}>Daten downloaden</p>
              </MenuItem>
            </a>
            <MenuItem
              disabled={isOPConfigDisabled(dashboard as ORWorkflowOverviewDashboardData)}
              onClick={handleOpenConfig}
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              <ListItemIcon>
                <SvgEventUpcoming style={{ width: 25 }} />
              </ListItemIcon>
              <p style={{ color: '#1cade4' }}>OP-Beginn konfigurieren</p>
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
      <div className={styles['container']}>
        <div className={styles['header']}>
          <p className={styles['font-bold-space']}>{getFormattedDate(day, month, year)}</p>
          <div className={styles['date-container']}>
            <p className={styles['box-number-2']}>{day.toString().padStart(2, '0')}</p>
            <p>.</p>
            <p className={styles['box-number-2']}>{month.toString().padStart(2, '0')}</p>
            <p>.</p>
            <p className={styles['box-number-4']}>{year}</p>
          </div>
          <div>
            <IconButton
              disabled={!prevDate}
              onClick={() => dispatcher.start(PrevDay())}
              color={'grayed'}
            >
              <NavigateBeforeRoundedIcon />
            </IconButton>
            <IconButton
              disabled={!nextDate}
              onClick={() => dispatcher.start(NextDay())}
              color="grayed"
            >
              <NavigateNextRoundedIcon />
            </IconButton>
          </div>

          <div style={{ flex: 1 }} />
          <div className={styles['right-buttons-container']}>
            <ToggleButtonGroup
              value={selectedDashboard}
              exclusive
              onChange={handleChangeDashboardView}
              aria-label="text alignment"
              style={{
                height: '100%',
              }}
            >
              <ToggleButton value={DashboardType.NEW} aria-label="new dashboard">
                <SvgCalendarMonth style={{ width: 30 }} />
              </ToggleButton>
              <ToggleButton
                value={DashboardType.OLD}
                aria-label="old dashboard"
                disabled={!isSameDay(new Date(), new Date(year, month - 1, day))}
              >
                <SvgIconDashboard style={{ width: 30 }} />
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              disabled={selectedDashboard === DashboardType.OLD}
              onClick={openConfig ? handleCloseConfig : handleOpenMenu}
              variant="outlined"
              style={{
                background: '#121930',
                height: '100%',
                border: '1px solid #242A42',
              }}
            >
              {openConfig ? <CloseRoundedIcon /> : <MoreHorizIcon />}
            </Button>
          </div>
        </div>

        <div className={styles['dashboard']}>
          {selectedDashboard === DashboardType.NEW && dashboard && (
            <FactoryComponent
              data={dashboard}
              autoScroll={false}
              openConfig={openConfig}
              showChallenges={true}
            />
          )}
          {selectedDashboard === DashboardType.OLD && oldDashboard && (
            <FactoryComponent data={oldDashboard} />
          )}
        </div>
      </div>
    </>
  );
}

export default ORWorkflowInteractiveDashboard;
