import { FactoryProps } from '@sqior/react/factory';
import styles from './address-selection-control.module.css';
import {
  ContainerWithShadow,
  InputControl,
  ProfilePicture,
  SvgIconPerson,
  useTextResources,
} from '@sqior/react/uibase';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { StreamOperation, isFinal } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import { AddressSearch, AddressSearchPath, AddressSearchResult } from '@sqior/viewmodels/user';
import { useDynamicState } from '@sqior/react/state';
import { SelectionAreaProps } from '@sqior/react/uiselection';

export type AddressSelectionControlProps = FactoryProps & SelectionAreaProps;

export function AddressSelectionControl(props: AddressSelectionControlProps) {
  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  const [search, setSearch] = useState('');
  /* View model containing search results */
  const addresses = useDynamicState<AddressSearchResult[]>(AddressSearchPath, []);
  /* Search operation that is supposed to be stopped if this gets destructed */
  const searchOp = useRef<StreamOperation | undefined>();
  useEffect(() => {
    return () => {
      searchOp.current?.close();
    };
  }, []);
  return (
    <div className={styles['container']}>
      <InputControl
        className={styles['input']}
        value={search}
        placeholder={textDict.get('input_search_term')}
        autoFocus={true}
        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
          const inputText = ev.currentTarget.value;
          setSearch(ev.currentTarget.value);
          /* Send search parameter to server */
          if (!searchOp.current || isFinal(searchOp.current.state))
            searchOp.current = dispatcher.start(AddressSearch(inputText)) as StreamOperation;
          else searchOp.current.send(inputText);
        }}
      />
      <ContainerWithShadow className={styles['result-container']}>
        <div className={styles['inner-result-container']}>
          {addresses.map((item) => {
            return (
              <div
                key={item.id}
                className={styles['result']}
                onClick={() => {
                  props.onSelection(item.address);
                }}
              >
                {!item.profilePicture && <SvgIconPerson className={styles['icon']} />}
                {item.profilePicture && (
                  <ProfilePicture className={styles['profile']} url={item.profilePicture} />
                )}
                <div className={styles['result-text-container']}>
                  <div className={styles['name']}>{item.name}</div>
                  <div className={styles['role']}>{item.role}</div>
                </div>
              </div>
            );
          })}
        </div>
      </ContainerWithShadow>
    </div>
  );
}

export default AddressSelectionControl;
