import { ChatDetailsVM } from '@sqior/viewmodels/communication';
import { useDynamicStateRaw } from '@sqior/react/state';
import { PageHeader } from '@sqior/react/uiadvanced';
import { ClosablePage } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { OperationContext } from '@sqior/react/operation';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { useContext, useState } from 'react';
import { ChatParticipantsHeader, TeamAddresses } from '../team/team';
import Chat from '../chat/chat';
import styles from './closable-chat.module.css';
import { FactoryProps, factoryGetProp } from '@sqior/react/factory';
import { EntityHeader } from '@sqior/js/entity';
import ChatPicture from '../chat-picture/chat-picture';

/* eslint-disable-next-line */
export type ClosableChatProps = EntityHeader & {};
export function ClosableChat(props: FactoryProps<ClosableChatProps>) {
  const chatDetails = useDynamicStateRaw<ChatDetailsVM>('current-chat/selected-details');
  const [displayTeam, setDisplayTeam] = useState(false);
  const dispatcher = useContext(OperationContext);

  function switchDisplayTeam() {
    setDisplayTeam(() => !displayTeam);
  }
  function selectRelated() {
    if (chatDetails?.selectEntities) {
      dispatcher.start(SelectPatient(chatDetails.selectEntities));
    }
  }

  if (chatDetails === undefined || chatDetails.chatVM === undefined) return null;

  return (
    <ClosablePage
      onClose={() => {
        props.onClose?.();
      }}
      className={classes(factoryGetProp<string>('className', props), styles['container'])}
    >
      <PageHeader
        onClick={selectRelated}
        icon={<ChatPicture type={chatDetails.chatVM.type} url={chatDetails.chatVM.typeUrl} />}
        title={
          <div className={styles['title']}>
            {chatDetails.chatVM.name}
            <div></div>
          </div>
        }
        subTitle={
          <>
            {typeof chatDetails.chatVM.subtitle === 'string' && (
              <div className={styles['sub-title']}>{chatDetails.chatVM.subtitle}</div>
            )}
            {chatDetails.chatVM.subtitle instanceof Array && (
              <div className={styles['sub-title-container']}>
                <div>{chatDetails.chatVM.subtitle[0]}</div>
                <div>{chatDetails.chatVM.subtitle[1]}</div>
              </div>
            )}
          </>
        }
      />
      <div className={styles['content']}>
        <div className={styles['participant-container']}>
          <ChatParticipantsHeader
            team={chatDetails?.participants}
            switchDisplayTeam={switchDisplayTeam}
            displayTeam={displayTeam}
          />
        </div>
        <Chat className={styles['chat']} />
        <TeamAddresses
          className={styles['address-list']}
          team={chatDetails?.participants}
          switchDisplayTeam={switchDisplayTeam}
          displayTeam={displayTeam}
        />
      </div>
    </ClosablePage>
  );
}

export default ClosableChat;
