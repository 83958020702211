import { useDynamicState } from '@sqior/react/state';
import { classes } from '@sqior/react/utils';

import { ReactComponent as UserInfoIcon } from './user-picture-generic.svg';

import styles from './user-info-server.module.css';
import { LogOutButton } from '@sqior/react/uiauth';
import { useTextResources } from '@sqior/react/uibase';

/* eslint-disable-next-line */
export interface UserInfoServerProps {
  onClick?: () => void;
  showLogout?: boolean;
  showUserIcon?: boolean;
  className?: string;
}

export function UserInfoServer(props: UserInfoServerProps) {
  const user = useDynamicState<string>('user-info/user', '');
  const roles = useDynamicState<string[]>('user-info/roles', []);
  const employeeId = useDynamicState<string[]>('user-info/employee-id', []);
  const textResources = useTextResources();

  return (
    <div className={classes(styles['user-info'], props.className)}>
      {props.showUserIcon && (
        <UserInfoIcon
          fill="#ffffff"
          viewBox="0 0 40 40"
          className={styles['user-image']}
          onClick={props.onClick}
        />
      )}
      {user && (
        <div className={styles['user-info-details']} onClick={props.onClick}>
          <div className={styles['user-name']}>{user}</div>
          <div className={styles['user-employee-id']}>{employeeId}</div>
          <div className={styles['user-roles']}>
            {roles.length > 5 ? textResources.get('super_user') : roles.join(', ')}
          </div>
        </div>
      )}
      {props.showLogout && <LogOutButton />}
    </div>
  );
}

export default UserInfoServer;
