import { Emitter } from '@sqior/js/event';
import { Logger } from '@sqior/js/log';
import { AddOperation, isFinal, OperationState } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import { FacingMode, QRScannerControl } from '@sqior/react/qrcode';
import { AuthContext } from '@sqior/react/uiauth';
import { useContext, useRef } from 'react';

/* eslint-disable-next-line */
export interface QRVideoControlProps {
  onClose: () => void;
  takePhoto?: Emitter;
  onPhoto?: (canv: HTMLCanvasElement) => void;
  facing?: FacingMode;
  onFacing?: (facing: FacingMode) => void;
}

export function QRVideoControl(props: QRVideoControlProps) {
  const dispatcher = useContext(OperationContext);
  const codeStarted = useRef(false);
  const authContext = useContext(AuthContext);

  return (
    <QRScannerControl
      detected={(code) => {
        if (codeStarted.current) return;
        codeStarted.current = true;
        /* Start an operation to react on the QR code */
        const addOp = new AddOperation(code);
        addOp.stateChange.on((opState) => {
          if (isFinal(opState)) codeStarted.current = false;
          if (opState === OperationState.Completed) {
            if (addOp.finalId && addOp.finalId.length > 0) {
              Logger.info(`QR code returned user, relogin with ${addOp.finalId}`);
              props.onClose();
              authContext.provider.tryLogIn(addOp.finalId);
            } else {
              props.onClose();
            }
          }
        });
        dispatcher.handle(addOp, 'qr-code');
      }}
      takePhoto={props.takePhoto}
      onPhoto={props.onPhoto}
      facing={props.facing}
      onFacing={props.onFacing}
    />
  );
}

export default QRVideoControl;
