import { ComponentFactory } from '@sqior/react/factory';
import { useDynamicState } from '@sqior/react/state';
import { ContainerWithShadow } from '@sqior/react/uibase';
import { ListViewItem } from '@sqior/viewmodels/visual';
import { useContext } from 'react';
import styles from './dashboard-list.module.css';

/* eslint-disable-next-line */
export interface DashboardListProps {
  path: string;
  className?: string;
}

export function DashboardList(props: DashboardListProps) {
  const list = useDynamicState<ListViewItem[]>(props.path, []);
  const FactoryComponent = useContext(ComponentFactory);
  return (
    <ContainerWithShadow className={props.className}>
      <div className={styles['container']}>
        {list.map((item) => (
          <FactoryComponent data={item} key={item.id} />
        ))}
      </div>
    </ContainerWithShadow>
  );
}

export default DashboardList;
