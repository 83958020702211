import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { DashboardStatePath } from '@sqior/viewmodels/visual';
import { EmergencyClassificationVM } from './orworkflow-overview';
import { CaveSeverity } from '@sqior/viewmodels/patient';
import { DateData } from '@sqior/viewmodels/time';
import { joinPath } from '@sqior/js/url';
import { DailyStartConfig, TransferPriorityGroupVM } from '@sqior/viewmodels/location';

export const ORWorkflowDashboardType = 'ORWorkflowDashboard';
export const ORWorkflowOverviewDashboardType = 'ORWorkflowOverviewDashboard';
export const ORWorkflowOverviewORItemType = 'ORWorkflowOverviewORItem';
export const ORWorkflowOverviewProcedureType = 'ORWorkflowOverviewProcedure';
export const ORWorkflowOverviewSutureIncisionDurationType =
  'ORWorkflowOverviewSutureIncisionDuration';

/* Data describing the overview dashboard */
export type ORWorkflowOverviewDashboardMarker = { label: string; timestamp: ClockTimestamp };
export type ORWorkflowOverviewDashboardORItemData = EntityHeader & {
  start: ClockTimestamp;
  end: ClockTimestamp;
  markers: ORWorkflowOverviewDashboardMarker[];
  component: Entity;
  opaque: boolean;
};
export type ORWorkflowOverviewDashboardORData = EntityHeader & {
  name: string;
  items: ORWorkflowOverviewDashboardORItemData[];
  specialty?: string; // Specialty controlling this OR (e.g. Orthopedics)
  specialtyColor?: string; // Color code associated with specialty (see ColorCodes enum)
  dailyStartConfig?: DailyStartConfig;
  location: Entity;
};
export type ORWorkflowOverviewDashboardClusterData = EntityHeader & {
  title: string;
  ors: ORWorkflowOverviewDashboardORData[];
};
export type ORWorkflowOverviewDashboardData = EntityHeader & {
  clusters: ORWorkflowOverviewDashboardClusterData[]; // Clusters to display
  date: DateData; // Date of the data displayed
  interval: { startHour: number; endHour: number }; // Hours of the OR schedule that shall be displayed (e.g. 0 to 30 means 00:00 to 06:00 of the next day)
  core: { startHour: number; endHour: number }; // Core hours of the OR schedule
  target?: { hours: number; minutes: number }; // Target time to optimize the schedule for
};
export enum ORWorkflowOverviewDashboardState {
  Completed = 'completed',
  Running = 'running',
  Planned = 'planned',
}
export enum ORWorkflowOverviewDashboardStage {
  Normal = 'normal',
  Anesthesia = 'anesthesia',
  Surgery = 'surgery',
}
export type ORWorkflowOverviewDashboardProcedureData = EntityHeader & {
  name: string; // Patient name
  sex: string; // Patient sex symbol
  dob: string; // Patient date of birth
  description: string; // Procedure description
  state: ORWorkflowOverviewDashboardState; // Procedure state (planned, running, completed)
  duration?: number; // Procedure duration in min
  step: string; // Description of current step
  stage: ORWorkflowOverviewDashboardStage; // Stage of procedure
  countdown?: ClockTimestamp; // Countdown timer to be display, if applicable
  countdownDuration?: number; // Duration of the countdown timer in min
  team?: string[]; // Names of the surgeons
  caveSeverity?: CaveSeverity;
  specialty?: string; // Specialty of the procedure (e.g. Orthopedics)
  specialtyColor?: string; // Color code associated with specialty (see ColorCodes enum)
  emergencyClassification?: EmergencyClassificationVM;
  prioGroup?: TransferPriorityGroupVM;
  border?: {
    color: string;
    opacity: number;
  };
  select: OperationSpec<ValueObject>;
  shortcutCommand?: {
    text: string;
    select: OperationSpec;
  };
  preSurgicalWard?: string;
  postSurgicalWard?: string;
  postSurgicalCareLevel?: string;
  challenged?: boolean; // Flag if there are challenges with this procedure, so that some kind of warning indicator shall be displayed
};
export type ORWorkflowOverviewSutureIncisionDurationData = EntityHeader & {
  duration: number; // Suture to incision of the next procedure duration in min
};

/** Interactive dashboard */

export const ORWorkflowInteractiveDashboardType = 'ORWorkflowInteractiveDashboard';
export type ORWorkflowInteractiveDashboardData = EntityHeader & {
  date: DateData;
  prevDate: boolean;
  nextDate: boolean;
  reportURL?: string;
  dashboard?: Entity;
  oldDashboard?: Entity;
};

export const NextDaySuffix = 'next',
  PrevDaySuffix = 'prev';
export function NextDay(): OperationSpec<string> {
  return { type: OperationType.Add, path: joinPath(DashboardStatePath, NextDaySuffix), data: '' };
}
export function PrevDay(): OperationSpec<string> {
  return { type: OperationType.Add, path: joinPath(DashboardStatePath, PrevDaySuffix), data: '' };
}
