import { OperationContext } from '@sqior/react/operation';
import { useDynamicState } from '@sqior/react/state';
import {
  ButtonX,
  InterweaveExt,
  SelectControl,
  SelectModel,
  useTextResources,
} from '@sqior/react/uibase';
import { LocationData } from '@sqior/viewmodels/location';
import { AddLocation, RemoveLocation } from '@sqior/viewmodels/user';
import { useContext } from 'react';
import styles from './active-location-selection.module.css';

/* eslint-disable-next-line */
export interface ActiveLocationSelectionProps {
  description: string;
}

export function ActiveLocationSelection(props: ActiveLocationSelectionProps) {
  const textDict = useTextResources();

  const activeLocations = useDynamicState<LocationData>('active-location', []);

  const availableLocations = useDynamicState<LocationData>('available-locations', []);
  const filteredAvailableLocations = availableLocations.filter((item) => {
    return (
      activeLocations.findIndex((act) => {
        return act.id === item.id;
      }) < 0
    );
  });

  const rooms: SelectModel = {
    items: filteredAvailableLocations.map((item) => {
      return item.text;
    }),
    selected: -1,
    defaultSelectionText: textDict.get('qr_room_add_room'),
  };

  const dispatcher = useContext(OperationContext);
  function roomManuallySelected(index: number) {
    const roomId = filteredAvailableLocations[index];
    if (roomId?.id) dispatcher.start(AddLocation(roomId.id));
  }

  return (
    <div className={styles['container']} style={{ flex: 1 }}>
      <div className={styles['content']}>
        <div className={styles['title']}>{textDict.get('qr_room_title')}</div>
        <InterweaveExt
          className={styles['description']}
          content={textDict.get(props.description)}
          disableMatchers
        />
      </div>

      <div style={{ flex: 1 }} />
      <LocationList locations={activeLocations.filter((item) => item.id)} />
      {rooms.items.length > 0 && (
        <SelectControl
          className={styles['room-select']}
          data={rooms}
          onSelect={roomManuallySelected}
        />
      )}
    </div>
  );
}

export interface LocationListProps {
  locations: LocationData;
}

export function LocationList(props: LocationListProps) {
  const dispatcher = useContext(OperationContext);
  const deleteLocation = (id: string | undefined) => {
    if (id) dispatcher.start(RemoveLocation(id));
  };

  return (
    <div className={styles['location-list']}>
      {props.locations.map((item, index) => {
        return (
          <div
            key={item.text}
            className={styles['location-list-item']}
            onClick={() => deleteLocation(item.id)}
          >
            {item.text}
            <ButtonX onClick={() => deleteLocation(item.id)} />
          </div>
        );
      })}
    </div>
  );
}

export default ActiveLocationSelection;
