import { ValueObject } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { OperationSpec } from '@sqior/js/operation';
import { PatientInfo } from '@sqior/viewmodels/patient';
import { ListViewItem } from '@sqior/viewmodels/visual';
import { DischargeInfo } from './discharge-info-vm';

export const BedAllocationWardListItemType = 'BedAllocationWardListItem';
export const WardSpecialtiesStatsInfoType = 'WardSpecialtiesStatsInfo';
export const WardSPIStatsInfoType = 'WardSPIStatsInfo';
export const BedAllocationDashboardType = 'BedAllocationDashboard';
export const BedAllocationInFlightPatientInfoType = 'BedAllocationInFlightPatientInfo';

export enum BedAllocationWardListItemBedState {
  Free = 'free',
  Blocked = 'blocked',
  Occupied = 'occupied',
}

export type BedAllocationWardListItemBedInfo = {
  state: BedAllocationWardListItemBedState;
  sex?: string;
  inset?: string;
  blockStrength?: string;
  patient?: PatientInfo;
  dischargeInfo?: DischargeInfo;
};

export type BedAllocationWardListItemBed = {
  id: string;
  current: BedAllocationWardListItemBedInfo;
  later: BedAllocationWardListItemBedInfo;
  select?: OperationSpec<ValueObject>;
};

export type BedAllocationWardListItemEntity = ListViewItem & {
  label: string;
  beds: BedAllocationWardListItemBed[];
};

export type WardSpecialtiesStatsInfoItem = {
  specialty: string;
  patients: number;
  quota: number;
  arriving: number;
  leaving: number;
};
export type WardSpecialtiesStatsInfoEntity = ListViewItem & {
  specialties: WardSpecialtiesStatsInfoItem[];
};

export type WardSPIStatsInfoData = {
  spiValueLimit: number;
  spiMaxCountLimit: number;
  spiCountLimit: number;
};
export type WardSPIStatsInfoListViewEntity = ListViewItem & WardSPIStatsInfoData;

export type BedAllocationInFlightPatientInfoEntity = ListViewItem & {
  label: string /* HTML */;
  patient: Entity;
  sex: string;
  requestSource: string;
  select: Entity;
  stepInfo?: string;
};
