import { SelectControl, SelectModel, useTextResources } from '@sqior/react/uibase';
import { ListViewInfoVector } from '@sqior/viewmodels/visual';
import DashboardList from '../dashboard-list/dashboard-list';
import styles from './dashboard-switch.module.css';

/* eslint-disable-next-line */
export interface DashboardSwitchProps {
  data: ListViewInfoVector;
  stage: string;
  setStage: (stage: string) => void;
}

export function DashboardSwitch(props: DashboardSwitchProps) {
  const selectModel: SelectModel = { items: [], selected: 0 };
  for (let i = 0; i < props.data.length; i++) {
    selectModel.items.push(
      props.data[i].showCount
        ? `${props.data[i].title} (${props.data[i].entries})`
        : props.data[i].title
    );
    if (props.data[i].title === props.stage) selectModel.selected = i;
  }
  const textResources = useTextResources();
  return (
    <div className={styles['container']}>
      {props.data.length > 0 && (
        <SelectControl
          data={selectModel}
          onSelect={(index) => {
            props.setStage(props.data[index].title);
          }}
        />
      )}
      {selectModel.items.length > 0 && props.data[selectModel.selected].entries > 0 && (
        <DashboardList
          key={props.data[selectModel.selected].path}
          className={styles['list']}
          path={props.data[selectModel.selected].path}
        />
      )}
      {(selectModel.items.length === 0 || props.data[selectModel.selected].entries === 0) && (
        <div className={styles['empty']}>{textResources.get('no_entries')}</div>
      )}
    </div>
  );
}

export default DashboardSwitch;
