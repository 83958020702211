import { classes } from '@sqior/react/utils';
import ButtonClose from '../button-close/button-close';
import PopupPage from '../popup-page/popup-page';
import styles from './closable-page.module.css';
import { useOnCancel } from '../use-on-cancel';

/* eslint-disable-next-line */
export interface ClosablePageProps {
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  className?: string;
}

export function ClosablePage(props: ClosablePageProps) {
  useOnCancel(props.onClose);

  return (
    <PopupPage className={classes(styles['container'], props.className)}>
      <div className={styles['header']}>
        <ButtonClose additionalClassName={styles['btn-close']} onClick={props.onClose} />
        {props.children instanceof Array && (
          <div className={styles['header-content']}>{props.children[0]}</div>
        )}
      </div>
      {props.children instanceof Array ? props.children.slice(1) : props.children}
    </PopupPage>
  );
}

export default ClosablePage;
