import {
  ChecklistItemViewModel,
  ChecklistOptionSentiment,
  ChecklistParagraphViewModel,
} from '@sqior/viewmodels/checklist';
import ChecklistItemControl from '../checklist-item-control/checklist-item-control';
import styles from './checklist-paragraph-control.module.css';
import { useState } from 'react';
import { Button, SvgIconExpandLess, SvgIconExpandMore } from '@sqior/react/uibase';

export type ChecklistParagraphControlProps = {
  data: ChecklistParagraphViewModel;
  select: (item: number, option: number, check: boolean) => void;
  readOnly: boolean;
};

export function ChecklistParagraphControl(props: ChecklistParagraphControlProps) {
  /* Check if there are uncompleted items, in this no folding is allowed */
  const complete = !props.data.items.find((item) => {
    return !item.options.find((opt) => {
      return opt.checked;
    });
  });
  /* Paragraph is folded by default if it is completed */
  const [fold, setFold] = useState(complete);
  return (
    <div className={styles['container']}>
      <div className={styles['header-container']}>
        <div className={styles['header']}>{props.data.header}</div>
        {complete && (
          <Button
            className={styles['fold-button']}
            onClick={() => {
              setFold(!fold);
            }}
          >
            {fold ? <SvgIconExpandMore /> : <SvgIconExpandLess />}
          </Button>
        )}
      </div>
      {(!complete || !fold) &&
        props.data.items.map((item, index) => (
          <ChecklistItemControl
            data={item}
            key={index}
            select={(option, check) => {
              if (!props.readOnly) {
                if (fold) setFold(false);
                props.select(index, option, check);
              }
            }}
          />
        ))}
      {fold &&
        complete &&
        props.data.items
          .map<[ChecklistItemViewModel, number]>((item, index) => {
            return [item, index];
          })
          .filter((item) => {
            return item[0].options.find((opt) => {
              return opt.checked && opt.sentiment === ChecklistOptionSentiment.Negative;
            });
          })
          .map((item) => (
            <ChecklistItemControl
              data={item[0]}
              key={item[1]}
              select={(option, check) => {
                if (!props.readOnly) {
                  if (fold) setFold(false);
                  props.select(item[1], option, check);
                }
              }}
            />
          ))}
    </div>
  );
}

export default ChecklistParagraphControl;
