import { ValueObject } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';

export const ChatListPath = 'chatlist';
export const PersonalChatType = 'PersonalChat';

export const ManageCurrentChatPath = 'current-chat-manage';

export function SelectChatView(chatId: Entity): OperationSpec<Entity> {
  return { type: OperationType.Add, path: ManageCurrentChatPath, data: chatId };
}
export function CloseChatView(chatId?: Entity): OperationSpec<string> {
  chatId;
  return { type: OperationType.Delete, path: ManageCurrentChatPath, data: '' };
}

/** Sending a chat message */
export function SendChat(message: string): OperationSpec<string> {
  return { type: OperationType.Add, path: 'chatmessage', data: message };
}

/** Create a chat manually */
export type CreateChatData = {
  related?: ValueObject;
  addresses: ValueObject[];
  addMySelf: boolean;
};
export function CreateChat(
  related: Entity,
  addresses: ValueObject[],
  addMySelf = true
): OperationSpec<CreateChatData> {
  return {
    type: OperationType.Add,
    path: 'chat',
    data: { related: related, addresses: addresses, addMySelf: addMySelf },
  };
}

export type CreateInquiryData = {
  related?: ValueObject; // The related object the chat is related to
  addresses?: ValueObject[]; // The addresses that shall be included into the chat
  includeAddresses: boolean; // If true, the chat must contain the specified addresses as members, if false, only the related object is considered when looking for existing chats
  addMySelf: boolean; // If true, the logged on user is added to the list of addresses
};

/** Create a chat based on an related entity, chat particpants are derived from related entity */
export function CreateAndDisplayInquiry(relatedId: ValueObject): OperationSpec<CreateInquiryData> {
  const data: CreateInquiryData = {
    related: relatedId,
    addresses: [],
    addMySelf: false,
    includeAddresses: false,
  };
  return { type: OperationType.Add, path: 'chat-inquiry', data: data };
}
/** Create a chat based on participants (addresses) and related entity, optionally ensure oneself is added  */
export function CreateAndDisplayChat(
  relatedId: ValueObject,
  addresses: ValueObject[],
  addMySelf: boolean
): OperationSpec<CreateInquiryData> {
  const data: CreateInquiryData = {
    related: relatedId,
    addresses: addresses,
    addMySelf: addMySelf,
    includeAddresses: true,
  };
  return { type: OperationType.Add, path: 'chat-inquiry', data: data };
}

/** Add additional address to chat */
export type ChatAddressData = { chat: ValueObject; addresses: ValueObject[] };
export function AddAddresses(
  chat: ValueObject,
  addresses: ValueObject[]
): OperationSpec<ChatAddressData> {
  return {
    type: OperationType.Add,
    path: 'chat/address',
    data: { chat: chat, addresses: addresses },
  };
}
// export function DeleteAddresses(chat: string, addresses: string[]): OperationSpec<ChatAddressData> {
//   return { type: OperationType.Delete, path: 'chat/address', data: {chat: chat, addresses: addresses}}
// }
