import {
  AddICECandidate,
  ConferencePartnerVM,
  ICEServer,
  SetWebRTCAnswer,
  SetWebRTCOffer,
} from '@sqior/js/conference';
import { SequenceNumber } from '@sqior/js/db';
import { OperationContext } from '@sqior/react/operation';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './partner-video.module.css';

/* eslint-disable-next-line */
export interface PartnerVideoProps {
  conference: SequenceNumber;
  iceServers?: ICEServer[];
  desc: ConferencePartnerVM;
}

async function createOffer(pc: RTCPeerConnection, stopped: boolean): Promise<string> {
  const offer = await pc.createOffer();
  if (stopped) return '';
  await pc.setLocalDescription(offer);
  return pc.localDescription ? pc.localDescription.sdp : '';
}

async function createAnswer(
  pc: RTCPeerConnection,
  offer: string,
  stopped: boolean
): Promise<string> {
  await pc.setRemoteDescription({ type: 'offer', sdp: offer });
  if (stopped) return '';
  const answer = await pc.createAnswer();
  if (stopped) return '';
  await pc.setLocalDescription(answer);
  return pc.localDescription ? pc.localDescription.sdp : '';
}

export function PartnerVideo(props: PartnerVideoProps) {
  const dispatcher = useContext(OperationContext);

  const videoControl = useRef<HTMLVideoElement>(null);

  /* Identify the audio/video stream */
  const [mediaStream, setMediaStream] = useState<MediaStream | undefined>(undefined);
  useEffect(() => {
    let avStream: MediaStream | undefined;
    let stopped = false;
    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } }).then((stream) => {
      /* Check if this is already stopped, in this case the code reader should not be started at all */
      if (stopped) return;
      setMediaStream((avStream = stream));
    });
    return () => {
      /* Check if this is already started */
      if (avStream) for (const track of avStream.getTracks()) track.stop();
      /* Indicate that this is stopped */
      stopped = true;
    };
  }, [setMediaStream]);

  /* Initiate the peer connection */
  const [peerConn, setPeerConn] = useState<RTCPeerConnection | undefined>(undefined);
  const conference = props.conference;
  const role = props.desc.role;
  const partner = props.desc.partner;
  const offer = role === 'answer' ? props.desc.sdp : '';
  const iceServers = props.iceServers;
  useEffect(() => {
    if (!mediaStream) return;
    let stopped = false;
    let peerConn: RTCPeerConnection | undefined;
    /* Initiate peer connection */
    const pc = (peerConn = new RTCPeerConnection(
      iceServers ? { iceServers: iceServers } : undefined
    ));
    mediaStream.getTracks().forEach((track) => pc.addTrack(track, mediaStream));
    /*
    pc.onconnectionstatechange = (ev) => {
      console.log("ConnectionState:", ev);
    };
    pc.onsignalingstatechange = (ev) => {
      console.log("SignalingState:", ev);
    };
    */
    pc.ontrack = (trackEv: RTCTrackEvent) => {
      if (videoControl.current) videoControl.current.srcObject = trackEv.streams[0];
    };
    pc.onicecandidate = (ev) => {
      if (ev.candidate)
        dispatcher.start(AddICECandidate(conference, partner, JSON.stringify(ev.candidate)));
    };
    if (role === 'offer')
      createOffer(pc, stopped).then((sdp) => {
        if (sdp.length > 0) dispatcher.start(SetWebRTCOffer(conference, partner, sdp));
      });
    else if (offer.length > 0)
      createAnswer(pc, offer, stopped).then((sdp) => {
        if (sdp.length > 0) dispatcher.start(SetWebRTCAnswer(conference, partner, sdp));
      });
    setPeerConn(pc);

    return () => {
      /* Check if this is already started */
      if (peerConn) peerConn.close();
      /* Indicate that this is stopped */
      stopped = true;
    };
  }, [dispatcher, mediaStream, role, conference, partner, offer, setPeerConn, iceServers]);

  /* Provision of ICE candidates */
  const cands = props.desc.iceCandidates;
  useEffect(() => {
    if (!peerConn) return;
    for (const candJSON of cands) {
      const cand = JSON.parse(candJSON);
      peerConn.addIceCandidate(cand);
    }
  }, [cands, peerConn]);

  /* Setting of answer */
  const answer = role === 'offer' ? props.desc.sdp : '';
  useEffect(() => {
    if (!answer.length || !peerConn) return;
    peerConn.setRemoteDescription({ type: 'answer', sdp: answer });
  }, [answer, peerConn]);

  return (
    <div className={styles['container']}>
      <video ref={videoControl} className={styles['video']} autoPlay muted playsInline />
      {props.desc.name && <span className={styles['name']}>{props.desc.name}</span>}
    </div>
  );
}

export default PartnerVideo;
