import { classes, getPrettyTimeDiffParts, isOlder, useDynamicTime } from '@sqior/react/utils';
import { ItemState } from '@sqior/viewmodels/visual';
import PieCircle from '../pie-circle/pie-circle';
import styles from './countdown-timer.module.css';

/* eslint-disable-next-line */
export interface CountdownTimerProps {
  timeout: number; // absolute timestamp in ms since epoch
  duration: number;
  state?: ItemState;
  className?: string;
}

type TimerInput = [number, string] | undefined;
type TimerOutput = [string, string];

const formatTimerOutput = (time: TimerInput): TimerOutput => {
  if (!time) return ['', '---'];

  const [unit, value] = time;

  if (unit === Number.POSITIVE_INFINITY) return ['', '---'];

  return [unit.toString(), value];
};

export function CountdownTimer(props: CountdownTimerProps) {
  const dynamicTimeout = useDynamicTime(props.timeout);
  const now = Date.now();
  const expired = isOlder(dynamicTimeout[0], now);

  const timeVis = getPrettyTimeDiffParts(props.timeout);
  const [textLarge, textSmall] = formatTimerOutput(timeVis);

  const percentage = ((props.timeout - now) / props.duration) * 100;

  return (
    <div
      className={classes(
        styles['countdown'],
        props.className,
        expired ? styles['countdown-expired'] : ''
      )}
    >
      <PieCircle
        className={styles['countdown-pie']}
        percentage={expired ? 0 : percentage}
        state={props.state}
        textLarge={textLarge}
        textSmall={textSmall}
      />
    </div>
  );
}

export default CountdownTimer;
