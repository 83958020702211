import { Bytes, LogicalTimestamp, Value, ValueObject } from '@sqior/js/data';
import { IdOperation } from './id-operation';
import { OperationState, OperationStateJSON, OperationType } from './operation';

export type ReadResult = [Value, string, LogicalTimestamp];

export class ReadOperation extends IdOperation {
  constructor(id: string, value?: Value) {
    super(OperationType.Read, id);
    this.requestData = value;
    this.mimeType = '';
  }

  async result(): Promise<[Value, string]> {
    await this.completion();
    if (this.data !== undefined) return [this.data, this.mimeType];
    else if (this.error !== undefined && this.error !== null) throw this.error;
    else throw Error('Result data is not set');
  }

  completeRead(data: Value, type: string, timestamp: LogicalTimestamp) {
    this.data = data;
    this.mimeType = type;
    this.complete(timestamp);
  }

  override resultToJSON(): OperationStateJSON {
    const res = super.resultToJSON();
    if (this.data) res['data'] = this.data;
    if (this.mimeType) res['type'] = this.mimeType;
    return res;
  }
  override completeFromJSON(msg: OperationStateJSON) {
    if (msg.state == OperationState.Completed) {
      this.data = msg['data'] as Value;
      this.mimeType = msg['type'] as string;
    }
    super.completeFromJSON(msg);
  }
  override toJSON() {
    const obj: ValueObject = { id: this.id };
    if (this.requestData) obj['data'] = this.requestData;
    return JSON.stringify(obj);
  }
  static fromJSON(json: Value) {
    if (typeof json === 'string' && json.length) {
      if (json[0] === '{') {
        const obj = JSON.parse(json);
        if (typeof obj === 'object' && !(obj instanceof Bytes) && !(obj instanceof Array)) {
          const id = obj['id'];
          if (id && typeof id === 'string') return new ReadOperation(id, obj['data']);
        }
      } else return new ReadOperation(json);
    }
    throw new Error('Provided value cannot be deserialized to ReadOperation');
  }

  requestData?: Value;
  data?: Value;
  mimeType: string;
}
