import { useDynamicState } from '@sqior/react/state';
import ChatList from '../chat-list/chat-list';
import styles from './chat-page.module.css';
import { ChatListPath, EnrichedChatVM } from '@sqior/viewmodels/communication';

/* eslint-disable-next-line */
export interface ChatPageProps {}

export function ChatPage(props: ChatPageProps) {
  const chatList = useDynamicState<EnrichedChatVM[]>(ChatListPath, []);
  return (
    <div className={styles['container']}>
      <ChatList chats={chatList} />
    </div>
  );
}

export default ChatPage;
