import { AuthProvider } from '@sqior/js/authbase';
import { now } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';
import { TextResourceAccess } from '@sqior/js/language';
import { ConnectionState, HasConnectionState } from '@sqior/js/wsbase';
import React, { useContext, useEffect, useState } from 'react';

export enum ConnectionError {
  None,
  Connection,
  Authentication,
}

/* Timeouts */
const StdErrorTimeout = 2000;
const WakeUpErrorTimeout = 4000;

/** Server connection observer */
export class ServerConnectionObserver {
  constructor(conn?: HasConnectionState, autoAuth?: AuthProvider) {
    this.conn = conn;
    this.autoAuth = autoAuth;
    this.lastState = ConnectionState.NOT_CONNECTED;
    this.errorChanged = new Emitter<[ConnectionError]>();
    /* Observe state of connection */
    if (conn) {
      this.updateState(conn.state);
      conn.stateChanged.on((state) => {
        this.updateState(state);
      });
    } else this.updateState(ConnectionState.ERROR);
  }

  /** Called after the app comes to the foreground, resets the connection error for some time to
   *  give time for a reconnect without a connection error popping up
   */
  reinitialize() {
    if (!this.conn) return;
    /* Reset a pot. running timer */
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    /* Reset error */
    if (this.lastError) this.errorChanged.emit(ConnectionError.None);
    this.lastError = ConnectionError.None;
    this.lastState = ConnectionState.NOT_CONNECTED;
    /* Try to connect again */
    this.conn.tryConnect(false);
    /* Update with current state to restart timer, if applicable */
    this.wakeUpTimestamp = now();
    this.updateState(this.conn.state);
  }

  private updateState(state: ConnectionState) {
    /* Some states are rather transient - they do not influence the displayed state */
    if (state === ConnectionState.NOT_CONNECTED || state === ConnectionState.CONNECTED_HANDSHAKE)
      return;

    /* Determine the error */
    let error = ConnectionError.None;
    if (state === ConnectionState.ERROR) error = ConnectionError.Connection;
    else if (
      state === ConnectionState.ERROR_AUTH_FAILED ||
      state === ConnectionState.ERROR_AUTH_RESET
    ) {
      /* Perform a reload of the app to allow for a check of SSO, as otherwise there is a chance to get
         a "you are already logged in" error in the log-in page */
      setTimeout(() => {
        if (state === ConnectionState.ERROR_AUTH_FAILED) window.location.reload();
        else this.autoAuth?.logOut();
      }, this.autoAuth?.authFailedReloadPeriod());
      error = ConnectionError.Authentication;
    } else if (state === ConnectionState.ERROR_AUTH_EXPIRED) {
      /* Try to re-establish the connection as the authentication has expired but may work after token refresh */
      error = ConnectionError.Authentication;
    }
    /* Check if the error changed */
    if (error === this.lastError) return;

    /* Check if this transitioned from no error to a connection error, in this case a timeout is started to show the error with a slight delay */
    if (
      this.lastError === ConnectionError.None &&
      !this.timer &&
      error === ConnectionError.Connection
    )
      this.timer = setTimeout(
        () => {
          this.errorChanged.emit((this.error = this.lastError));
          this.timer = undefined;
        },
        now() - this.wakeUpTimestamp < WakeUpErrorTimeout ? WakeUpErrorTimeout : StdErrorTimeout
      );
    else if (!this.timer)
      /* Emit directly */
      this.errorChanged.emit((this.error = error));
    /* Reset error timer if it is running and the error went away */
    if (error === ConnectionError.None && this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.lastError = error;
    this.lastState = state;
  }

  reconnect() {
    if (this.lastState === ConnectionState.ERROR_AUTH_FAILED) this.autoAuth?.tryLogIn();
    else if (this.lastState === ConnectionState.ERROR_AUTH_RESET) this.autoAuth?.logOut();
    else if (this.conn) this.conn.tryConnect(true);
  }

  errorString(textDict: TextResourceAccess) {
    let resource: string | undefined;
    if (this.error === ConnectionError.Connection) resource = 'cannot_establish_connection';
    else if (this.error === ConnectionError.Authentication) resource = 'authentication_error';
    return resource ? textDict.get(resource) : undefined;
  }

  private conn?: HasConnectionState;
  private autoAuth?: AuthProvider;
  private lastError = ConnectionError.None;
  private lastState: ConnectionState;
  private wakeUpTimestamp = now();
  private timer?: ReturnType<typeof setTimeout>;

  error = ConnectionError.None;
  readonly errorChanged: Emitter<[ConnectionError]>;
}

/* Helper for using the context and providing a react state variable */

export function useConnectionError() {
  /* Get context */
  const connContext = useContext(ServerConnectionContext);
  /* Get state variable */
  const [connError, setConnError] = useState<boolean>(connContext.error !== ConnectionError.None);
  /* Use an effect to observer the error state */
  useEffect(() => {
    /* Observe the error */
    return connContext.errorChanged.on((error) => {
      setConnError(error !== ConnectionError.None);
    });
  }, [connContext]);
  return connError;
}

/** Server connecttion context */
export const ServerConnectionContext = React.createContext(new ServerConnectionObserver());
