import { Slogan } from '@sqior/viewmodels/communication';
import { useDynamicState } from '@sqior/react/state';
import { useDynamicTime } from '@sqior/react/utils';
import { Node } from 'interweave';
import { InterweaveExt } from '@sqior/react/uibase';
import { DateTime } from 'luxon';
import styles from './main-info-item-empty.module.css';

/* eslint-disable-next-line */
export interface MainInfoItemEmptyProps {}

export function MainInfoItemEmpty(props: MainInfoItemEmptyProps) {
  const currentSlogan = useDynamicState<Slogan>('slogan', ['', '']);
  return (
    <div className={styles['container']}>
      <div className={styles['line1']}>
        <InterweaveExt content={currentSlogan[0]} transform={sloganTransform} />
      </div>
      <div className={styles['line2']}>
        <InterweaveExt content={currentSlogan[1]} transform={sloganTransform} />
      </div>
    </div>
  );
}

export default MainInfoItemEmpty;

function sloganTransform(node: HTMLElement, children: Node[]): React.ReactNode {
  if (node.tagName.toLowerCase() === 'currenttime') {
    return <CurrentTime />;
  }
  return undefined; // Skip eveyrthing else
}

function CurrentTime() {
  useDynamicTime(0); // Retriggers rendering
  return <span>{DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}</span>;
}
