import React from 'react';

/** Different layouts of the application */
export enum AppStyle {
  Messenger,
  Combined,
  Dashboard,
  ParallelPhoneDemo,
}

/** Selects the desired layout of the application */
export function decideAppStyle(kiosk: boolean, currStyle?: AppStyle) {
  if (kiosk) return AppStyle.Dashboard;
  if (window.innerWidth > 600)
    if (currStyle === undefined || currStyle === AppStyle.Messenger) return AppStyle.Combined;
    else return currStyle;
  return AppStyle.Messenger;
}

/** Context for forwarding state and modifier of the app style to innner parts of the application */
export const AppStyleContext = React.createContext<[AppStyle, (style: AppStyle) => void]>([
  AppStyle.Messenger,
  () => {
    /* default */
  },
]);
