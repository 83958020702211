import {
  isBefore,
  NoTimestamp,
  LogicalTimestamp,
  Value,
  ValueOrNothing,
  CloneMethod,
  memoryConsumption,
} from '@sqior/js/data';
import { Emitter, Listener, StopListening } from '@sqior/js/event';
import { StateOverlay } from './state-overlay';

export type ValueListener<ValueType = Value> = Listener<[ValueType | undefined, LogicalTimestamp]>;
type ValueChangeEmitter = Emitter<[Value | undefined, LogicalTimestamp]>;
export type SubStateValues = { path: string; value: Value; timestamp: LogicalTimestamp }[];
export type SubStateValueListener = Listener<[string, Value, LogicalTimestamp]>;

export class State {
  constructor(cloneMethod = CloneMethod.Deep) {
    this.timestamp = NoTimestamp;
    this.cloneMethod = cloneMethod;
    this.overlays = [];
    this.subStates = {};
    this.listeners = {};
    this.anyChange = new Emitter<[string, ValueOrNothing, LogicalTimestamp]>();
  }

  /* Returns the current state */
  getRaw<Type extends Value>(): Type | undefined {
    return this.value !== undefined ? (this.value as Type) : undefined;
  }
  get<Type extends Value>(defValue: Type) {
    if (this.value === undefined) return defValue;
    return this.value as Type;
  }
  /* Returns the current state */
  getSubRaw<Type extends Value>(path: string) {
    return this.subState(path).getRaw<Type>();
  }
  getSub<Type extends Value>(path: string, defValue: Type) {
    return this.subState(path).get(defValue);
  }
  /* Returns the complete state */
  getAll() {
    /* Init with this */
    const ssv: SubStateValues = [];
    if (this.value !== undefined)
      ssv.push({ path: '/', value: this.value, timestamp: this.timestamp });

    /* Loop all sub-states */
    for (const sub in this.subStates)
      for (const subValue of this.subStates[sub].state.getAll())
        ssv.push({
          path: this.combinePath(sub, subValue.path),
          value: subValue.value,
          timestamp: subValue.timestamp,
        });

    return ssv;
  }

  /* Gets the timestamp */
  getTimestamp() {
    return this.timestamp;
  }
  /* Gets the timestamp */
  getSubTimestamp(path: string) {
    return this.subState(path).getTimestamp();
  }
  /* Sets the timestamp */
  private setTimestamp(timestamp: LogicalTimestamp) {
    /* Remember timestamp */
    this.timestamp = timestamp;

    /* Clean out overlays that have no become outdated */
    for (let i = this.overlays.length - 1; i >= 0; i--)
      if (!isBefore(timestamp, this.overlays[i].timestamp)) {
        this.overlays[i].destroy();
        this.overlays.splice(i, 1);
      }
  }

  /* Sets the current state */
  set(value: ValueOrNothing, timestamp = NoTimestamp) {
    /* Check if the value or timestamp changed */
    if (value === this.baseValue && timestamp === this.timestamp) return;
    /* Remember value */
    this.baseValue = value;
    /* Set timestamp */
    this.setTimestamp(timestamp);

    /* Update computed value */
    this.computeValue();
  }
  /* Sets the current state */
  setSub(path: string, value: ValueOrNothing, timestamp = NoTimestamp) {
    return this.subState(path).set(value, timestamp);
  }

  /* Changes the current state */
  changeRaw<Type extends Value>(
    func: (value: Type | undefined) => Type | undefined,
    timestamp = NoTimestamp
  ) {
    this.set(func(this.baseValue ? (this.baseValue as Type) : undefined), timestamp);
  }
  /* Changes the current state */
  changeSubRaw<Type extends Value>(
    path: string,
    func: (value: Type | undefined) => Type | undefined,
    timestamp = NoTimestamp
  ) {
    this.subState(path).changeRaw(func, timestamp);
  }
  /* Changes the current state */
  change<Type extends Value>(func: (value: Type) => Type, defValue: Type, timestamp = NoTimestamp) {
    this.set(func(this.baseValue ? (this.baseValue as Type) : defValue), timestamp);
  }
  /* Changes the current state */
  changeSub<Type extends Value>(
    path: string,
    func: (value: Type) => Type,
    defValue: Type,
    timestamp = NoTimestamp
  ) {
    this.subState(path).change(func, defValue, timestamp);
  }

  /* Maps a state as a sub state of this */
  /* Note: setting a state that is an ancestor of this will lead to a
     loop of infinity */
  map(path: string, state: State) {
    /* Split path, the provided mapping path must reference a sub path of this */
    const splitPath = this.splitPath(path);
    if (splitPath.length === 0)
      throw (
        'State can only be mapped to a sub path of the called state! Provided path argument was: ' +
        path
      );

    /* Traverse for all intermediate path elements */
    const sub = splitPath.pop() as string;
    this.subStateFromPath(splitPath).mapAt(sub, state);
  }
  /* Maps a state as a sub state of this */
  private mapAt(sub: string, state: State) {
    /* Check if this is a change at all */
    const prev = this.subStates[sub];
    if (prev && prev.state === state) return;

    /* Set this as new sub state */
    this.setState(sub, state);

    /* Some aspects need to be considered if there was a state mapped to this path before */
    const notified = new Set<string>();
    if (prev) {
      /* Stop listening to the sub state */
      prev.stopListening();

      /* Get all paths of the previos state to notify the any listeners of this about the loss */
      for (const subState of prev.state.getAll()) {
        this.notifyListeners(
          this.combinePath(sub, subState.path),
          state.getSubRaw(subState.path),
          state.subState(subState.path).timestamp
        );
        notified.add(subState.path);
      }
    }

    /* Get all states of the mapped state to notify the any listeners of this */
    for (const subState of state.getAll())
      if (!notified.has(subState.path))
        this.notifyListeners(
          this.combinePath(sub, subState.path),
          subState.value,
          subState.timestamp
        );
  }

  /* Returns all sub-states of this */
  getSubStates(): Map<string, State> {
    const subStates = new Map<string, State>();
    for (const sub in this.subStates) subStates.set(sub, this.subStates[sub].state);
    return subStates;
  }

  /* Registers a listener for changes */
  on(list: ValueListener) {
    return this.onSub('/', list);
  }
  /* Registers a listener for changes */
  onTyped<Type extends Value>(list: ValueListener<Type>) {
    return this.onSubTyped('/', list);
  }
  /* Registers a listener for changes */
  onSub(path: string, list: ValueListener) {
    const p = this.normalizePath(path);
    if (!this.listeners[p]) this.listeners[p] = new Emitter<[ValueOrNothing, LogicalTimestamp]>();
    return this.listeners[p].on(list);
  }
  /* Registers a listener for changes */
  onSubTyped<Type extends Value>(path: string, list: ValueListener<Type>) {
    return this.onSub(path, (value: ValueOrNothing, timestamp: LogicalTimestamp) => {
      list(value as Type, timestamp);
    });
  }

  /*
    Overlay related functions
  */

  addOverlay(ov: StateOverlay) {
    /* Check if the overlay is already outdated */
    if (!isBefore(this.timestamp, ov.timestamp)) {
      ov.destroy();
      return;
    }

    /* Register */
    this.overlays.push(ov);

    /* Register listener for changes to the transformation */
    ov.transformationChange.on(() => {
      /* Update computed value */
      this.computeValue();
    });

    /* Register listener for changes to the timestamp */
    ov.timestampChange.on((timestamp) => {
      /* Check if the timestamp is still beyond the current state */
      if (isBefore(this.timestamp, timestamp)) return;

      /* Eliminate overlay */
      this.overlays = this.overlays.filter((o) => {
        return o !== ov;
      });

      /* Destroy overlay */
      ov.destroy();

      /* Update computed value */
      this.computeValue();
    });

    /* Update computed value */
    this.computeValue();
  }

  /*
    Internal helper functions
  */
  private computeValue() {
    /* Transform the value */
    let value = this.baseValue;
    for (const ov of this.overlays) value = ov.trans(value, this.timestamp);

    /* Take over change */
    this.value = value;

    /* Inform listeners */
    this.notifyListeners('/', value, this.timestamp);
  }

  /* Splits a path into elements */
  private splitPath(path: string) {
    return path.split('/').filter((s: string) => {
      return s.length > 0;
    });
  }
  /* Normalizes a path */
  private normalizePath(path: string) {
    const elements = this.splitPath(path);
    if (elements.length === 0) return '/';

    let norm = '';
    for (const element of elements) norm += '/' + element;
    return norm;
  }
  /* Combines a sub state name prefix with a specified path */
  private combinePath(sub: string, path: string) {
    return '/' + sub + (path != '/' ? path : '');
  }

  /* Returns a sub state representing the specified path */
  subState(path: string) {
    /* Split the path and avoid empty elements */
    return this.subStateFromPath(this.splitPath(path));
  }
  /* Returns a sub state representing the specified path */
  private subStateFromPath(path: string[]): State {
    /* Check if this is this */
    if (path.length === 0) return this;

    /* Ensure sub state exists */
    const first = path.splice(0, 1)[0];
    if (!this.subStates[first]) this.setState(first);
    return this.subStates[first].state.subStateFromPath(path);
  }
  private setState(sub: string, state: State = new State()) {
    /* Attach a listener to the sub state to satisfy our any listeners */
    const stopListening = state.anyChange.on(
      (path: string, state: ValueOrNothing, timestamp: LogicalTimestamp) => {
        this.notifyListeners(this.combinePath(sub, path), state, timestamp);
      }
    );

    /* Register sub state */
    this.subStates[sub] = { state: state, stopListening: stopListening };
  }
  private notifyListeners(path: string, state: ValueOrNothing, timestamp: LogicalTimestamp) {
    /* Inform the listeners that observe all paths */
    this.anyChange.emit(path, state, timestamp);

    /* Inform the listeners that observe this specific */
    const listeners = this.listeners[path];
    if (listeners) listeners.emit(state, timestamp);
  }

  /** Calculates the amount of memory used by this */
  get memory(): number {
    let bytes = memoryConsumption(this.value);
    if (this.baseValue !== this.value && this.baseValue) bytes += memoryConsumption(this.baseValue);
    for (const key in this.subStates) bytes += this.subStates[key].state.memory;
    return bytes;
  }

  private baseValue: ValueOrNothing; /* Value being set explicitly */
  private value: ValueOrNothing; /* Derived value considering all overlays */
  private timestamp: LogicalTimestamp;
  private cloneMethod: CloneMethod;

  private overlays: StateOverlay[];

  private subStates: Record<string, { state: State; stopListening: StopListening }>;

  private listeners: Record<string, ValueChangeEmitter>;
  anyChange: Emitter<[string, ValueOrNothing, LogicalTimestamp]>;
}
