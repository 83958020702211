import styles from './popup-page.module.css';

/* eslint-disable-next-line */
export interface PopupPageProps {
  children: React.ReactNode;
  className?: string;
}

export function PopupPage(props: PopupPageProps) {
  return <div className={`${styles['container']} ${props.className}`}>{props.children}</div>;
}

export default PopupPage;
