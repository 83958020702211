export function newlineToBr(str: string) {
  return str.split('\n').map(function (item, idx, arr) {
    return (
      <>
        {item}
        {idx < arr.length && <br />}
      </>
    );
  });
}
