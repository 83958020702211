import { Entity, EntityHeader } from '@sqior/js/entity';

/* State path of list views */

export const ListViewsStatePath = 'list-views'; // State containing all concrete lists as sub-states
export const AllListViewsIndexStatePath = 'all-lists'; // State specifying all lists to display in the list switch view

/* Header of a list view */

export const ListViewInfosType = 'ListViewInfos';

export type ListViewInfo = Entity & {
  path: string;
  title: string;
  entries: number;
  showCount: boolean;
};
export type ListViewInfoVector = ListViewInfo[];
export type ListViewInfos = Entity & { lists: ListViewInfoVector };

/* Item of a list view */

export enum ItemState {
  Normal = 'normal',
  Highlighted = 'highlight',
  Hot = 'hot',
  Challenged = 'challenge',
  Greyed = 'greyed',
  Emergency = 'emergency',
}
export type ListViewItem = EntityHeader & { id: string };
