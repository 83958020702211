import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import styles from './orworkflow-overview-procedure.module.css';
import {
  EmergencyClassificationVM,
  ORWorkflowOverviewDashboardProcedureData,
  ORWorkflowOverviewDashboardStage,
  ORWorkflowOverviewDashboardState,
} from '@sqior/viewmodels/orworkflow';
import { Cave, CountdownTimer, InterweaveExt, SvgIconGroup } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { addMinutes } from '@sqior/js/data';
import { ItemState } from '@sqior/viewmodels/visual';
import { motion } from 'framer-motion';
import AutoScroller, { IAutoScrollerSection } from '../auto-scroller/auto-scroller';
import SpecialtyView from '../specialty-view/specialty-view';
import { useContext, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { OperationSpec } from '@sqior/js/operation';
import { Button } from '@mui/material';

export type ORWorkflowOverviewProcedureProps =
  FactoryProps<ORWorkflowOverviewDashboardProcedureData>;

type Border =
  | {
      color: string | undefined;
      opacity: number | undefined;
    }
  | undefined;

const stepStyle = (stage: ORWorkflowOverviewDashboardStage) => {
  switch (stage) {
    case ORWorkflowOverviewDashboardStage.Anesthesia:
      return styles['step-anesthesia'];
    case ORWorkflowOverviewDashboardStage.Surgery:
      return styles['step-surgery'];
    case ORWorkflowOverviewDashboardStage.Normal:
      return styles['step-normal'];
  }
  return undefined;
};

const getStageClass = (stage: ORWorkflowOverviewDashboardStage): string | undefined => {
  switch (stage) {
    case ORWorkflowOverviewDashboardStage.Anesthesia:
      return classes(styles['stage-anesthesia'], styles['stage-highlighted']);
    case ORWorkflowOverviewDashboardStage.Surgery:
      return classes(styles['stage-surgery'], styles['stage-highlighted']);
    case ORWorkflowOverviewDashboardStage.Normal:
      return classes(styles['stage-normal'], styles['stage-highlighted']);
  }
  return undefined;
};

const getContainerClassName = (state: ORWorkflowOverviewDashboardState): string => {
  if (state === ORWorkflowOverviewDashboardState.Completed)
    return classes(styles['container'], styles['completed']);
  return classes(styles['container']);
};

const getEmergencyTextStyles = (emergencyClassification: EmergencyClassificationVM | undefined) => {
  if (!emergencyClassification || !emergencyClassification.opacity) return undefined;
  return {
    color: getEmergencyColorWithOpacity(emergencyClassification.opacity),
  };
};

const getContainerStyle = (state: ORWorkflowOverviewDashboardState, border: Border) => {
  if (!border || !border.opacity) return { zIndex: 20 };
  return {
    border: `2px solid ${getEmergencyColorWithOpacity(border.opacity)}`,
    zIndex: 20,
  };
};

const getCountDownState = (
  stage: ORWorkflowOverviewDashboardStage,
  emergencyClassification: EmergencyClassificationVM | undefined
) => {
  if (emergencyClassification?.emergencyCountdown) return ItemState.Emergency;

  switch (stage) {
    case ORWorkflowOverviewDashboardStage.Anesthesia:
      return ItemState.Hot;
    case ORWorkflowOverviewDashboardStage.Surgery:
      return ItemState.Highlighted;
    case ORWorkflowOverviewDashboardStage.Normal:
      return ItemState.Highlighted;
  }
  return ItemState.Highlighted;
};

const getEmergencyColorWithOpacity = (opacity: undefined | number = 1): string => {
  return `rgba(${EMERGENCY_BASE_COLOR}, ${opacity})`;
};

const EMERGENCY_BASE_COLOR = '245, 93, 93';

export function ORWorkflowOverviewProcedure(props: ORWorkflowOverviewProcedureProps) {
  const {
    data: {
      name,
      sex,
      dob,
      countdown,
      duration = 0,
      stage,
      step,
      description,
      state,
      team,
      caveSeverity,
      specialty,
      specialtyColor,
      countdownDuration,
      emergencyClassification,
      border,
      select,
      shortcutCommand,
      preSurgicalWard,
      postSurgicalWard,
    },
  } = props;

  const autoScroll = factoryGetProp<boolean>('autoScroll', props);
  const showChallenges = factoryGetProp<boolean | undefined>('showChallenges', props);
  const motionLayout = factoryGetProp<boolean>('motionLayout', props);
  const setTabAnimation = factoryGetProp<(tabAnimation: boolean) => void>('setTabAnimation', props);
  const dispatcher = useContext(OperationContext);

  const sync = factoryGetProp<Date | undefined>('sync', props);

  const [sections, setSections] = useState<IAutoScrollerSection[]>([]);

  const handleSectionRef = (ref: HTMLDivElement | null, index: number) => {
    if (!ref) return;

    const foundSectionIndex = sections.findIndex((section) => section.index === index);

    if (foundSectionIndex === -1) {
      setSections([
        ...sections,
        {
          index,
          ref,
        },
      ]);
      return;
    }
    const existingSectionHeight = sections[foundSectionIndex].ref.clientHeight;
    const currentSectionHeight = ref.clientHeight;

    if (existingSectionHeight === currentSectionHeight) return;

    const newSections = [...sections];
    newSections[foundSectionIndex] = {
      index,
      ref,
    };
    setSections(newSections);
  };

  const handleNavigate = (select: OperationSpec) => {
    dispatcher.start(select);
  };

  const showSurgicalWarn = () => {
    const separator = <>&nbsp;&nbsp;&nbsp;|&nbsp;</>;
    const arrow = <>&rarr;</>;
    if (preSurgicalWard && postSurgicalWard) {
      return (
        <>
          {preSurgicalWard} {arrow} {postSurgicalWard} {separator}
        </>
      );
    }
    if (preSurgicalWard && !postSurgicalWard) {
      return (
        <>
          {preSurgicalWard}
          {separator}
        </>
      );
    }
    if (!preSurgicalWard && postSurgicalWard) {
      return (
        <>
          {postSurgicalWard}
          {separator}
        </>
      );
    }
    return null;
  };
  const handleButtonPressStart = () => {
    setTabAnimation && setTabAnimation(false);
  };
  const handleButtonPressEnd = () => {
    setTabAnimation && setTabAnimation(true);
  };

  return (
    <motion.div
      onClick={() => handleNavigate(select)}
      layout={motionLayout}
      className={getContainerClassName(state)}
      style={getContainerStyle(state, border)}
    >
      <AutoScroller
        sections={sections}
        sync={sync}
        autoScroll={autoScroll}
        motionLayout={motionLayout}
      >
        <div className={styles['content-container']}>
          <div
            className={styles['auto-scroll-name-container']}
            ref={(ref) => handleSectionRef(ref, 0)}
          >
            <div className={styles['top-container']}>
              <div className={styles['top-left-container']}>
                <div className={styles['name-container']}>
                  <div className={styles['group-container']}>
                    {emergencyClassification?.category && (
                      <span style={getEmergencyTextStyles(emergencyClassification)}>
                        {emergencyClassification?.category.toUpperCase()}
                      </span>
                    )}
                    {props.data.prioGroup && (
                      <span
                        className={
                          styles[
                            props.data.prioGroup.state === ItemState.Challenged
                              ? 'transfer-group-warning'
                              : 'transfer-group-normal'
                          ]
                        }
                      >
                        {props.data.prioGroup.label}
                      </span>
                    )}
                  </div>
                  <InterweaveExt
                    content={name}
                    className={classes(
                      styles['brake-word'],
                      showChallenges &&
                        props.data.challenged &&
                        props.data.state !== ORWorkflowOverviewDashboardState.Completed
                        ? styles['challenged']
                        : undefined
                    )}
                  />
                  {caveSeverity ? <Cave severity={caveSeverity}></Cave> : ''}
                </div>

                <div className={styles['name-sub-container']}>
                  {(preSurgicalWard || postSurgicalWard) && (
                    <span className={styles['sex']}>{showSurgicalWarn()}</span>
                  )}
                  <InterweaveExt content={`${sex} *${dob}`} className={styles['sex']} />
                  <SpecialtyView
                    specialtyName={specialty}
                    specialtyColor={specialtyColor}
                    style={{ lineHeight: '140%' }}
                  />
                </div>
              </div>
              <div className={styles['top-right-container']}>
                {countdown && (
                  <CountdownTimer
                    className={styles['countdown-timer']}
                    timeout={countdown}
                    duration={countdownDuration ? countdownDuration : addMinutes(60)}
                    state={getCountDownState(stage, emergencyClassification)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles['auto-scroll-py']}>
            <div
              className={classes(
                styles['description'],
                duration >= (countdown ? 105 : 95)
                  ? styles['description-long']
                  : styles['description-short']
              )}
            >
              {shortcutCommand && !autoScroll ? (
                <div style={{ margin: '10px 0' }}>
                  <Button
                    onMouseDown={handleButtonPressStart}
                    onMouseUp={handleButtonPressEnd}
                    onTouchStart={handleButtonPressStart}
                    onTouchEnd={handleButtonPressEnd}
                    fullWidth
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      dispatcher.start(shortcutCommand.select);
                    }}
                    variant="contained"
                    style={{
                      borderRadius: 30,
                      height: 30,
                    }}
                  >
                    <div className={styles['shortcut-command-text']}>{shortcutCommand.text}</div>
                  </Button>
                </div>
              ) : (
                <div className={styles['spacer']} />
              )}

              {description}
            </div>
          </div>

          {step && (
            <div className={styles['auto-scroll-py']}>
              <div className={classes(styles['step-container'], getStageClass(stage))}>
                <div className={classes(styles['step'], stepStyle(stage))}>{step}</div>
              </div>
            </div>
          )}

          {team && (
            <div className={styles['auto-scroll-py-pb']}>
              <div
                className={classes(
                  styles['team-container'],
                  duration >= (countdown ? 95 : 85)
                    ? styles['description-long']
                    : styles['description-short']
                )}
              >
                <div>
                  <SvgIconGroup className={styles['team-icon']} />
                </div>
                <span className={styles['team']}>{team.join(', ')}</span>
              </div>
            </div>
          )}
        </div>
      </AutoScroller>
    </motion.div>
  );
}

export default ORWorkflowOverviewProcedure;
