import { memo, ReactNode, useEffect, useRef, useState } from 'react';
import { AnimationDefinition, motion, useAnimation } from 'framer-motion';
import { MotionLayout } from '../orworkflow-overview-dashboard/orworkflow-overview-dashboard';
import { useDynamicStateRaw } from '@sqior/react/state';
import { ViewportSize } from '@sqior/web/utils';

export interface AutoScrollerProps {
  children: ReactNode;
  sections?: IAutoScrollerSection[];
  sync?: Date;
  autoScroll?: boolean;
  motionLayout?: MotionLayout;
}

export interface IAutoScrollerSection {
  index: number;
  ref: HTMLDivElement;
}

const SPEED = 10;
const TOLERANCE = 10;

export function AutoScroller({
  children,
  sections = [],
  sync,
  autoScroll = true,
  motionLayout,
}: AutoScrollerProps) {
  const viewport = useDynamicStateRaw<ViewportSize>('app/viewport');
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  const [overflowY, setOverflowY] = useState(0);

  useEffect(() => {
    const createAnimationConfig = (scrollDistance: number): AnimationDefinition => ({
      y: [0, -scrollDistance],
      transition: {
        duration: scrollDistance / SPEED,
        ease: 'linear',
        repeat: Infinity,
        flip: true,
        repeatDelay: 2,
        repeatType: 'reverse',
      },
    });
    const createSlotAnimationConfig = (
      scrollDistance: number,
      sections: IAutoScrollerSection[]
    ): AnimationDefinition => {
      const firstSection = sections.find((section) => section.index === 0);
      if (!firstSection) return createAnimationConfig(scrollDistance);

      return {
        y: [0, -(firstSection.ref.clientHeight + 4)],
        transition: {
          duration: 1,
          ease: 'linear',
          repeat: Infinity,
          flip: true,
          repeatDelay: 2,
          repeatType: 'reverse',
        },
      };
    };
    const checkOverflow = () => {
      const container = containerRef.current;
      const content = contentRef.current;

      if (!container || !content) return;

      const isOverflowingY = container.clientHeight + TOLERANCE < content.scrollHeight;
      const diffOverflowY = content.scrollHeight - container.clientHeight;

      if (isOverflowingY) {
        if (overflowY !== diffOverflowY) setOverflowY(diffOverflowY);
      } else {
        controls.start({
          y: 0,
          transition: { duration: 1 },
        });
      }
    };
    const startAnimation = async () => {
      if (overflowY === 0) return;
      if (sections?.length > 0)
        await controls.start(createSlotAnimationConfig(overflowY, sections));
      else await controls.start(createAnimationConfig(overflowY));
    };
    if (!autoScroll) return;
    checkOverflow();
    startAnimation();
  }, [controls, sync, overflowY, sync, autoScroll, sections, viewport]);

  return (
    <div ref={containerRef}>
      <motion.div layout={motionLayout} animate={controls} ref={contentRef}>
        {children}
      </motion.div>
    </div>
  );
}

export default memo(AutoScroller);
