/* This class takes an async callback and returns the values as an async iterator */
export class AsyncIterator<Type> {
  constructor(it: () => Promise<Type | null>) {
    this.it = it;
  }

  [Symbol.asyncIterator]() {
    return {
      it: this.it,
      async next(): Promise<{ value: Type | undefined; done: boolean }> {
        const v = await this.it();
        if (v === null) return { value: undefined, done: true };
        else return { value: v, done: false };
      },
    };
  }

  readonly it: () => Promise<Type | null>;
}
