import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { OperationSpec } from '@sqior/js/operation';
import { ItemState, ListViewItem } from '@sqior/viewmodels/visual';
import { EmergencyClassificationVM } from './orworkflow-overview';
import { TransferPriorityGroupVM } from '@sqior/viewmodels/location';

/** Base OR Workflow list item */
export type ORWorkflowListItemBase = ListViewItem & {
  patient: string;
  step?: string;
  description?: string;
  location?: string;
  order: string;
  select: OperationSpec<ValueObject>;
  countdown?: ClockTimestamp | 'Infinity';
  countdownStart?: ClockTimestamp;
  countdownState?: ItemState;
  emergencyClassification?: EmergencyClassificationVM;
  prioGroup?: TransferPriorityGroupVM;
};

/** OR workflow stage list item */
export const ORWorkflowStageItemType = 'ORWorkflowStageItem';
export type ORWorkflowStageItem = ORWorkflowListItemBase;

/** OR workflow OR list item */
export const ORWorkflowORListItemType = 'ORWorkflowORListItem';
export type ORWorkflowORListItem = ORWorkflowListItemBase;

/** OR workflow OR cluster list item */
export const ORWorkflowORClusterListItemType = 'ORWorkflowClusterListItem';
export type ORWorkflowORClusterListItem = ORWorkflowListItemBase;

/** OR workflow personal list item */
export const ORWorkflowMyListItemType = 'ORWorkflowMyListItem';
export type ORWorkflowMyListItem = ORWorkflowListItemBase;
