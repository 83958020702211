import { InformProjectionVM, ReadConfirmationTypes } from '@sqior/viewmodels/communication';
import { useDynamicState } from '@sqior/react/state';
import { FilteredSetStateStatusLoadMore } from '@sqior/react/uiadvanced';
import { HighlightButton } from '@sqior/react/uibase';
import { getPrettyDateString } from '@sqior/react/utils';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ThreadItemSeparator from '../thread-item-separator/thread-item-separator';
import ThreadItem from '../thread-item/thread-item';
import styles from './thread.module.css';
import { isEqual, Value } from '@sqior/js/data';
import { useAutoMarkRead } from '../hooks/conversation-hooks';
import { EntityHeader } from '@sqior/js/entity';

export type ThreadPageProps = EntityHeader & { data: ThreadProps };
export type ThreadProps = {
  stateBasePath: string;
  autoMarkReadAs?: ReadConfirmationTypes;
};

function lastItemsDiffer(a: InformProjectionVM[], b: InformProjectionVM[]) {
  for (let i = 0; i < Math.min(a.length, b.length); i++)
    if (a[a.length - 1 - i].id !== b[b.length - 1 - i].id) return true;
  return false;
}

export const Thread = forwardRef((props: ThreadProps, ref) => {
  const [lastConversation, setLastConversation] = useState<InformProjectionVM[]>([]);

  const conversation = useDynamicState<InformProjectionVM[]>(`${props.stateBasePath}/items`, []);
  useAutoMarkRead(conversation, props.autoMarkReadAs);

  const lastThreadItem = useRef<HTMLDivElement>(null);

  function scrollToLastElement(smooth: boolean) {
    setTimeout(() => {
      lastThreadItem.current?.scrollIntoView({
        block: 'end',
        behavior: smooth ? 'smooth' : 'auto',
      });
    }, 10);
  }

  // Provide scrollToLastElement method to the user of this component
  useImperativeHandle(ref, () => ({
    scrollToLastElement: () => {
      scrollToLastElement(true);
    },
  }));

  // Scroll automatically to last element if a new item arrived
  if (
    conversation.length > 0 &&
    (!lastConversation.length || lastItemsDiffer(conversation, lastConversation))
  ) {
    const smooth = lastConversation.length > 0;
    setLastConversation(conversation);
    scrollToLastElement(smooth);
  }

  const conversationItems = [];
  let lastDate = getPrettyDateString(Date.now());
  let lastSender: Value | undefined = undefined;
  for (const item of conversation) {
    const curDate = getPrettyDateString(item.timestamp) || '';
    if (curDate !== lastDate) {
      conversationItems.push(<ThreadItemSeparator key={lastDate}>{curDate}</ThreadItemSeparator>);
      lastDate = curDate;
    }
    const displaySender = !isEqual(lastSender, item.sender);
    lastSender = item.sender;
    const ci = (
      <ThreadItem
        className={displaySender ? undefined : styles['thread-item-same-sender']}
        content={item}
        key={item.sequenceId}
        considerSentByMySelf={true}
        displaySenderOrRecipient={displaySender}
        displayResponseOptionsAnswers={true}
        displayFollowUpResponses={false}
      />
    );
    conversationItems.push(ci);
  }

  return (
    <div className={styles['thread']}>
      <div className={styles['spacer']} />
      {
        <FilteredSetStateStatusLoadMore
          className={styles['load-more']}
          stateBasePath={props.stateBasePath}
        >
          <HighlightButton onClick={() => {}}>Ältere Nachrichten anzeigen</HighlightButton>
        </FilteredSetStateStatusLoadMore>
      }
      {conversationItems}
      {/* This element is used in order to detect whether last element is visible or to scroll to the last element; it needs to have (tiny visible) content, otherwise iPhone will not work correctly */}
      <div ref={lastThreadItem} className={styles['last-element']}>
        &nbsp;
      </div>
    </div>
  );
});

export default Thread;
