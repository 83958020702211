import { AddressInfoVM } from '@sqior/viewmodels/communication';
import { Entity } from '@sqior/js/entity';
import { WorkflowOverview } from '@sqior/viewmodels/workflow';
import { ValueObject } from '@sqior/js/data';

/* Parameterless entity representing the parameters of a patient discharge request to be put in */

export const PatientDischargeRequestParametersInputType = 'PatientDischargeRequestParametersInput';
export type PatientDischargeRequestParametersInput = Entity;

/* Bed allocation workflow overview */

export const PatientDischargeOverviewType = 'PatientDischargeOverview';

export type PatientDischargeOverviewEntity = WorkflowOverview & {
  team?: AddressInfoVM[];
  relatedId?: ValueObject;
};
