import { classes } from '@sqior/react/utils';
import { MouseEvent } from 'react';
import styles from './button.module.css';

/* eslint-disable-next-line */
export interface ButtonProps {
  children: React.ReactNode;
  additionalClassName?: string;
  className?: string;
  onClick: (e: MouseEvent) => void;
}

export function Button(props: ButtonProps) {
  return (
    <button
      onClick={props.onClick}
      className={classes(props.className, props.additionalClassName, styles['button'])}
    >
      {props.children}
    </button>
  );
}

export default Button;
