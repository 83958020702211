import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import {
  BedAllocationDashboardType,
  BedAllocationInFlightPatientInfoType,
  BedAllocationListItemType,
  BedAllocationOverviewType,
  BedAllocationRequestParametersInfoType,
  BedAllocationRequestParametersInputType,
  BedAllocationTargetWardInputType,
  BedAllocationWardListItemType,
  PatientDischargeOverviewType,
  WardSpecialtiesStatsInfoType,
  WardSPIStatsInfoType,
} from '@sqior/viewmodels/occupancy';
import BedAllocationDashboard from './bed-allocation-dashboard/bed-allocation-dashboard';
import BedAllocationInFlightPatientInfo from './bed-allocation-in-flight-patient-info/bed-allocation-in-flight-patient-info';
import BedAllocationListItem from './bed-allocation-list-item/bed-allocation-list-item';
import BedAllocationOverview from './bed-allocation-overview/bed-allocation-overview';
import BedAllocationParameterInfo from './bed-allocation-parameter-info/bed-allocation-parameter-info';
import BedAllocationParameterInputArea from './bed-allocation-parameter-input-area/bed-allocation-parameter-input-area';
import BedAllocationTargetWardInputArea from './bed-allocation-target-ward-input-area/bed-allocation-target-ward-input-area';
import BedAllocationWardListItem from './bed-allocation-ward-list-item/bed-allocation-ward-list-item';
import WardSpecialtiesStats from './ward-specialties-stats/ward-specialties-stats';
import WardSpiStats from './ward-spi-stats/ward-spi-stats';

export function UIOccupancyPlugin(context: PluginContext & FactoryContext) {
  context.plugins.require(UIVisualPlugin, context);
  context.factory.add(BedAllocationRequestParametersInputType, BedAllocationParameterInputArea);
  context.factory.add(BedAllocationTargetWardInputType, BedAllocationTargetWardInputArea);
  context.factory.add(BedAllocationOverviewType, BedAllocationOverview);
  context.factory.add(BedAllocationListItemType, BedAllocationListItem);
  context.factory.add(BedAllocationWardListItemType, BedAllocationWardListItem);
  context.factory.add(WardSpecialtiesStatsInfoType, WardSpecialtiesStats);
  context.factory.add(WardSPIStatsInfoType, WardSpiStats);
  context.factory.add(BedAllocationDashboardType, BedAllocationDashboard);
  context.factory.add(BedAllocationInFlightPatientInfoType, BedAllocationInFlightPatientInfo);
  context.factory.add(BedAllocationRequestParametersInfoType, BedAllocationParameterInfo);

  context.factory.add(PatientDischargeOverviewType, BedAllocationOverview);
}
