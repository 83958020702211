import { Checkbox } from '@sqior/react/uibase';
import styles from './nursing-complexity-control.module.css';

/* eslint-disable-next-line */
export interface NursingComplexityControlProps {
  score: number;
  onChanged?: (score: number) => void;
}

export function NursingComplexityControl(props: NursingComplexityControlProps) {
  const scores = [1, 2, 3, 4];
  return (
    <div className={styles['container']}>
      {scores.map((item) => (
        <Checkbox
          key={item.toString()}
          checked={item === props.score}
          onChanged={(checked) => {
            if (props.onChanged) props.onChanged(checked ? item : 0);
          }}
          label={item.toString()}
        />
      ))}
    </div>
  );
}

export default NursingComplexityControl;
