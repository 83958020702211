/** Enum specifying abstract colors */

export enum ColorCodes {
  Green = 'green',
  Yellow = 'yellow',
  Orange = 'orange',
  Purple = 'purple',
  Violet = 'violet',
  Blue = 'blue',
  Cyan = 'cyan',
  White = 'white',
}
