import { useDynamicState, useDynamicStateRaw } from '@sqior/react/state';
import { ClosablePage, ImageInfo, Logo, LogoType, useTextResources } from '@sqior/react/uibase';
import { VersionInfoContext } from '@sqior/react/utils';
import { SupportInfo } from '@sqior/viewmodels/user';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import LicenseInfo from '../license-info/license-info';
import styles from './about-info.module.css';
import { SqiorNativeAppVersion } from '@sqior/react/uiapp';
import { FactoryProps } from '@sqior/react/factory';
import LogView from '../log-view/log-view';

/* eslint-disable-next-line */

export function AboutInfo(props: FactoryProps) {
  const textDict = useTextResources();
  const versionServer = useDynamicState<string>('system/version/server', '');
  const versionNativeClient = SqiorNativeAppVersion;
  const supportInfo = useDynamicStateRaw<SupportInfo>('system/support/info');
  const versionInfoClientContext = useContext(VersionInfoContext);
  const versionWebClient = versionInfoClientContext?.version;
  const serverTime = useDynamicState<number>('system/server-time', 0);
  const deployment = window.location.href.split('?')[0]; //+ "extralang/eslabel/";

  function onCall(phone: string | undefined) {
    if (phone) document.location.href = `tel:${phone}`;
  }
  function onEmail(email: string | undefined) {
    if (email) document.location.href = `mailto:${email}`;
  }

  const [showLicense, setShowLicense] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  return (
    <div className={styles['container']}>
      {!showLicense && !showLogs && (
        <ClosablePage onClose={() => props.onClose?.(true, undefined)}>
          <div className={styles['about-container']}>
            <Logo type={LogoType.RectWhite} className={styles['logo']} />

            <div className={styles['info-section']}>
              <div className={styles['logo-info']}>
                <ImageInfo />
              </div>
              <div className={styles['info-section-text']}>
                <div className={styles['version']}>
                  {supportInfo?.email && (
                    <div
                      className={styles['support-text']}
                      onClick={() => {
                        onEmail(supportInfo.email);
                      }}
                    >
                      {supportInfo.email}
                    </div>
                  )}
                  {supportInfo?.phone && (
                    <div
                      className={styles['support-text']}
                      onClick={() => {
                        onCall(supportInfo.phone);
                      }}
                    >
                      {supportInfo.phone}
                    </div>
                  )}
                  <div className={styles['top-spacer']} />
                </div>
                <div>
                  <div className={styles['support-infos']}>{textDict.get('support_infos')}</div>
                  <table className={styles['support-info-table']}>
                    <tr>
                      <td colSpan={2}>
                        <div className={styles['version-info-heading']}>
                          {textDict.get('support_infos_versions')}
                        </div>
                      </td>
                    </tr>
                    {versionServer && (
                      <tr className={styles['version-info-line']}>
                        <td className={styles['version-info-name']}>
                          {textDict.get('support_infos_server_version')}
                        </td>
                        <td className={styles['version-info-value']}>{versionServer}</td>
                      </tr>
                    )}
                    {versionWebClient && (
                      <tr className={styles['version-info-line']}>
                        <td className={styles['version-info-name']}>
                          {textDict.get('support_infos_wep_version')}
                        </td>
                        <td className={styles['version-info-value']}>{versionWebClient}</td>
                      </tr>
                    )}
                    {versionNativeClient && (
                      <tr className={styles['version-info-line']}>
                        <td className={styles['version-info-name']}>
                          {textDict.get('support_infos_native_version')}
                        </td>
                        <td className={styles['version-info-value']}>{versionNativeClient}</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={2}>
                        <div className={styles['version-info-heading']}>
                          {textDict.get('support_infos_deployment')}
                        </div>
                      </td>
                    </tr>
                    {deployment && (
                      <tr className={styles['version-info-line']}>
                        <td className={styles['version-info-name']}>
                          {textDict.get('support_infos_deployment_url')}
                        </td>
                        <td className={styles['version-info-value']}>{deployment}</td>
                      </tr>
                    )}
                    {versionServer && (
                      <tr className={styles['version-info-line']}>
                        <td className={styles['version-info-name']}>
                          {textDict.get('support_infos_server_time')}
                        </td>
                        <td className={styles['version-info-value']}>
                          {DateTime.fromMillis(serverTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
                <div className={styles['license']}>
                  <div
                    className={styles['license-info-heading']}
                    onClick={() => setShowLicense(true)}
                  >
                    {textDict.get('support_infos_licenses')}
                  </div>
                  <div className={styles['license-info-heading']} onClick={() => setShowLogs(true)}>
                    {textDict.get('support_infos_logs')}
                  </div>
                </div>
                <div className={styles['copyrightcontainer']}>
                  <div className={styles['copyright']}>Copyright &copy; 2022-2023</div>
                  <div className={styles['company']}>sqior medical GmbH</div>
                </div>
              </div>
            </div>

            <div className={styles['info-section-footer']} />
          </div>
        </ClosablePage>
      )}
      {showLicense && <LicenseInfo onClose={() => setShowLicense(false)} />}
      {showLogs && <LogView onClose={() => setShowLogs(false)} />}
    </div>
  );
}

export default AboutInfo;
