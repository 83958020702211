import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import {
  CommandCommunicationItemsPath,
  CommunicationVMTypes,
  PageStandardInfoThreadType,
  PersonsCommunicationStateOverlay,
} from '@sqior/viewmodels/communication';
import { MainInfoItemVF } from './lib/main-info-item/main-info-item';
import CollpasedStandardInfoItem from './lib/collpased-standard-info-item/collpased-standard-info-item';
import { StandardInfoThreadPage } from './lib/standard-info-thread-page/standard-info-thread-page';
import { StateOverlayContext } from '@sqior/js/state-operation';

export function UIConversationPlugin(
  context: PluginContext & FactoryContext & StateOverlayContext
) {
  context.factory.add(CommunicationVMTypes.MainInfoItemVMType, MainInfoItemVF);
  context.factory.add(
    CommunicationVMTypes.MainInfoCollapsedStandardInfoItemVMType,
    CollpasedStandardInfoItem
  );
  context.factory.add(PageStandardInfoThreadType, StandardInfoThreadPage);

  context.stateOverlays.add(
    'communication-response',
    PersonsCommunicationStateOverlay,
    CommandCommunicationItemsPath
  );
  context.stateOverlays.add(
    'communication-read-confirmation',
    PersonsCommunicationStateOverlay,
    'main-info/items'
  );
}
