import { Bytes } from './binary';
import { isEqual, PrimitiveValue, RecIF, Value, ValueObject, ValueOrNothing } from './value';

export type PatchValue = PrimitiveValue | null | RecIF<PatchValue> | PatchValue[];
type PatchOriginal = [number, number];
type PatchInsert = [Value[]];
type PatchModify = [number, PatchValue[]];
type PatchArray = (PatchOriginal | PatchInsert | PatchModify)[];

export class ValuePatch {
  private static createElementPatch(
    prev: Value[],
    next: Value[],
    prevStart: number,
    nextStart: number,
    count: number
  ): PatchArray {
    const res: PatchArray = [];
    let lastStart = prevStart;
    const prevEnd = prevStart + count;
    let patches: PatchValue[] | undefined;
    while (prevStart < prevEnd) {
      /* Determine diff */
      const patch = this.create(prev[prevStart], next[nextStart++]);
      if (patch) {
        /* Check if this ended a non-patch zone */
        if (!patches) {
          if (lastStart !== prevStart) {
            res.push([lastStart, prevStart]);
            lastStart = prevStart;
          }
          patches = [];
        }
        patches.push(patch);
      } else if (patches !== undefined) {
      /* Check if this ended a patch zone */
        res.push([lastStart, patches]);
        lastStart = prevStart;
        patches = undefined;
      }

      prevStart++;
    }
    /* Add the final zone */
    if (patches) res.push([lastStart, patches]);
    else res.push([lastStart, prevStart]);
    return res;
  }

  private static checkShiftFront(prev: Value[], next: Value[]): PatchArray | undefined {
    let end = 0;
    for (; end < prev.length; end++)
      if (prev[prev.length - 1 - end] === next[next.length - 1]) break;
    if (end === prev.length) return undefined;
    let endStart = end + 1;
    for (; endStart < prev.length && endStart - end < next.length; endStart++)
      if (prev[prev.length - 1 - endStart] !== next[next.length - 1 - endStart + end]) break;
    let res: PatchArray = [];
    const overlap = Math.min(prev.length - endStart, next.length - endStart + end);
    const rem = next.length - (endStart - end) - overlap;
    if (rem > 0) res.push([next.slice(0, rem)]);
    if (overlap > 0)
      res = res.concat(
        this.createElementPatch(prev, next, prev.length - endStart - overlap, rem, overlap)
      );
    res.push([prev.length - endStart, prev.length - end]);
    return res;
  }

  private static checkShiftBack(prev: Value[], next: Value[]): PatchArray | undefined {
    let start = 0;
    for (; start < prev.length; start++) if (prev[start] === next[0]) break;
    if (start === prev.length) return undefined;
    let startEnd = start + 1;
    for (; startEnd < prev.length && startEnd - start < next.length; startEnd++)
      if (prev[startEnd] !== next[startEnd - start]) break;
    let res: PatchArray = [[start, startEnd]];
    const overlap = Math.min(prev.length - startEnd, next.length - startEnd + start);
    const rem = next.length - (startEnd - start) - overlap;
    if (overlap > 0)
      res = res.concat(
        this.createElementPatch(prev, next, startEnd, next.length - overlap - rem, overlap)
      );
    if (rem > 0) res.push([next.slice(next.length - rem, next.length)]);
    return res;
  }

  private static createForArray(prev: Value[], next: Value[]): PatchArray | undefined {
    /* Check for identity of the array elements */
    let start = 0;
    for (; start < Math.min(prev.length, next.length); start++)
      if (!isEqual(prev[start], next[start])) break;
    /* Check if the two arrays are identical with respect to literal identity */
    if (start === prev.length && start === next.length) return undefined;

    /* Check for identify from the end */
    let end = 0;
    for (; end < Math.min(prev.length, next.length) - start; end++)
      if (!isEqual(prev[prev.length - 1 - end], next[next.length - 1 - end])) break;

    let res: PatchArray = [];
    /* Combine result if there are prefixes or suffixes detected */
    if (start > 0 || end > 0 || next.length === 0) {
      if (start > 0) res.push([0, start]);
      if (next.length > start + end) {
        const common = Math.min(prev.length - start - end, next.length - start - end);
        if (start > 0 || common === 0) {
          if (common > 0)
            res = res.concat(this.createElementPatch(prev, next, start, start, common));
          if (next.length - end - start - common > 0)
            res.push([next.slice(start + common, next.length - end)]);
        } else {
          if (next.length - end - start - common > 0)
            res.push([next.slice(start, next.length - end - common)]);
          res = res.concat(
            this.createElementPatch(
              prev,
              next,
              prev.length - end - common,
              next.length - end - common,
              common
            )
          );
        }
      }
      if (end > 0) res.push([prev.length - end, prev.length]);
    } else {
      /* Check for shifts of the array (items removed from front and others appended to or modified at the end) or vice versa - for efficiency use literal identity only */
      const shiftRes = this.checkShiftFront(prev, next) || this.checkShiftBack(prev, next);
      if (shiftRes) return shiftRes;
      res = [[next]];
    }

    return res;
  }

  private static createForObject(
    prev: ValueObject,
    next: ValueObject
  ): RecIF<PatchValue> | undefined {
    const diff: RecIF<PatchValue> = {};
    /* Loop all original attributes */
    for (const key in prev) {
      const d = ValuePatch.create(prev[key], next[key]);
      if (d !== undefined) diff[key] = d;
    }
    /* Loop pot. additional attributes */
    for (const key in next) if (!prev[key]) diff[key] = next[key];
    /* Check if result is empty */
    for (const key in diff) return diff;
    return undefined;
  }

  static create(prev: ValueOrNothing, next: ValueOrNothing): PatchValue | undefined {
    /* Check for literal identity */
    if (prev === next) return undefined;
    /* Check if there was something before, if not then the new thing is the patch */
    if (prev === undefined) return next;
    /* Check if there is something after, if not then the patch is the deletion indicator */
    if (next === undefined) return null;
    /* Check if the types differ, if yes then the next value is the patch */
    if (typeof prev !== typeof next) return next;
    /* Check if this is a non-object and non-array, in this case the next value is the patch */
    if (typeof prev !== 'object' || typeof next !== 'object') return next;
    /* Check if this was an array */
    if (prev instanceof Array)
      if (next instanceof Array) return ValuePatch.createForArray(prev, next);
      else return next;
    else if (next instanceof Array) return next;
    if (prev instanceof Bytes || next instanceof Bytes)
      throw new Error('Binary data is not allowed in value patch');
    return ValuePatch.createForObject(prev, next);
  }

  private static patchArray(prev: Value[], patch: PatchArray): Value[] {
    let res: Value[] = [];
    /* Combine all patch array statements */
    for (const p of patch) {
      const sec = p[1];
      if (typeof sec === 'number') res = res.concat(prev.slice(p[0] as number, sec));
      else if (sec !== undefined)
        for (let i = 0; i < sec.length; i++) {
          const r = this.patch(prev[(p[0] as number) + i], sec[i]);
          if (r) res.push(r);
        }
      else res = res.concat(p[0]);
    }
    return res;
  }

  private static patchObject(prev: ValueObject, patch: RecIF<PatchValue>): ValueObject {
    const res: ValueObject = {};
    /* Loop all original attributes */
    for (const key in prev) {
      const r = ValuePatch.patch(prev[key], patch[key]);
      if (r !== undefined) res[key] = r;
    }
    /* Loop pot. additional attributes */
    for (const key in patch) if (!prev[key]) res[key] = patch[key] as Value;
    return res;
  }

  static patch(prev: ValueOrNothing, patch: PatchValue | undefined): ValueOrNothing {
    /* Check if there is a patch at all, if not the original remains */
    if (patch === undefined) return prev;
    /* CHeck if there was something before, it no then the patch is the new value */
    if (prev === undefined) return patch as Value;
    /* Check if the patch eliminates the value */
    if (patch === null) return undefined;
    /* Check if the types differ, if yes then the patch is the next value */
    if (typeof prev !== typeof patch) return patch as Value;
    /* Check if this is a non-object and non-array, in this case the next value is the patch */
    if (typeof prev !== 'object' || typeof patch !== 'object') return patch as Value;
    /* Check if this was an array */
    if (prev instanceof Array)
      if (patch instanceof Array) return ValuePatch.patchArray(prev, patch as PatchArray);
      else return patch as Value;
    else if (patch instanceof Array) return patch as Value;
    if (prev instanceof Bytes || patch instanceof Bytes)
      throw new Error('Binary data is not allowed in value patch');
    return ValuePatch.patchObject(prev, patch);
  }
}
