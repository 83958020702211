import { classes } from '@sqior/react/utils';
import { ReactComponent as IconBadge } from './icon-badge.svg';
import { ReactComponent as IconCall1 } from './icon-call1.svg';
import { ReactComponent as IconCall2 } from './icon-call2.svg';
import { ReactComponent as IconChat } from './icon-chat.svg';
import { ReactComponent as IconChatEmpty } from './icon-chat-empty.svg';
import { ReactComponent as IconChatRound } from './icon-chat-round.svg';
import { ReactComponent as IconDashboard } from './icon-dashboard.svg';
import { ReactComponent as IconEmail } from './icon-email.svg';
import { ReactComponent as IconExpandLess } from './icon-expand-less.svg';
import { ReactComponent as IconExpandMore } from './icon-expand-more.svg';
import { ReactComponent as IconHistory } from './icon-history.svg';
import { ReactComponent as IconNavigateBefore } from './icon-navigate-before.svg';
import { ReactComponent as IconNavigateNext } from './icon-navigate-next.svg';
import { ReactComponent as IconPatient } from './icon-patient.svg';
import { ReactComponent as IconPerson } from './icon-person.svg';
import { ReactComponent as IconPersonGroup } from './icon-group.svg';
import { ReactComponent as IconTasks } from './icon-tasks.svg';
import { ReactComponent as IconTeam } from './icon-team.svg';
import { ReactComponent as IconTeam2 } from './icon-team2.svg';
import { ReactComponent as IconCalendarMonth } from './calendar_month.svg';
import { ReactComponent as IconEventUpcoming } from './event_upcoming.svg';
import { ReactComponent as IconQRCode } from './qr_code.svg';
import { ReactComponent as IconUploadFile } from './upload_file.svg';

import styles from './svg-icon.module.css';
import { CSSProperties } from 'react';

export type SvgIconProps = {
  className?: string;
  style?: CSSProperties;
};

export function SvgIconBadge(props: SvgIconProps) {
  return (
    <IconBadge
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconCall1(props: SvgIconProps) {
  return (
    <IconCall1
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconCall2(props: SvgIconProps) {
  return (
    <IconCall2
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconChat(props: SvgIconProps) {
  return (
    <IconChat
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconChatEmpty(props: SvgIconProps) {
  return (
    <IconChatEmpty
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconChatRound(props: SvgIconProps) {
  return (
    <IconChatRound
      viewBox="0 0 48 48"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconDashboard(props: SvgIconProps) {
  return (
    <IconDashboard
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconEmail(props: SvgIconProps) {
  return (
    <IconEmail
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconExpandLess(props: SvgIconProps) {
  return (
    <IconExpandLess
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconExpandMore(props: SvgIconProps) {
  return (
    <IconExpandMore
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconGroup(props: SvgIconProps) {
  return (
    <IconTeam2
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconHistory(props: SvgIconProps) {
  return (
    <IconHistory
      viewBox="0 96 960 960"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconNavigateBefore(props: SvgIconProps) {
  return (
    <IconNavigateBefore
      viewBox="0 96 960 960"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconNavigateNext(props: SvgIconProps) {
  return (
    <IconNavigateNext
      viewBox="0 96 960 960"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconPatient(props: SvgIconProps) {
  return (
    <IconPatient
      viewBox="0 0 48 48"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconPerson(props: SvgIconProps) {
  return <IconPerson className={classes(styles['std-icon'], props.className)} {...props} />;
}
export function SvgIconPersonGroup(props: SvgIconProps) {
  return <IconPersonGroup className={classes(styles['std-icon'], props.className)} {...props} />;
}
export function SvgIconTasks(props: SvgIconProps) {
  return (
    <IconTasks
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconTeam(props: SvgIconProps) {
  return (
    <IconTeam
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}

export function SvgCalendarMonth(props: SvgIconProps) {
  return <IconCalendarMonth className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgEventUpcoming(props: SvgIconProps) {
  return <IconEventUpcoming className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgQRCode(props: SvgIconProps) {
  return <IconQRCode className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgUploadFile(props: SvgIconProps) {
  return <IconUploadFile className={classes(styles['std-icon'], props.className)} {...props} />;
}
