import { FactoryProps } from '@sqior/react/factory';
import { DashboardStage } from '@sqior/react/uivisual';
import { ListViewInfos } from '@sqior/viewmodels/visual';
import styles from './bed-allocation-dashboard.module.css';

export type BedAllocationDashboardProps = FactoryProps<ListViewInfos>;

function style(index: number) {
  if (index <= 2) return 'patients';
  return 'rooms';
}

export function BedAllocationDashboard(props: BedAllocationDashboardProps) {
  return (
    <div className={styles['container']}>
      {props.data.lists.map((list, index) => (
        <DashboardStage
          key={list.path}
          className={styles[style(index)]}
          title={list.title}
          path={list.path}
          separator={index + 1 < props.data.lists.length}
        />
      ))}
    </div>
  );
}

export default BedAllocationDashboard;
