import { ChatVMType } from '@sqior/viewmodels/communication';
import styles from './chat-picture.module.css';
import {
  ImageInfo,
  ProfilePicture,
  SvgIconPatient,
  SvgIconPerson,
  SvgIconPersonGroup,
} from '@sqior/react/uibase';

/* eslint-disable-next-line */
export interface ChatPictureProps {
  type: ChatVMType;
  url: string | undefined;
}

export function ChatPicture(props: ChatPictureProps) {
  return (
    <>
      {!props.url && getIcon(props.type)}
      {props.url && <ProfilePicture className={styles['profile-picture']} url={props.url} />}
    </>
  );
}

export function getIcon(type: ChatVMType) {
  switch (type) {
    case ChatVMType.Person:
      return <SvgIconPerson className={styles['svg-icon']} />;
    case ChatVMType.Group:
      return <SvgIconPersonGroup className={styles['svg-icon']} />;
    case ChatVMType.Info:
      return <ImageInfo className={styles['svg-icon']} />;
    case ChatVMType.PatientRelated:
      return <SvgIconPatient className={styles['svg-icon']} />;
  }
  return null;
}

export default ChatPicture;
