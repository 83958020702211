import styles from './react-uiworkflow.module.css';

/* eslint-disable-next-line */
export interface ReactUiworkflowProps {}

export function ReactUiworkflow(props: ReactUiworkflowProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactUiworkflow!</h1>
    </div>
  );
}

export default ReactUiworkflow;
