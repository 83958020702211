import { PluginContext } from '@sqior/js/plugin';
import { StateOverlayContext } from '@sqior/js/state-operation';
import { FactoryContext } from '@sqior/react/factory';
import { UISelectionPlugin } from '@sqior/react/uiselection';
import {
  ORWorkflowDashboardType,
  ORWorkflowInteractiveDashboardType,
  ORWorkflowMyListItemType,
  ORWorkflowORClusterListItemType,
  ORWorkflowORListItemType,
  ORWorkflowOverviewDashboardType,
  ORWorkflowOverviewProcedureType,
  ORWorkflowOverviewSutureIncisionDurationType,
  ORWorkflowOverviewType,
  ORWorkflowStageItemType,
} from '@sqior/viewmodels/orworkflow';
import { PatientOverviewPath } from '@sqior/viewmodels/patient';
import { WorkflowCommandPath, WorkflowPathOverlay } from '@sqior/viewmodels/workflow';
import ORWorkflowArea from './orworkflow-area/orworkflow-area';
import ORWorkflowDashboard from './orworkflow-dashboard/orworkflow-dashboard';
import ORWorkflowOverviewDashboard from './orworkflow-overview-dashboard/orworkflow-overview-dashboard';
import ORWorkflowOverviewProcedure from './orworkflow-overview-procedure/orworkflow-overview-procedure';
import ORWorkflowOverviewSutureIncisionDuration from './orworkflow-overview-suture-incision-duration/orworkflow-overview-suture-incision-duration';
import ORWorkflowInteractiveDashboard from './orworkflow-interactive-dashboard/orworkflow-interactive-dashboard';
import ORWorkflowListItem from './orworkflow-list-item/orworkflow-list-item';

export function UIWorkflowPlugin(context: PluginContext & FactoryContext & StateOverlayContext) {
  context.plugins.require(UISelectionPlugin, context);
  context.factory.add(ORWorkflowOverviewType, ORWorkflowArea);
  context.factory.add(ORWorkflowStageItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowORListItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowORClusterListItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowMyListItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowDashboardType, ORWorkflowDashboard);
  context.factory.add(ORWorkflowOverviewDashboardType, ORWorkflowOverviewDashboard);
  context.factory.add(ORWorkflowOverviewProcedureType, ORWorkflowOverviewProcedure);
  context.factory.add(ORWorkflowInteractiveDashboardType, ORWorkflowInteractiveDashboard);
  context.factory.add(
    ORWorkflowOverviewSutureIncisionDurationType,
    ORWorkflowOverviewSutureIncisionDuration
  );
  context.stateOverlays.add(WorkflowCommandPath, WorkflowPathOverlay, PatientOverviewPath);
}
