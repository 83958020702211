import styles from './qrscanner.module.css';
import { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { Emitter } from '@sqior/js/event';
import { Logger } from '@sqior/js/log';

export enum FacingMode {
  Environment,
  User,
}

/* eslint-disable-next-line */
export interface QRScannerProps {
  detected: (code: string) => void;
  takePhoto?: Emitter;
  onPhoto?: (canv: HTMLCanvasElement) => void;
  facing?: FacingMode;
  onFacing?: (facing: FacingMode) => void;
}

export function QRScannerControl(props: QRScannerProps) {
  const [facing, setFacing] = useState(props.facing === FacingMode.User ? 'user' : 'environment');
  const videoControl = useRef<HTMLVideoElement | null>(null);

  /* Connect the photo emitter if applicable */
  useEffect(() => {
    const onPhoto = props.onPhoto;
    if (props.takePhoto && onPhoto)
      return props.takePhoto.on(() => {
        if (
          !videoControl.current ||
          !videoControl.current.videoWidth ||
          !videoControl.current.videoHeight
        )
          return;
        /* Draw video element into canvas with original video size */
        const canv = document.createElement('canvas');
        canv.width = videoControl.current.videoWidth;
        canv.height = videoControl.current.videoHeight;
        canv.getContext('2d')?.drawImage(videoControl.current, 0, 0);
        /* Inform users */
        onPhoto(canv);
      });
    else return;
  }, [props]);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();
    let videoStream: MediaStream | undefined;
    let stopped = false;
    let retryCount = 0;
    let startTimer: ReturnType<typeof setTimeout> | undefined = undefined;

    Logger.debug('QRScannerControl - useEffect');

    function getUserMedia() {
      Logger.debug('QRScannerControl - try to get user media');
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: props.takePhoto ? 3840 : 1280 },
            height: { ideal: props.takePhoto ? 2160 : 720 },
            facingMode: facing,
          },
        })
        .then((stream) => {
          Logger.debug(
            `QRScannerControl - getUserMedia - returned stream stopped=${stopped}, videoControl.current=${videoControl.current}`
          );
          /* Check if this is already stopped, in this case the code reader should not be started at all */
          if (stopped) return;
          /* Start decoding */
          videoStream = stream;
          if (videoControl.current) {
            videoControl.current.srcObject = stream;
            Logger.debug(`QRScannerControl - call codeReader.decodeFromVideoElementContinuously`);
            codeReader.decodeFromVideoElementContinuously(videoControl.current, (result, err) => {
              //Logger.debug(`QRScannerControl - codeReader.decodeFromVideoElementContinuously - callback, result=${result?.getText()}`);
              if (result) {
                if (result.getText() && !stopped) props.detected(result.getText());
              }
            });
          }
        })
        .catch((r) => {
          Logger.debug(
            `QRScannerControl - getUserMedia - error: ${JSON.stringify(
              r
            )}, retryCount=${retryCount}, stopped=${stopped}`
          );
          // Retry once, might happen if video has been consumed recently
          if (retryCount < 1 && !stopped) {
            retryCount++;
            triggerGetUserMedia();
          }
        });
    }

    function triggerGetUserMedia() {
      Logger.debug(
        `QRScannerControl - triggerGetUserMedia(), retryCount=${retryCount}, stopped=${stopped}, startTimer=${startTimer}`
      );
      startTimer = setTimeout(() => {
        Logger.debug(
          `QRScannerControl - triggerGetUserMedia() - timout, retryCount=${retryCount}, stopped=${stopped}`
        );
        if (!stopped) {
          getUserMedia();
        }
      }, 200);
    }

    triggerGetUserMedia();

    return () => {
      Logger.debug(
        `QRScannerControl - useEffect - destructor, retryCount=${retryCount}, stopped=${stopped}, videoStream=${videoStream}, startTimer=${startTimer}`
      );
      /* Indicate that this is stopped in case that the getUserMedia function or other asynchronous callbacks are still running */
      stopped = true;

      if (startTimer) clearTimeout(startTimer);
      /* Check if this is already started */
      if (videoStream) {
        codeReader.reset();
        Logger.debug(`QRScannerControl - useEffect - destructor, reset()`);
        for (const track of videoStream.getTracks()) {
          track.stop();
          Logger.debug(`QRScannerControl - useEffect - destructor, track.stop() track=${track}`);
        }
      }
    };
  }, [props, facing]);

  return (
    <video
      ref={videoControl}
      className={styles['container']}
      onClick={() => {
        /* Check if the device has multiple video sources, if yes try to change the facing mode */
        navigator.mediaDevices.enumerateDevices().then((devs) => {
          let videoDevices = 0;
          for (const dev of devs) if (dev.kind === 'videoinput') videoDevices++;
          if (videoDevices > 1) {
            const newFacing = facing === 'user' ? 'environment' : 'user';
            setFacing(newFacing);
            if (props.onFacing)
              props.onFacing(newFacing === 'user' ? FacingMode.User : FacingMode.Environment);
          }
        });
      }}
    ></video>
  );
}

export default QRScannerControl;
