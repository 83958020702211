import SvgButton from '../svg-button/svg-button';
import { ReactComponent as Logo } from './microphone.svg';

import styles from './button-record.module.css';

/* eslint-disable-next-line */
export interface ButtonRecordProps {
  onClick?: () => void;
}

export function ButtonRecord(props: ButtonRecordProps) {
  return (
    <SvgButton onClick={props.onClick}>
      <Logo fill="#ffffff" viewBox="0 0 40 40" />
    </SvgButton>
  );
}

export default ButtonRecord;
