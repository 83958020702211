import { ServerConnectionBase, ServerConnectionOptions, WebSocketIF } from '@sqior/js/wsbase';
import { WebSocketImpl } from './websocket';

export class ServerConnection extends ServerConnectionBase {
  constructor(url: string, options: ServerConnectionOptions = {}) {
    super(url, options);
  }

  createWebSocket(url: string): WebSocketIF {
    return new WebSocketImpl(url);
  }
}
