import styles from './main-frame.module.css';
import { Logo } from '@sqior/react/uibase';

/* eslint-disable-next-line */
export interface MainFrameProps {
  children: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export function MainFrame(props: MainFrameProps) {
  return (
    <div className={styles['main-frame']}>
      <div className={styles['children']}>{props.children}</div>
    </div>
  );
}

export default MainFrame;
