import { ComponentFactory } from '@sqior/react/factory';
import { useDynamicState } from '@sqior/react/state';
import { useConnectionError } from '@sqior/react/uiapp';
import { DashboardStatePath, ListViewInfos } from '@sqior/viewmodels/visual';
import { useContext } from 'react';
import styles from './dashboard.module.css';

/* eslint-disable-next-line */
export interface DashboardProps {
  className: string;
}

export function Dashboard(props: DashboardProps) {
  const dashboard = useDynamicState<ListViewInfos>(DashboardStatePath, {
    entityType: '',
    lists: [],
  });
  const connError = useConnectionError();
  const FactoryComponent = useContext(ComponentFactory);

  return (
    <div className={`${styles['outer-container']} ${props.className}`}>
      {dashboard.entityType.length > 0 && <FactoryComponent data={dashboard} />}
      {connError && <div className={styles['shade']} />}
    </div>
  );
}

export default Dashboard;
