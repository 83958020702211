import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export function useTabTitleCounter(count: number, title?: string) {
  const [originalTitle, setOriginalTitle] = useState('');

  if (!originalTitle) setOriginalTitle(document.title);

  useEffect(() => {
    if (count > 0) document.title = `(${count}) ${title || originalTitle}`;
    else document.title = title || originalTitle;
  }, [count, title, originalTitle]);
}
