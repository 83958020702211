import { OperationSpec, OperationType } from '@sqior/js/operation';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { ItemState } from '@sqior/viewmodels/visual';

export const PageSelectLocationType = 'page.select-location';

export const UpdateLocationPath = 'update-location';

export type LocationData = { text: string; id?: string }[];

export function hasMissingLocation(locations: LocationData) {
  return locations.length > 0 && locations[0].id === undefined;
}
export function hasLocationRole(locations: LocationData) {
  return locations.length > 0;
}
export function hasValidLocation(locations: LocationData) {
  return locations.length > 0 && locations[0].id !== undefined;
}

/* Transfer Priority Group */
export type TransferPriorityGroup = EntityHeader & {
  name: string;
};

export enum DailyStartConfigItemTypes {
  TransferPriorityGroup = 'TransferPriorityGroup',
  TransferLocation = 'TransferLocation',
  InductionLocation = 'InductionLocation',
}

export type DailyStartConfigItem = EntityHeader & {
  configType: DailyStartConfigItemTypes;
  options: {
    entity: Entity;
    text: string;
    description?: string;
  }[];
  selected?: number; // undefined if not selected
};

export type DailyStartConfig = EntityHeader & {
  configs: DailyStartConfigItem[];
};

export type TransferPriorityGroupVM = EntityHeader & {
  label: string;
  state: ItemState;
};

export type UpdateLocationData = EntityHeader & {
  location: string;
  configType: DailyStartConfigItemTypes;
  index: number;
};
export function makeUpdateLocationData(
  location: string,
  configType: DailyStartConfigItemTypes,
  index: number
): UpdateLocationData {
  return {
    entityType: UpdateLocationPath,
    location: location,
    configType: configType,
    index: index,
  };
}

export function UpdateLocation(data: UpdateLocationData): OperationSpec<UpdateLocationData> {
  return { type: OperationType.Add, path: UpdateLocationPath, data: data };
}
