import { FactoryProps } from '@sqior/react/factory';
import styles from './orworkflow-overview-suture-incision-duration.module.css';
import { ORWorkflowOverviewSutureIncisionDurationData } from '@sqior/viewmodels/orworkflow';
import { useTextResources } from '@sqior/react/uibase';

export type ORWorkflowOverviewSutureIncisionDurationProps =
  FactoryProps<ORWorkflowOverviewSutureIncisionDurationData>;

export function ORWorkflowOverviewSutureIncisionDuration(
  props: ORWorkflowOverviewSutureIncisionDurationProps
) {
  const textDict = useTextResources();
  return (
    <div className={styles['container']}>
      <div className={styles['sub-container']}>
        <div className={styles['duration']}>{props.data.duration + ' ' + textDict.get('min')}</div>
        <div className={styles['description']}>{textDict.get('suture_incision_time')}</div>
      </div>
    </div>
  );
}

export default ORWorkflowOverviewSutureIncisionDuration;
