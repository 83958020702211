import { LogicalTimestamp, Value } from '@sqior/js/data';
import { IdOperation } from './id-operation';
import { OperationType } from './operation';

export type DeleteResult = LogicalTimestamp;

export class DeleteOperation extends IdOperation {
  constructor(id: string) {
    super(OperationType.Delete, id);
  }

  static fromJSON(json: Value) {
    return new DeleteOperation(json as string);
  }
}
