import { StateOverlayContext } from '@sqior/js/state-operation';
import {
  AppVersionOverlay,
  AppVersionUpdateConfirmationPath,
  AppVersionUpdatePath,
} from '@sqior/viewmodels/app';

export function UIAppPlugin(context: StateOverlayContext) {
  /* Register overlays */
  context.stateOverlays.add(
    AppVersionUpdateConfirmationPath,
    AppVersionOverlay,
    AppVersionUpdatePath
  );
}
