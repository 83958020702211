import { Entity } from '@sqior/js/entity';

/* Entity representing the input parameter options of a transport */

export const TransportOrderInputType = 'TransportOrderInput';
export type TransportOrderLocationInput = { label: string; select: Entity };
export type TransportOrderInput = Entity & {
  from: TransportOrderLocationInput[];
  to: TransportOrderLocationInput[];
};

/* Entity representing the selected input parameters of a transport order */

export const TransportOrderParametersType = 'TransportOrderParameters';
export type TransportOrderParameters = Entity & {
  from: Entity;
  to: Entity;
  priority: number;
  infectious: boolean;
};
