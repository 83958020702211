import styles from './react-uiadvanced.module.css';

/* eslint-disable-next-line */
export interface ReactUiadvancedProps {}

export function ReactUiadvanced(props: ReactUiadvancedProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactUiadvanced!</h1>
    </div>
  );
}

export default ReactUiadvanced;
