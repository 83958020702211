import { useDynamicState } from '@sqior/react/state';
import { classes } from '@sqior/react/utils';
import { hasMissingLocation, LocationData } from '@sqior/viewmodels/location';
import { Fragment } from 'react';

import styles from './active-location.module.css';

/* eslint-disable-next-line */
export interface ActiveLocationProps {
  className?: string;
  onClick: () => void;
}

export function ActiveLocation(props: ActiveLocationProps) {
  const activeLocation = useDynamicState<LocationData>('active-location', []);

  return (
    <div
      className={classes(
        styles['container'],
        props.className,
        hasMissingLocation(activeLocation) ? styles['missing-location'] : ''
      )}
      onClick={props.onClick}
    >
      <span>
        {activeLocation.map((item, idx) => {
          return <Fragment key={item.text}>{item.text} &nbsp;</Fragment>;
        })}
      </span>
    </div>
  );
}

export default ActiveLocation;
