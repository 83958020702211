import { ValueObject } from '@sqior/js/data';
import {
  Button,
  SvgIconExpandLess,
  SvgIconExpandMore,
  SvgIconGroup,
  useTextResources,
} from '@sqior/react/uibase';
import { AddressList } from '@sqior/react/uichat';
import { classes } from '@sqior/react/utils';
import { AddressInfoVM } from '@sqior/viewmodels/communication';
import styles from './team.module.css';
import { EntityVisual } from '@sqior/react/uiconversation';

/* eslint-disable-next-line */
export interface TeamProps {
  className?: string;
  team?: AddressInfoVM[];
  switchDisplayTeam: () => void;
  displayTeam: boolean;
  relatedId?: ValueObject;
}

export function TeamHeader(props: TeamProps) {
  const textResources = useTextResources();

  return (
    (props.team && props.team.length > 0 && (
      <div className={classes(styles['description-header'], props.className)}>
        <SvgIconGroup className={styles['description-icon']} />
        <div className={styles['team-header']} onClick={props.switchDisplayTeam}>
          {textResources.get('or_team')}
        </div>
        <div className={styles['team-button-div']}>
          <Button className={styles['team-button']} onClick={props.switchDisplayTeam}>
            {props.displayTeam ? <SvgIconExpandLess /> : <SvgIconExpandMore />}
          </Button>
        </div>
      </div>
    )) ||
    null
  );
}

export function ChatParticipantsHeader(props: TeamProps) {
  const textResources = useTextResources();

  props.team && props.team.length > 0;

  return (
    (props.team && props.team.length > 0 && (
      <div
        className={classes(
          styles['description-header'],
          styles['description-header-aligntop'],
          props.className
        )}
      >
        <SvgIconGroup className={styles['description-icon']} />
        <div className={styles['chat-participant-header']} onClick={props.switchDisplayTeam}>
          <div className={styles['chat-participants']}>
            {!props.displayTeam
              ? props.team &&
                props.team.map((a, idx, arr) => {
                  return (
                    <>
                      <span>
                        <EntityVisual content={a.name} />
                      </span>
                      {idx < arr.length - 1 && <span>, </span>}
                    </>
                  );
                })
              : textResources.get('chat_participants')}
          </div>
        </div>
        <div className={styles['team-button-div']}>
          <Button className={styles['team-button']} onClick={props.switchDisplayTeam}>
            {props.displayTeam ? <SvgIconExpandLess /> : <SvgIconExpandMore />}
          </Button>
        </div>
      </div>
    )) ||
    null
  );
}

export function TeamAddresses(props: TeamProps) {
  return (
    (props.team && props.team.length > 0 && (
      <div
        className={classes(
          props.className,
          styles['team-list-overlay'],
          styles[props.displayTeam ? 'team-list-overlay-visible' : 'team-list-overlay-hidden']
        )}
      >
        {props.team && (
          <div className={styles['team']}>
            <AddressList items={props.team} relatedId={props.relatedId} />
          </div>
        )}
      </div>
    )) ||
    null
  );
}
